import {
  ElementModel,
  ElementModelWithPortLabels,
  ElementModelWithOutPortLabels,
  ElementModelWithInPortLabels,
  DefaultStencilElementAttrs,
  withTooltip,
} from './ElementModel';

const notImplementedHelpText = 'This tool is planned, but not yet developed.';

export class ElementType {
  constructor(type, label, blockColor, iconImage, helpText, showPortLabels = '') {
    this.type = type;
    this.label = label;
    this.helpText = helpText || notImplementedHelpText;

    const modelProps = {
      typeLabel: label,
      attrs: {
        image: { 'xlink:href': iconImage, pointerEvents: 'none' },
        '.name': { text: label, pointerEvents: 'none' },
        '.block': { fill: blockColor },
      },
    };
    let stencilElModel = ElementModel;
    if (showPortLabels) {
      showPortLabels = showPortLabels.toString();
      if (showPortLabels === 'all' || showPortLabels === 'true') {
        stencilElModel = ElementModelWithPortLabels;
      } else if (showPortLabels === 'out') {
        stencilElModel = ElementModelWithOutPortLabels;
      } else if (showPortLabels === 'in') {
        stencilElModel = ElementModelWithInPortLabels;
      }
    }
    this.stencilElementModel = stencilElModel.define(this.type, modelProps);
  }

  get initialData() {
    return { name: this.label, type: this.type };
  }

  get maxCount() {
    return -1;
  }

  get stencilElementAttrs() {
    return withTooltip(DefaultStencilElementAttrs, this.helpText);
  }

  applySourceElements(elementData, sourceElements) {
    let sourceFields = [];

    if (sourceElements['in']) {
      sourceFields = sourceElements['in'].elementData.fields ?? [];
    }

    return { ...elementData, fields: [...sourceFields] };
  }

  createStencilInstance(position) {
    const instance = new this.stencilElementModel(this.stencilElementAttrs);
    instance.position(...position);
    instance.prop('enabled', true);

    return instance;
  }

  canPreview() {
    return true;
  }

  getPreviewColumns(elementData) {
    return elementData.fields || [];
  }

  extractTypeData(elementData) {
    return {
      fields: elementData.fields,
    };
  }
}
