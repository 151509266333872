import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getTokenInfo, logout } from '../../_shared/auth';
import { setDataToLocalStorage, getDataFromLocalStorage } from '../../_shared/storage';
import { getOneTimeToken, redirectToLogin, refreshExpiredToken } from '../../_shared/jwtAuth';
import { getUserInfoV2, getAuthUrlParameters, scaleProvision } from '../../_shared/jwtAuth';
import { EnviromentUrl, numberOfTokenRefreshesToRedirectOnLogin } from '../../../configs/params';
import { displayApplicationBrowserConsoleLogs } from '../../_shared/consoleLogger';

const oneSecondInMS = 1000;
const oneMinuteInMS = 60 * oneSecondInMS;
let tabTokenRefreshCounter = 0;

const useAuth = () => {
  displayApplicationBrowserConsoleLogs();
  const { pathname } = useLocation();
  const [user, setUser] = useState();
  const [isError, setIsError] = useState(false);
  const TOKEN_REFRESH_COUNT = 'tokenRefreshCount';
  const resetRefreshCounterEvents = ['click', 'keypress', 'onkeydown'];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetTokenRefreshCount = () => {
    setDataToLocalStorage(TOKEN_REFRESH_COUNT, 0);
    tabTokenRefreshCounter = 0;
  };

  const retryFunction = async (func, numRetries, funcParams) => {
    try {
      const result = await func(funcParams);
      return result;
    } catch (error) {
      if (numRetries === 0) {
        throw error;
      }
      numRetries--;
      return await retryFunction(func, numRetries, funcParams);
    }
  };

  async function checkAndRefreshLogic() {
    setIsError(false);
    const tokenRefreshCount = parseInt(getDataFromLocalStorage(TOKEN_REFRESH_COUNT));
    if (tokenRefreshCount >= parseInt(numberOfTokenRefreshesToRedirectOnLogin)) {
      console.log('REFRESHING TOKEN HIT MAX COUNT');
      logout();
      redirectToLogin();
      return;
    }

    const tokenExpirationTime = getDataFromLocalStorage('tokenExpirationTime');
    console.log('token expiration time', tokenExpirationTime);
    console.log('Is token expired', isTokenExpired());
    if (!tokenExpirationTime || isTokenExpired()) {
      console.log('Token is expired');
      await checkAndRefreshToken();
      scaleProvision();
    }

    const tokenInfo = getTokenInfo();
    if (!tokenInfo) {
      redirectToLogin();
      return;
    }

    setTimeout(checkAndRefreshLogic, 30 * oneSecondInMS);
  }

  async function checkAndRefreshToken() {
    navigator.locks.request('auth-lock', async lock => {
      console.log('LOCK INFO', lock);
      await refreshToken();
    });
  }

  async function refreshToken() {
    const data = await retryFunction(refreshExpiredToken, 3);
    setUser(data);
    console.log('Token Refresh Completed');
    tabTokenRefreshCounter = parseInt(getDataFromLocalStorage(TOKEN_REFRESH_COUNT));
    tabTokenRefreshCounter++;
    setDataToLocalStorage(TOKEN_REFRESH_COUNT, tabTokenRefreshCounter);
    if (!isTokenExpired()) {
      console.log('Step 3: Fetching userInfo in the referesh token call');

      await getUserInfoV2().catch(error => console.log('Error trying to get Product Codes from User Info'));
    }
  }

  function isTokenExpired() {
    const tokenExpirationTime = parseInt(getDataFromLocalStorage('tokenExpirationTime'));
    const currentTime = new Date().getTime();
    return currentTime + oneMinuteInMS >= tokenExpirationTime;
  }

  function forwardReturnUrl(returnUrl) {
    console.log('EnviromentUrl', EnviromentUrl);
    if (returnUrl.includes(EnviromentUrl.toString())) {
      window.location.replace(decodeURIComponent(returnUrl));
      return;
    } else {
      logout();
      redirectToLogin();
      return;
    }
  }

  useEffect(() => {
    (async function () {
      const { returnUrl, authToken } = getAuthUrlParameters();
      const decodedPathName = decodeURIComponent(pathname);
      const isLoginPage = decodedPathName.includes('auth/login');
      if (isLoginPage) {
        if (!returnUrl) {
          setIsError(true);
          redirectToLogin();
          return;
        }

        if (!authToken) {
          forwardReturnUrl(returnUrl);
        }

        try {
          console.log('Auth token:', authToken);
          console.log('Return Url:', returnUrl);
          const result = await retryFunction(getOneTimeToken, 3, authToken);
          if (result.notAuthorized) {
            redirectToLogin(returnUrl);
            return;
          } else if (result.data) {
            console.log('One time token data exist');
            console.log('One time token process completed');
            forwardReturnUrl(returnUrl);
          }
          setIsError(true);
        } catch (error) {
          setIsError(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  useEffect(() => {
    (async function () {
      setDataToLocalStorage(TOKEN_REFRESH_COUNT, 0);
      const tokenInfo = getTokenInfo();
      if (tokenInfo) {
        console.log('Token exist on page load');
        setUser(tokenInfo);
        await checkAndRefreshLogic();
        resetRefreshCounterEvents.forEach(evt => window.addEventListener(evt, resetTokenRefreshCount));

        if (!isTokenExpired()) {
          await getUserInfoV2().catch(error => console.log('Error trying to get Product Codes from User Info'));
        }
      }
      const isLoginPage = pathname.includes('auth/login');
      if (!isLoginPage && !tokenInfo) {
        console.log('Token does not exist on home page');
        await checkAndRefreshLogic();
        resetRefreshCounterEvents.forEach(evt => window.addEventListener(evt, resetTokenRefreshCount));
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user,
    isError,
  };
};

export { useAuth };
