import React, { useCallback, useRef, useState } from 'react';
import { BTInput, BTPopover } from '@btas/jasper';
import { useClickAwayListener } from '../shared/useClickAwayListener';
import './EditableText/styles.scss';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';

const EditableText = ({ text, name, onSubmit }) => {
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);
  const [previousValue, setPreviousValue] = useState('');

  const onChange = useCallback(e => onSubmit(e.target.value), [onSubmit]);
  const stopEditing = useCallback(() => inputRef.current.blur(), [inputRef]);
  const onFocus = useCallback(() => setPreviousValue(text), [setPreviousValue, text]);

  const handleKeyDown = useCallback(
    event => {
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          stopEditing();
          break;
        case 'Escape':
          onSubmit(previousValue);
          stopEditing();
          break;
        default:
          break;
      }
    },
    [previousValue, stopEditing, onSubmit]
  );

  useClickAwayListener(wrapperRef.current, stopEditing);

  const error = text ? null : 'Data flow name is required.';
  const canEditWorkflow = useCanEditWorkflow();
  const dataFlowTitleClass = canEditWorkflow ? 'wkp-data-flow-title' : 'wkp-data-flow-title-disabled';
  return (
    <div ref={wrapperRef} className="wkp-editable-text-container" id="editableInput">
      <BTInput
        className={dataFlowTitleClass}
        disabled={!canEditWorkflow}
        inputRef={inputRef}
        maxLength="100"
        placeholder={name}
        value={text}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={handleKeyDown}
      />
      {/*keep text width*/}
      <span className="wkp-data-flow-text">{text}</span>
      {error && (
        <BTPopover id="errorPopover" placement="bottom" target="editableInput" type="error">
          {error}
        </BTPopover>
      )}
    </div>
  );
};

export default EditableText;
