import { useReducer, useCallback, useEffect, useState, useRef } from 'react';
import { commandCheckStatusThreshold } from '../../../../configs/params';
import {
  commandsStatusEnum,
  getCommandsAverageProcessingTime,
  getCommandsStatus,
  saveBatchCommandSJS,
} from './useCommandsQueue/apis';
import usePubSub from '../PubSub/usePubSub';
import MessageType from '../../../_shared/PubSub/pubSubMessageType';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  commandsQueue: [],
  isCommandsSaving: false,
  commandsBeingProcessed: [],
  batchSize: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'addCommand':
      return { ...state, commandsQueue: [...state.commandsQueue, action.payload] };
    case 'startProcessingQueue':
      return {
        ...state,
        commandsBeingProcessed: state.commandsQueue.map(c => c.commandId),
        isCommandsSaving: true,
        commandsStoreUpdated: false,
      };
    case 'storeProcessingQueue':
      return {
        ...state,
        commandsQueue: state.commandsQueue.filter(command => !action.payload?.includes(command)),
        commandsBeingProcessed: action.payload?.map(c => c.commandId),
        batchSize: action.payload?.length,
        isCommandsSaving: true,
        commandsStoreUpdated: true,
      };
    case 'endProcessingQueue': {
      return {
        ...state,
        isCommandsSaving: false,
        commandsBeingProcessed: [],
      };
    }
    case 'errorProcessQueue':
      return { ...state };
    default:
      throw new Error();
  }
};

export default function useCommandsQueue({ spreadRef, syncDatareferences }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [workpaperId, setWorkpaperId] = useState();
  const [onEmptyQueueCallbacks, setOnEmptyQueueCallbacks] = useState([]);
  const [processingTime, setProcessingTime] = useState(commandCheckStatusThreshold);

  const { publish } = usePubSub();
  const checkStatusTimeoutRef = useRef(null);

  useEffect(() => {
    if (workpaperId) {
      getCommandsAverageProcessingTime(workpaperId, state.batchSize).then(({ averageProcessingTime }) =>
        setProcessingTime(averageProcessingTime)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workpaperId]);

  const allCommandsProcessedAsync = useCallback(() => {
    if (state.commandsQueue.length === 0) {
      return Promise.resolve();
    }
    return new Promise(resolve => {
      setOnEmptyQueueCallbacks(prevCallbacks => [...prevCallbacks, resolve]);
    });
  }, [state.commandsQueue.length]);

  const syncDatareferencesPositionsData = async commands => {
    commands.forEach(command => {
      publish({
        body: { command: JSON.parse(command.commandText), sheet: spreadRef.current.getActiveSheet() },
        message: MessageType.CellPosition,
        callback: syncDatareferences?.trackDataReferencesAction.current,
      });
    });
  };

  const directlySaveCommands = useCallback(async () => {
    const sheetName = spreadRef.current?.getActiveSheet()?.name();
    const { commandsQueue, commandsBeingProcessed } = state;
    const pendingCommandsQueue = commandsQueue.filter(c => !commandsBeingProcessed?.includes(c.commandId));

    if (pendingCommandsQueue.length > 0) {
      dispatch({ type: 'startProcessingQueue' });
      await saveBatchCommandSJS(workpaperId, sheetName, pendingCommandsQueue, true);
      dispatch({ type: 'storeProcessingQueue', payload: commandsQueue });
    }
  }, [state, spreadRef, workpaperId]);

  const saveCommands = async () => {
    const sheetName = spreadRef.current?.getActiveSheet()?.name();
    const currentCommandsQueue = state.commandsQueue;

    dispatch({ type: 'startProcessingQueue' });
    syncDatareferencesPositionsData(currentCommandsQueue);
    const response = await saveBatchCommandSJS(workpaperId, sheetName, currentCommandsQueue);
    if (response.ok || response.status === 504) {
      // eslint-disable-next-line no-useless-escape
      if (currentCommandsQueue.filter(x => x.commandText.includes('{"cmd":"renameSheet"'))?.length) {
        publish({
          body: workpaperId,
          message: MessageType.CellPosition,
          callback: syncDatareferences?.loadDataReferencesAction.current,
        });
      }
    } else {
      throw new Error(response.message);
    }

    // Store command IDs to check on their status
    // Update batch size
    // Clear commands queue
    dispatch({ type: 'storeProcessingQueue', payload: currentCommandsQueue });
  };

  const isProcessingLastBatch = async () => {
    if (state.commandsBeingProcessed.length) {
      const commandsStatus = await getCommandsStatus(state.commandsBeingProcessed);
      return commandsStatus.some(c => c.status === commandsStatusEnum.Processing);
    }

    return false;
  };

  const clearCheckStatusTimeout = () => {
    if (checkStatusTimeoutRef.current) {
      clearInterval(checkStatusTimeoutRef.current);
      checkStatusTimeoutRef.current = null;
    }
  };

  const handleCommandsBeingProcessed = async () => {
    if (await isProcessingLastBatch()) {
      startCheckStatusInterval();
    } else {
      clearCheckStatusTimeout();

      const { averageProcessingTime } = await getCommandsAverageProcessingTime(workpaperId, state.batchSize);
      setProcessingTime(averageProcessingTime);

      dispatch({ type: 'endProcessingQueue' });
    }
  };

  const handleEmptyQueue = () => {
    try {
      onEmptyQueueCallbacks[0]();
    } finally {
      setOnEmptyQueueCallbacks(([, ...rest]) => rest);
    }
  };

  const startCheckStatusInterval = () => {
    const timeout = Math.max(processingTime * state.batchSize, commandCheckStatusThreshold);

    checkStatusTimeoutRef.current = setTimeout(() => {
      handleCommandsBeingProcessed();
    }, timeout);
  };

  useEffect(() => {
    if (state.commandsBeingProcessed.length && state.commandsStoreUpdated) {
      startCheckStatusInterval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.commandsBeingProcessed, state.commandsStoreUpdated]);

  useEffect(() => {
    if (state.commandsQueue.length && !state.isCommandsSaving) {
      saveCommands();
    } else if (onEmptyQueueCallbacks.length && !state.isCommandsSaving) {
      handleEmptyQueue();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, onEmptyQueueCallbacks]);

  const enqueueCommands = commands => {
    commands.map(payload => dispatch({ type: 'addCommand', payload: { ...payload, commandId: uuidv4() } }));
  };

  useEffect(() => {
    function handleOnUnload() {
      directlySaveCommands();
    }
    window.addEventListener('beforeunload', handleOnUnload);

    return () => {
      window.removeEventListener('beforeunload', handleOnUnload);
    };
  }, [directlySaveCommands]);

  useEffect(() => {
    function handleExternalCommandEnqueued(e) {
      enqueueCommands(e.detail);
    }

    window.addEventListener('enqueueExternalCommand', handleExternalCommandEnqueued);

    return () => {
      window.removeEventListener('enqueueExternalCommand', handleExternalCommandEnqueued);
    };
  }, []);

  useEffect(() => {
    function handleExternalCallbackEnqueued(e) {
      setOnEmptyQueueCallbacks(prevCallbacks => [...prevCallbacks, e.detail]);
    }

    window.addEventListener('enqueueCallback', handleExternalCallbackEnqueued);

    return () => {
      window.removeEventListener('enqueueCallback', handleExternalCallbackEnqueued);
    };
  }, []);

  return {
    isCommandsSaving: state.isCommandsSaving,
    commandsQueue: state.commandsQueue,
    enqueueCommands,
    allCommandsProcessedAsync,
    setWorkpaperId,
  };
}
