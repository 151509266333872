import React, { useContext, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { BTIcon, BTForm, BTButton, BTComboBox, BTAlert } from '@btas/jasper';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import './styles.scss';
import { getFAworkFlowNames, getFAFocusPeriods, getFASourceFileInfo, removeSourceFile } from './apis';
import { processSourceFileToCsv } from '../FileDialog/apis';
import { workflowAutomationUrl } from '../../../../configs/params';
import FAInputSubSourceDataTypes from './FAInputSubSourceDataTypes';
import { fetchRetry } from '../../shared/apis';
import * as apis from '../apis';
import { transformToConfiguration } from '../useSaveDataFlow/transformToConfiguration';
import { useInputSourceFiles } from './useInputSourceFiles';

const FAInputSubInspector = ({ elementId, elementData, elementType, updateData, isDirty }) => {
  const { url } = useRouteMatch();
  const parentUrl = url.substring(0, url.lastIndexOf('/'));

  const [wfNames, setWFNames] = useState([]);
  const [selectedWFName, setSelectedWFName] = useState(null);
  const [wfPeriods, setWfPeriods] = useState([]);
  const { dataFlowState, dataFlowActions } = useContext(DataFlowEditorContext);
  const { setElementData, setFAInputProperties, setSaveStateDirty, resetSaveMenuDirty } = dataFlowActions;
  const { faInputProperties } = dataFlowState;
  const [selectedFocusPeriod, setSelectedFocusPeriod] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const { configFields } = elementData;

  const { sourceFiles } = useInputSourceFiles(
    dataFlowState.id,
    elementId,
    elementData?.pendingSourceFileVersionId,
    false
  );

  // init cache for focus periods api call
  const initCache = () => {
    const cacheMap = new Map();
    setFAInputProperties({ ...faInputProperties, cache: cacheMap });
  };

  useEffect(() => {
    initCache();

    if (
      !faInputProperties?.elementId ||
      (faInputProperties?.elementId && elementId && faInputProperties?.elementId === elementId)
    ) {
      if (
        configFields &&
        (elementData?.hasSourceFileUploaded || (sourceFiles && !!sourceFiles[0]?.uploadedFile)) &&
        !faInputProperties?.isEdit
      ) {
        setFAInputProperties({
          showDataType: true,
          elementId,
        });
      } else {
        if (configFields) {
          setSelectedWFName({
            label: configFields.workFlowName,
            value: configFields.workFlowName,
            workflowId: configFields.workflowId,
            workFlowName: configFields.workFlowName,
          });

          if (configFields?.focusPeriod) {
            //user might save just workflow name but not focus period
            setSelectedFocusPeriod({ label: configFields.focusPeriod, value: configFields.focusPeriod });
          }

          setFAInputProperties({ ...faInputProperties, elementId });
        }
      }
    } else if (faInputProperties?.elementId && elementId && faInputProperties.elementId !== elementId) {
      // we reset input properties if we change the input block focus
      setFAInputProperties({
        showDataType: false,
        isEdit: false,
        elementId,
        cache: faInputProperties?.cache,
      });
    } else {
      setFAInputProperties(null);
      initCache();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    elementId,
    configFields,
    elementData?.hasSourceFileUploaded,
    faInputProperties?.cache?.size,
    faInputProperties?.isEdit,
    faInputProperties?.elementId,
  ]);

  useEffect(() => {
    async function getWFNames() {
      initCache();
      const wfNames = await getFAworkFlowNames();
      const nameOptions = wfNames?.map(name => ({
        ...name,
        label: name.workFlowName,
        value: name.workFlowName,
      }));
      setWFNames(nameOptions);
    }

    getWFNames();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getFocusPeriod() {
      // applied cache here to optimize performance
      const cache = faInputProperties?.cache;
      if (cache && cache.has(selectedWFName?.workflowId)) {
        setWfPeriods(cache.get(selectedWFName?.workflowId));
      } else if (selectedWFName) {
        const focusPeriods = await getFAFocusPeriods(selectedWFName.workflowId, selectedWFName.workFlowName);
        if (focusPeriods) {
          const periodsOptions = focusPeriods?.map(period => ({
            ...period,
            label: period.focusPeriod,
            value: period.focusPeriod,
          }));
          setWfPeriods(periodsOptions);
          if (selectedWFName?.workflowId && cache) {
            cache.set(selectedWFName.workflowId, periodsOptions);
          }
        }
      }
    }

    getFocusPeriod();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWFName]);

  const updateConfiguration = fields => {
    updateData({
      ...elementData,
      configFields: { ...elementData?.configFields, ...fields },
      integrationType: 'FA',
      isEdit: true,
      hasSourceFileUploaded: false,
    });

    // Enable save and publish every time the user updates workflowName or focus period
    setSaveStateDirty(true);
    resetSaveMenuDirty();
  };

  const onWFNameChange = name => {
    const { workFlowName, workflowId } = name;
    setSelectedWFName(name);
    setSelectedFocusPeriod('');
    updateConfiguration({ workFlowName, workflowId });
    setFetchError(null);
  };

  const onFocusPeriodChange = period => {
    const { focusPeriod } = period;
    setSelectedFocusPeriod(period);
    updateConfiguration({ focusPeriod });
    setFetchError(null);
  };

  const errorMessage = () => {
    const message = (
      <span>
        The application has encountered a problem. If you continue to receive this error message, contact Customer
        Support by phone (1-800-424-2938) or email&nbsp;
        <b>
          <a href="mailto:appsupport@bloombergtax.com" rel="noreferrer" target="_blank">
            appsupport@bloombergtax.com
          </a>
        </b>
        &nbsp;.
      </span>
    );
    return (
      <BTAlert appear btStyle="danger" className="wkp-tp-alert-message">
        {message}
      </BTAlert>
    );
  };

  const retrieveData = async () => {
    setFAInputProperties({ ...faInputProperties, retrieving: true });
    let sourceFile;

    try {
      // get the file info by
      sourceFile = await getFASourceFileInfo(
        selectedWFName.workflowId,
        selectedWFName.workFlowName,
        selectedFocusPeriod.value
      );
    } catch (err) {
      // retry logic
      try {
        sourceFile = await fetchRetry(
          getFASourceFileInfo(selectedWFName.workflowId, selectedWFName.workFlowName, selectedFocusPeriod.value),
          500
        );
      } catch (err) {
        setFetchError(err);
        setFAInputProperties({ ...faInputProperties, retrieving: false, processingFile: false });
        return;
      }
    }

    setFAInputProperties({ ...faInputProperties, retrieving: false, processingFile: true });
    const { bucket, path, name, jobRunAt } = sourceFile;

    if (faInputProperties?.isEdit) {
      if (faInputProperties.uploadFileName === name) {
        setFAInputProperties({ ...faInputProperties, showDataType: true, processingFile: false });
        return;
      }
      // need to remove the source file to avoid accumulate source file version for same input
      if (faInputProperties?.sourceFileId) {
        await removeSourceFile(faInputProperties?.sourceFileId, faInputProperties?.sourceFileVersionId);
      }
    }

    const updateElementData = { ...elementData, containsNewSourceFiles: true };
    let payload = {
      dataFlowId: dataFlowState.id,
      inputId: elementId,
      elementData: updateElementData,
      sheetData: { sheetName: '', dataStartAt: '', numHeaders: '' },
      sourceData: { bucket, path, name },
      taxPeriod: selectedFocusPeriod.value,
      systemCode: elementData.integrationType.toLowerCase(),
      additionalFields: {
        workFlowName: selectedWFName.workFlowName,
        workFlowId: selectedWFName.workflowId,
        focusPeriod: selectedFocusPeriod.value,
        jobRunAt,
      },
    };

    if (sourceFile.fileLocationId) {
      payload = { ...payload, fileLocationId: sourceFile.fileLocationId };
    }

    let result;
    try {
      result = await processSourceFileToCsv(payload);
    } catch (err) {
      try {
        result = await fetchRetry(processSourceFileToCsv(payload), 500);
      } catch (err) {
        setFetchError(err);
        setFAInputProperties({ ...faInputProperties, retrieving: false, processingFile: false });
        return;
      }
    }
    const { metadata, sourceFileVersionId, sourceFileId } = result;

    setFAInputProperties({
      ...faInputProperties,
      processingFile: false,
      showDataType: true,
      workflow: selectedWFName,
      focusPeriod: selectedFocusPeriod.value,
      uploadFileName: name,
      pendingSourceFileVersionId: sourceFileVersionId,
      sourceFileId,
      jobRunAt,
      isEdit: false,
    });

    setElementData(elementId, {
      ...updateElementData,
      fields: metadata.fields,
      sourceFields: metadata.fields,
      pendingSourceFileVersionId: sourceFileVersionId,
      isEdit: false,
    });

    updateData({
      ...updateElementData,
      configFields: { ...configFields, jobRunAt: jobRunAt },
      hasSourceFileUploaded: true,
      fields: metadata.fields,
      isEdit: false,
      pendingSourceFileVersionId: sourceFileVersionId,
    });

    if (faInputProperties?.isEdit) {
      const cpElements = { ...dataFlowState.elements };
      cpElements[elementId].elementData = {
        ...updateElementData,
        fields: metadata.fields,
        sourceFields: metadata.fields,
        pendingSourceFileVersionId: sourceFileVersionId,
        configFields: { ...configFields, jobRunAt: jobRunAt || '' },
        hasSourceFileUploaded: true,
      };

      await apis.saveDataFlow({
        id: dataFlowState.id,
        name: dataFlowState.name,
        configuration: transformToConfiguration(cpElements, dataFlowState.links),
      });

      updateData({
        ...elementData,
        fields: metadata.fields,
        configFields: { ...configFields, jobRunAt: jobRunAt || '' },
        pendingSourceFileVersionId: sourceFileVersionId,
        isEdit: false,
      });
    }

    // Enable save and publish every time the user tries to update a FA file
    setSaveStateDirty(true);
    resetSaveMenuDirty();
  };

  const cancelEdit = () => {
    setFAInputProperties({ ...faInputProperties, showDataType: false, isEdit: false });
    updateData({
      ...elementData,
      isEdit: false,
      hasSourceFileUploaded: elementData?.hasSourceFileUploaded ?? false,
    });

    setElementData(elementId, {
      ...elementData,
      isEdit: false,
      hasSourceFileUploaded: elementData?.hasSourceFileUploaded ?? false,
    });
  };

  return (
    <div>
      {faInputProperties?.showDataType ? (
        <FAInputSubSourceDataTypes {...{ elementData, elementId, elementType, updateData, isDirty }} />
      ) : (
        <div className="wkp-input-element-inspector">
          <h4>Source application</h4>
          <p>Bloomberg Tax Fixed Assets</p>

          <BTForm>
            <BTForm.FormGroup required htmlFor="workflowName" label="Workflow name">
              <BTComboBox
                defaultValue=""
                id="workflowName"
                name="workflowName"
                options={wfNames}
                value={selectedWFName}
                onChange={onWFNameChange}
              />
            </BTForm.FormGroup>

            <BTForm.FormGroup required label="Focus period">
              <BTComboBox
                defaultValue=""
                id="focusPeriod"
                name="focusPeriod"
                options={wfPeriods}
                value={selectedFocusPeriod}
                onChange={onFocusPeriodChange}
              />
            </BTForm.FormGroup>
          </BTForm>

          <p className="wkp-input-fa-configure-textnote">
            Don't see the workflow or focus period you are looking for?{' '}
            <a href={workflowAutomationUrl + '/fa-wa/jobs'} rel="noreferrer" target="_blank">
              Open Workflow Automation
            </a>{' '}
            to create or run a workflow
          </p>
          {fetchError && errorMessage()}
          <div>
            <div className="wkp-input-button-row">
              {faInputProperties?.isEdit && (
                <BTButton
                  btType="icon"
                  className="wkp-input-upload-file-but"
                  disabled={faInputProperties?.retrieving || faInputProperties?.processingFile}
                  onClick={cancelEdit}
                >
                  CANCEL EDIT
                </BTButton>
              )}
              <BTButton
                btStyle="primary"
                btType="icon"
                className="wkp-input-upload-file-col"
                disabled={
                  faInputProperties?.retrieving ||
                  faInputProperties?.processingFile ||
                  !selectedWFName ||
                  !selectedFocusPeriod
                }
                hasSpinner={(faInputProperties?.retrieving || faInputProperties?.processingFile) && !fetchError}
                icon={<BTIcon icon="download" />}
                onClick={retrieveData}
              >
                RETRIEVE DATA
              </BTButton>
            </div>
            {faInputProperties?.retrieving && (
              <p className="wkp-input-fa-configure-textnote-retrieving">
                Retrieving data from Bloomberg Tax Workflow Automation...
              </p>
            )}

            {faInputProperties?.processingFile && !fetchError && (
              <p className="wkp-input-fa-configure-textnote-retrieving">Detecting columns and data types...</p>
            )}
          </div>
          <Link to={parentUrl}>
            <BTIcon className="wkp-input-fa-go-back-link" icon="arrow-left" />
            Select a different data source
          </Link>
        </div>
      )}
    </div>
  );
};

export default FAInputSubInspector;
