import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import WorkbookManager from './components/_shared/WorkbookManager';
import { matchPath } from 'react-router';
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
  setDataToLocalStorage,
} from './components/_shared/storage';

export default function useEditorRedirectInterceptor(isFeatureFlagsLoaded) {
  const location = useLocation();
  const workbookManager = WorkbookManager.getInstance();

  const executeWorkbookRequest = (workpaperId, versionId) => {
    const key = workpaperId;
    const workpaperIdStorage = JSON.parse(getDataFromLocalStorage(key) || '{}');

    if (workpaperIdStorage && (workpaperIdStorage.newEmptyWorkpaper || workpaperIdStorage.newTemplateWorkpaper)) {
      setDataToLocalStorage(key, JSON.stringify({ creatingWorkpaper: true }));

      const { newEmptyWorkpaper, categoryName, templateName } = workpaperIdStorage;
      if (newEmptyWorkpaper) {
        workbookManager.runNewWorkbookRequest(workpaperId);
      } else {
        workbookManager.runNewTemplateWorkbookRequest(workpaperId, categoryName, templateName);
      }

      window.history.replaceState({}, document.title);
      workbookManager.workbookRequest.then(() => {
        removeDataFromLocalStorage(key);
      });
    } else {
      isFeatureFlagsLoaded && workbookManager.runWorkbookRequest(workpaperId, versionId);
    }
  };

  useLayoutEffect(() => {
    const urlMatchEditor = matchPath(location.pathname, {
      path: '/editor/:id',
      exact: true,
    });

    const urlMatchHistory = matchPath(location.pathname, {
      path: '/editor/:id/history/:versionId',
      exact: true,
    });

    if ((urlMatchEditor && urlMatchEditor.params) || (urlMatchHistory && urlMatchHistory.params)) {
      const workpaperId = urlMatchEditor?.params['id'] || urlMatchHistory?.params['id'];

      if (!workpaperId) return;

      const versionId = urlMatchHistory?.params['versionId'];
      executeWorkbookRequest(workpaperId, versionId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
}
