import React, { useMemo, useEffect, useContext } from 'react';

import { BTCheckbox, BTForm, BTInput, BTButton } from '@btas/jasper';
import ElementInspector from './shared/ElementInspector';

import { DataFlowEditorContext } from './DataFlowEditorContext';
import { useDebouncedEffect } from './shared/useDebouncedEffect';
import useColumnValidation from './AdvancedTransposeElementInspector/useColumnValidation';
import { getOriginalName } from '../shared/utils/FieldHashUtils';

export const AdvancedTransposeElementInspector = ({
  elementId,
  elementData,
  elementType,
  updateData,
  inputElements,
  isDirty,
}) => {
  const {
    dataFlowState,
    previewState: { elementConfiguration },
    dataFlowActions: { syncWorkingElement },
  } = useContext(DataFlowEditorContext);

  const { checkedFields, advancedTransposeFieldName, advancedTransposeFieldValue } = elementData;
  const { workingElement } = dataFlowState;
  const fields = useMemo(() => elementData.sourceFields ?? [], [elementData.sourceFields]);
  var { validateColumn, columnError } = useColumnValidation();

  const columnMappingCheckboxes = useMemo(() => {
    const handleTabularOutputChange = ({ target }) => {
      const { id, checked } = target;
      updateData({ checkedFields: { ...checkedFields, [id]: checked } });
    };

    return fields.map(({ original_name, name }) => (
      <BTCheckbox
        key={name}
        checked={checkedFields && checkedFields[name]}
        id={name}
        label={getOriginalName({ original_name, name })}
        onChange={handleTabularOutputChange}
      />
    ));
  }, [fields, checkedFields, updateData]);

  const alerts = useMemo(() => {
    if (!Object.values(inputElements || []).some(v => v)) {
      return [{ style: 'danger', content: 'input port is not connected.' }];
    }
  }, [inputElements]);

  const handleAdvancedTransposeFieldNameChange = ({ target: { value } }) => {
    updateData({ advancedTransposeFieldName: value });
  };

  const handleAdvancedTransposeFieldValueChange = ({ target: { value } }) => {
    updateData({ advancedTransposeFieldValue: value });
  };

  useDebouncedEffect(
    () => validateColumn(advancedTransposeFieldName, advancedTransposeFieldValue, checkedFields, fields),
    350,
    [validateColumn, advancedTransposeFieldName, fields]
  );

  useDebouncedEffect(
    () => validateColumn(advancedTransposeFieldValue, advancedTransposeFieldName, checkedFields, fields),
    350,
    [validateColumn, advancedTransposeFieldValue, fields]
  );

  useEffect(() => {
    let element = elementConfiguration(workingElement.id);

    if (element && element.fields) {
      syncWorkingElement({ fields: element.fields });
    }
  }, [syncWorkingElement, elementConfiguration, workingElement?.id]);

  const handleSelectAll = () => {
    const checkedAllFields = { ...checkedFields };
    Object.keys(checkedAllFields).forEach(key => {
      checkedAllFields[key] = true;
    });
    updateData({ checkedFields: checkedAllFields });
  };

  return (
    <ElementInspector {...{ alerts, elementData, elementId, elementType, updateData, isDirty }}>
      {Object.keys(checkedFields).length && !alerts ? (
        <div>
          <BTForm onSubmit={e => e.preventDefault()}>
            <BTForm.FormGroup label="Select columns to convert into name/value pairs.">
              <p>
                <BTButton btStyle="link" onClick={handleSelectAll}>
                  Select All
                </BTButton>
              </p>
              <div className="wkp-column-items">{columnMappingCheckboxes}</div>
            </BTForm.FormGroup>
            <BTForm.FormGroup
              errorText={columnError}
              hasError={!!columnError}
              label="Name of the column containing the headers above"
            >
              <BTInput value={advancedTransposeFieldName} onChange={handleAdvancedTransposeFieldNameChange} />
            </BTForm.FormGroup>
            <BTForm.FormGroup
              errorText={columnError}
              hasError={!!columnError}
              label="Name of the column containing the values"
            >
              <BTInput value={advancedTransposeFieldValue} onChange={handleAdvancedTransposeFieldValueChange} />
            </BTForm.FormGroup>
          </BTForm>
        </div>
      ) : (
        ''
      )}
    </ElementInspector>
  );
};
