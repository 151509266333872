import React, { useContext, useEffect, useMemo } from 'react';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import FilePropertiesPanel from '../FileSettings/FilePropertiesPanel';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { WKP_INPUT_FILE_IMPORT } from '../../../../constants/featureFlags';
import { useHistory } from 'react-router-dom';

const WKPImportSubInspector = ({ elementId, elementData, elementType, updateData, isDirty }) => {
  const history = useHistory();
  const { dataFlowState, sourceFileUpload } = useContext(DataFlowEditorContext);
  const { workingElement, id: dataflowId } = dataFlowState;

  const { uploadError, uploadedFilesByInput, setUploadedFilesByInput } = sourceFileUpload;

  const filePropertiesPanel = useMemo(
    () => <FilePropertiesPanel elementData={elementData} elementId={elementId} updateData={updateData} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elementId, elementData, updateData]
  );

  useEffect(() => {
    if (isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT)) {
      const uploadedFile = uploadedFilesByInput && uploadedFilesByInput[elementId];
      //Add WKP-IMPORT integrationType to render FilePropertiesPanel
      if (uploadedFile && uploadedFile.status !== 'error') {
        updateData({
          ...elementData,
          integrationType: 'WKP-IMPORT',
          uploadedFile,
        });
      }

      if (uploadedFile && uploadedFile.status === 'error') {
        updateData({
          ...elementData,
          integrationType: '',
          uploadedFile: undefined,
        });

        const newUploadedFiles = uploadedFilesByInput;
        delete newUploadedFiles[workingElement.id];
        setUploadedFilesByInput(newUploadedFiles);

        history.replace(`/data-flows/${dataflowId}/editor`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFilesByInput, elementData, updateData, elementId, uploadError, setUploadedFilesByInput, workingElement]);

  return (
    <div className="wkp-input-element-inspector">
      {isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) && elementData?.uploadedFile && filePropertiesPanel}
    </div>
  );
};

export default WKPImportSubInspector;
