import { React, useCallback, useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BTGrid, BTButton, BTInput, BTComboBox, BTInputGroup, BTDatePicker } from '@btas/jasper';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { wijmoKey } from '../../../configs/params';
import WorkpapersConnectionsListModal from './DataFlowsList/WorkpapersConnectionsListModal';
import SourceFilesModal from './DataFlowsList/SourceFilesModal';
import data_transformation_editor_icon from './DataFlowsList/Data_transformation_editor_icon.svg';
import data_transformation_editor_icon_template from './DataFlowsList/Data_transformation_editor_icon_template.svg';
import './DataFlowsList/styles.scss';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { DF_GLOBAL_TEMPLATES, WKP_DATAFLOW_SIDEBAR } from '../../../constants/featureFlags';
import { getTemplateList } from './apis';
import { CanCreateWorkflow } from '../../auth/permissions/CanCreateWorkflow';
import { CanDeleteWorkflow } from '../../auth/permissions/CanDeleteWorkflow';
import { statusColor } from '../DataFlowEditorPage/shared/statusColors';

export default function DataFlowsList({
  onViewSourceData,
  onCreateDataFlowClick,
  onSelectionChanged,
  onDeleteClick,
  config,
  actions,
}) {
  const history = useHistory();
  const { gridFilters, gridData, initialFilters, selectedDataFlows, defaultSort } = config;
  const [workpapersConnectionsModal, setWorkpapersConnectionsModal] = useState({
    show: false,
    dataFlowId: null,
    dataFlowName: null,
  });
  const { items, filterOptions } = gridData;
  const [gridDataItems, setGridDataItems] = useState(items);
  const { setGridSorting, setGridFilters } = actions;

  const [sourceFilesModal, setSourceFilesModal] = useState({
    show: false,
    dataFlowId: null,
    dataFlowName: null,
  });

  const noItemsSelected = selectedDataFlows.length === 0;
  const onSortChange = order => setGridSorting(order);
  const handleSort = columnSort => {
    onSortChange(columnSort);
  };

  const onFilterChange = args => setGridFilters(prev => ({ ...prev, ...args }));
  const handleClearFilters = () => {
    setGridFilters(initialFilters);
  };
  const handleFilterChange = (field, value) => {
    onFilterChange({ [field]: value });
  };
  const handleStatusFilterChange = (field, value) => {
    onFilterChange({ [field]: value?.value });
  };

  const isFiltered = useMemo(() => Object.values(gridFilters).some(filter => filter), [gridFilters]);

  useEffect(() => {
    const listOfTemp = async () => {
      const defaultSort = { binding: 'name', direction: 'ASC' };
      const filters = {};
      let data = await getTemplateList({ defaultSort, filters });
      const ids = data.items.map(template => template.id);
      const updateItems = items.map(item => {
        return ids.includes(item.id) ? { ...item, isGlobalTemplate: true } : { ...item, isGlobalTemplate: false };
      });
      setGridDataItems(updateItems);
    };
    listOfTemp();
  }, [items]);

  const nameCell = ({ rowData }) => {
    let dataFlowEditorIcon = data_transformation_editor_icon;
    if (isFeatureFlagEnabled(DF_GLOBAL_TEMPLATES)) {
      dataFlowEditorIcon = rowData.isGlobalTemplate
        ? data_transformation_editor_icon_template
        : data_transformation_editor_icon;
    }
    const isUnpublished = !rowData.isValid || !rowData.lastRunTimestamp || !rowData.publishedWithNoNewRuns;
    return (
      <>
        <BTButton
          btStyle="link"
          icon={
            <img
              alt="Data flow icon"
              aria-hidden="true"
              className="wkp-data-flow-table-icon"
              src={dataFlowEditorIcon}
            />
          }
          onClick={() => handleNameClick(rowData.id)}
        >
          {rowData.name}
        </BTButton>
        {isUnpublished && <span className="df-status-tag df-status-unpublished">Unpublished</span>}
      </>
    );
  };
  const linkedWorkpapersCell = ({ rowData }) => (
    <BTButton
      btStyle="link"
      onClick={() =>
        handleWorkpapersConnectionsClick(rowData.id, rowData.name, Object.keys(rowData.lastRun_configuration.elements))
      }
    >
      {rowData.linkedWorkpapers}
    </BTButton>
  );

  const sourceFilesCell = ({ rowData }) => (
    <BTButton btStyle="link" onClick={() => handleSourceFilesClick(rowData.id, rowData.name)}>
      {rowData.sourceFiles}
    </BTButton>
  );

  const lastModifiedCell = ({ rowData }) => {
    const initial = rowData?.lastModifiedUserFirstName?.charAt(0).toLocaleUpperCase() ?? '';
    return (
      <>
        {initial && (
          <span className="user-initials">
            <span className="user-initials-inner" title={rowData?.lastModifiedUserFirstName}>
              {initial}
            </span>
          </span>
        )}
        {rowData.lastModifiedDate}
      </>
    );
  };

  const handleNameClick = useCallback(id => history.push(`/data-flows/${id}/editor`), [history]);
  const handleWorkpapersConnectionsClick = (id, name, outputIds) =>
    setWorkpapersConnectionsModal({ show: true, dataFlowId: id, dataFlowName: name, linkedOutputIds: outputIds });
  const handleWorkpapersConnectionsModalClose = () =>
    setWorkpapersConnectionsModal({ show: false, dataFlowId: null, linkedOutputIds: null });

  const handleSourceFilesClick = (id, name, outputIds) => {
    setSourceFilesModal({ show: true, dataFlowId: id, dataFlowName: name });
  };

  const handleSourceFilesModalClose = () => setSourceFilesModal({ show: false, dataFlowName: null, dataFlowId: null });

  return (
    <>
      <Row>
        <Col>
          <div className="wkp-data-flows-list-section">
            <h2>Data Transformation</h2>

            <div className="wkp-data-flows-list-container">
              <div className="wkp-data-flows-grid-actions">
                <CanCreateWorkflow
                  onYes={() => (
                    <BTButton
                      aria-label="newDataFlow"
                      btStyle="link"
                      btType="icon"
                      icon={faPlus}
                      id={'newDataFlow'}
                      onClick={onCreateDataFlowClick}
                    >
                      New data transformation workflow
                    </BTButton>
                  )}
                />

                <CanDeleteWorkflow
                  onYes={() => (
                    <BTButton
                      btStyle="link"
                      btType="delete"
                      disabled={noItemsSelected}
                      title={noItemsSelected ? 'Select one or more items to delete' : ''}
                      onClick={onDeleteClick}
                    >
                      Delete
                    </BTButton>
                  )}
                />
              </div>

              <BTGrid
                allowSelection
                className="wkp-data-flows-grid"
                data={gridDataItems}
                defaultSortedColumn={[defaultSort]}
                wijmoKey={wijmoKey}
                onClearFilterClick={handleClearFilters}
                onSelectionChanged={onSelectionChanged}
              >
                <BTGrid.Header binding="sel" minWidth={50} width="*">
                  sel
                </BTGrid.Header>
                <BTGrid.Header
                  key="name"
                  align="left"
                  binding="name"
                  cellTemplate={nameCell}
                  minWidth={200}
                  width="2*"
                  onSort={handleSort}
                >
                  Name
                </BTGrid.Header>
                <BTGrid.Header key="taxPeriod" binding="taxPeriod" width="*" onSort={handleSort}>
                  Tax period
                </BTGrid.Header>
                <BTGrid.Header
                  key="status"
                  align="left"
                  binding="status"
                  cellTemplate={context => (
                    <>
                      <span
                        className="bt-status-color-dot"
                        style={{ backgroundColor: statusColor[context.rowData.status] }}
                      />
                      {context.text}
                    </>
                  )}
                  hidden={!isFeatureFlagEnabled(WKP_DATAFLOW_SIDEBAR)}
                  minWidth={140}
                  width="*"
                  onSort={handleSort}
                >
                  Status
                </BTGrid.Header>
                <BTGrid.Header
                  key="sourceFiles"
                  binding="sourceFilesImported"
                  cellTemplate={sourceFilesCell}
                  minWidth={140}
                  width="*"
                  onSort={handleSort}
                >
                  Source files
                </BTGrid.Header>
                <BTGrid.Header
                  key="linkedWorkpapers"
                  align="left"
                  binding="linkedWorkpapers"
                  cellTemplate={linkedWorkpapersCell}
                  minWidth={180}
                  width="*"
                  onSort={handleSort}
                >
                  Linked workpapers
                </BTGrid.Header>
                <BTGrid.Header
                  key="lastModifiedDate"
                  binding="lastModifiedDate"
                  cellTemplate={lastModifiedCell}
                  minWidth={180}
                  width="*"
                  onSort={handleSort}
                >
                  Last modified
                </BTGrid.Header>
                <BTGrid.Header key="notes" binding="notes" width={1}>
                  {''}
                </BTGrid.Header>

                <BTGrid.Filter binding="name">
                  <BTInput
                    name="name"
                    value={gridFilters.name}
                    onChange={e => handleFilterChange('name', e.target.value)}
                  />
                </BTGrid.Filter>
                <BTGrid.Filter binding="taxPeriod">
                  <BTComboBox
                    isClearable
                    maxMenuHeight={100}
                    name="taxPeriod"
                    options={filterOptions.taxPeriods}
                    value={gridFilters.taxPeriod}
                    onChange={opt => handleFilterChange('taxPeriod', opt)}
                  />
                </BTGrid.Filter>
                <BTGrid.Filter binding="status">
                  <BTComboBox
                    isClearable
                    maxMenuHeight={100}
                    name="status"
                    options={filterOptions.statuses}
                    value={gridFilters.status}
                    onChange={opt => handleStatusFilterChange('status', opt)}
                  />
                </BTGrid.Filter>
                <BTGrid.Filter binding="lastModifiedDate">
                  <BTInputGroup>
                    <BTDatePicker
                      dateSelected={gridFilters.lastModifiedFrom}
                      placeholder=""
                      popperProps={{
                        positionFixed: true,
                      }}
                      onChange={date => handleFilterChange('lastModifiedFrom', date)}
                    />
                    <BTInputGroup.Addon>to</BTInputGroup.Addon>
                    <BTDatePicker
                      dateSelected={gridFilters.lastModifiedTo}
                      placeholder=""
                      popperProps={{
                        positionFixed: true,
                      }}
                      onChange={date => handleFilterChange('lastModifiedTo', date)}
                    />
                  </BTInputGroup>
                </BTGrid.Filter>
                <BTGrid.EmptyState isFiltered={isFiltered} />
              </BTGrid>
            </div>
          </div>
        </Col>
      </Row>
      <WorkpapersConnectionsListModal
        dataFlowName={workpapersConnectionsModal.dataFlowName}
        linkedOutputIds={workpapersConnectionsModal.linkedOutputIds}
        show={workpapersConnectionsModal.show}
        onClose={handleWorkpapersConnectionsModalClose}
      />
      <SourceFilesModal
        dataFlowId={sourceFilesModal.dataFlowId}
        dataFlowName={sourceFilesModal.dataFlowName}
        show={sourceFilesModal.show}
        onClose={handleSourceFilesModalClose}
        onViewSourceData={onViewSourceData}
      />
    </>
  );
}
