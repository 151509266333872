import * as fetch from '../../../_shared/fetch';
import { uploadSourceFile } from '../../shared/apis';
import { dataFlowServiceHostName } from '../../../../configs/params';

export async function uploadPendingInputFile(dataFlowId, inputId, sampleFile) {
  const uploadResult = await uploadSourceFile(sampleFile);

  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/${inputId}/pending`;

  const res = await fetch.post(reqUri, { body: JSON.stringify(uploadResult) });

  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function getDataFlowSourceFileVersion(dataFlowId, sourceFileVersionId) {
  const response = await fetch.get(
    `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-file-versions/${sourceFileVersionId}`
  );
  return response.json();
}

export async function validateSourceFileFields(dataFlowId, newFields, inputId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/${inputId}/validate`;
  const res = await fetch.post(reqUri, { body: JSON.stringify({ newFields }) });

  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function removeSourceFileData(sourceFileId, dataFlowId, inputId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/${sourceFileId}/inputs/${inputId}/delete`;
  const res = await fetch.remove(reqUri);

  return res.json();
}

export async function removeSourceFile(sourceFileId, sourceFileVersionId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/${sourceFileId}/versions/${sourceFileVersionId}/delete`;
  const res = await fetch.remove(reqUri);

  return res.json();
}

export async function validatePermanentDelete(dataFlowId, inputId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/inputs/${inputId}/validate-permanent-delete`;
  const res = await fetch.get(reqUri);

  return res.json();
}

export async function removeSourceFilePermanently(deleteInfos, systemCode) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/${systemCode}/permanent-delete`;
  const res = await fetch.remove(reqUri, { body: JSON.stringify(deleteInfos) });

  return res.json();
}

export async function getFAworkFlowNames() {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/FA/workflow-names`;
  const res = await fetch.get(reqUri);
  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function getFAFocusPeriods(workFlowId, workFlowName) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/FA/focus-periods/${workFlowId}/${workFlowName}`;

  const res = await fetch.get(reqUri);
  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function getFASourceFileInfo(workFlowId, workFlowName, focusPeriod) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/FA/source-file/${workFlowId}/${workFlowName}/${focusPeriod}`;

  const res = await fetch.get(reqUri);
  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function isValidFAUser() {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/FA/validate-access`;

  const res = await fetch.get(reqUri);
  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function getSFTPlistObject() {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/SFTP/files`;

  const res = await fetch.get(reqUri);
  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}
