import React from 'react';
import TextField from './AutoCompleteField/AutoCompleteField';
import { functions, triggerStrings, functionOptions, hintOptions, fieldOptions } from './AutoCompleteField/constants';

const FormulaTextArea = ({ fields, maxLength, error, value, onChange }) => {
  const { byFunc, byHint, byFields } = triggerStrings;

  return (
    <TextField
      error={error}
      maxLength={maxLength}
      options={{
        ...functionOptions,
        ...fieldOptions(fields),
        ...hintOptions,
      }}
      spacer=""
      supportedFunctions={functions}
      trigger={[...byFunc, ...byFields, byHint]}
      value={value}
      onChange={onChange}
    />
  );
};

export default FormulaTextArea;
