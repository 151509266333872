import React, { useState, useEffect, useContext } from 'react';
import GC from '../../../../../SpreadSheets';
import AddDataConnection from './ConnectionPanel/AddDataConnection';
import EditDataConnection from './ConnectionPanel/EditDataConnection';
import EditorContext from '../../EditorContext';
import { getDataFlows, getDataFlowAvailableCriteria } from './ConnectionPanel/apis';
import { isCellReference, registerGlobalCustomFunctions, setCellDataSourceFormula } from '../_spreadsheets';
import { putTaxPeriodMetadata } from './ConnectionPanel/apis';
import { isEmpty } from 'lodash';

export default function ConnectionsPanel({ cellDataSourceConnection, onClose, onCellSelection, workpaperId }) {
  const defaultFormData = { fieldErrors: {}, formError: '' };

  const [formData, setFormData] = useState(defaultFormData);
  const [dataFlowGroups, setDataFlowGroups] = useState([]);
  const [filters, setFilters] = useState([]);
  const {
    reducerValue: {
      actions: { handleTriggerSave },
    },
    enqueueCommands,
    spreadRef,
    enqueueDataReference,
    renderCustomFormulaValues,
    getCellReferenceTag,
    gcBaseCustomFunction,
    enqueueDataReferenceReCalc,
    isDragFillAction,
    isCopyPasteAction,
    isLocked,
    referenceExistInWorksheet,
    referenceExistInTargetCell,
    referenceIsEnqueuedForRecalc,
    isFormulaMatch,
    stateTaxJurisdictions,
    isGlobalTemplate,
    cellChangedData,
  } = useContext(EditorContext);

  const spread = spreadRef?.current;

  useEffect(() => {
    fetchDataFlows();
    setFormData(defaultFormData);
    // Removing lint checks for dependencies because fetchDataFlows won't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellDataSourceConnection]);

  useEffect(() => {
    onCellSelection();
    // Removing lint checks for dependencies because there is no need to re-execute
    // when onCellSelection changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCellFormData();
    // Removing lint checks for dependencies because setCellFormData won't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFlowGroups, cellDataSourceConnection]);

  useEffect(() => {
    const dataFlowId = formData.dataFlow?.id;
    const outputId = formData.output;

    if (dataFlowId && outputId) {
      fetchFilters(dataFlowId, outputId);
    }
    // Removing lint checks for dependencies because fetchFilters won't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.dataFlow?.id, formData.output, dataFlowGroups]);

  const setCellFormData = () => {
    if (!isEmpty(cellDataSourceConnection)) {
      const { selectedFilters, dataFlowOutputElementId, outputField } = cellDataSourceConnection;

      setFormData({
        selectedFilters,
        output: dataFlowOutputElementId,
        outputField,
        dataFlow: dataFlowGroups
          ? dataFlowGroups
              .flatMap(({ dataFlows }) => dataFlows)
              .find(({ outputs }) => outputs.some(({ id }) => id === dataFlowOutputElementId))
          : {},
        fieldErrors: {},
        formError: '',
      });
    }
  };

  const fetchDataFlows = async () => {
    setDataFlowGroups(null);

    const _dataFlowGroups = await getDataFlows();
    setDataFlowGroups(_dataFlowGroups);
  };

  const fetchFilters = async (dataFlowId, outputId) => {
    setFilters(null);

    const { filters } = await getDataFlowAvailableCriteria(dataFlowId, outputId);
    setFilters(filters);
  };

  const handleSaveConnection = async () => {
    const sheet = spread.getActiveSheet();
    const metadataTaxPeriod = GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, 'TAX_PERIOD()', 0, 0);

    const {
      dataFlow: { taxPeriod },
      output,
      outputField: outputFieldValue,
      selectedFilters,
    } = formData;

    if (taxPeriod && !metadataTaxPeriod) {
      try {
        await putTaxPeriodMetadata({ id: workpaperId, taxPeriod });
      } catch (error) {
        console.log(error);
      }

      registerGlobalCustomFunctions(
        taxPeriod,
        workpaperId,
        enqueueCommands,
        spread,
        enqueueDataReference,
        renderCustomFormulaValues,
        getCellReferenceTag,
        gcBaseCustomFunction,
        enqueueDataReferenceReCalc,
        isDragFillAction,
        isCopyPasteAction,
        referenceExistInWorksheet,
        referenceExistInTargetCell,
        referenceIsEnqueuedForRecalc,
        isFormulaMatch,
        stateTaxJurisdictions,
        cellChangedData
      );
    }

    const formulaFilters = {};

    for (const key in selectedFilters) {
      const {
        labelReference,
        cell: { value: filterValue, reference },
      } = selectedFilters[key];

      const criteria = filters.find(filter => filter.name === key);
      const isNewRawInput = criteria?.type === 'numeric' && !reference && isNaN(filterValue);

      const value = reference ? { formula: reference } : filterValue;
      let type;

      if (reference) type = 'formula';
      else if (isNewRawInput) type = 'text';
      else type = criteria?.type;

      formulaFilters[key] = { value, type, nameReference: labelReference };
    }

    const isNewOutputField = !filters.some(({ name }) => name === outputFieldValue);
    const outputFieldType = isNewOutputField && isCellReference(spread, outputFieldValue) ? 'formula' : 'text';
    const outputField = {
      [outputFieldType === 'formula' ? 'formula' : 'value']: outputFieldValue,
      type: outputFieldType,
    };

    await setCellDataSourceFormula({
      col: sheet.getActiveColumnIndex(),
      filters: formulaFilters,
      output,
      outputField,
      row: sheet.getActiveRowIndex(),
      sheet,
      spread,
      sdcFormulaWrapper: cellDataSourceConnection?.sdcFormulaWrapper,
    });

    handleTriggerSave(true);
    onCellSelection();
  };

  const handleDeleteConnection = async () => {
    const commandManager = spread.commandManager();
    const sheet = spread.getActiveSheet();

    const row = sheet.getActiveRowIndex();
    const col = sheet.getActiveColumnIndex();
    const sheetId = sheet._id;
    const sheetName = sheet.name();

    commandManager.execute({
      cmd: 'clearValues',
      ranges: [new GC.Spread.Sheets.Range(row, col, 1, 1)],
      sheetId,
      sheetName,
    });
    sheet.setValue(row, col, null);
    sheet.setFormula(row, col, '');
    sheet.repaint(sheet.getCellRect(row, col));

    onCellSelection();
  };

  const saveButtonDisabled = !formData.outputField || Object.values(formData.fieldErrors).some(error => error != null);

  return cellDataSourceConnection ? (
    <EditDataConnection
      cellDataSourceConnection={cellDataSourceConnection}
      dataFlowGroups={dataFlowGroups}
      filters={filters}
      formData={formData}
      readOnly={isLocked || isGlobalTemplate}
      saveButtonDisabled={saveButtonDisabled}
      setCellFormData={setCellFormData}
      workpaperId={workpaperId}
      onClose={onClose}
      onDeleteConnection={handleDeleteConnection}
      onFieldChange={setFormData}
      onSaveConnection={handleSaveConnection}
    />
  ) : (
    <AddDataConnection
      dataFlowGroups={dataFlowGroups}
      filters={filters}
      formData={formData}
      readOnly={isLocked || isGlobalTemplate}
      saveButtonDisabled={saveButtonDisabled}
      onClose={onClose}
      onFieldChange={setFormData}
      onSaveConnection={handleSaveConnection}
    />
  );
}
