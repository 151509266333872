import { useContext } from 'react';
import { UserPermissionsContext } from '../../_shared/UserPermissionsContext';

export function CanCreateWorkflow({ onYes, onNo = () => null }) {
  const { userPermissions } = useContext(UserPermissionsContext);

  const check = function () {
    return userPermissions.includes('create_workflow');
  };

  return check() ? onYes() : onNo();
}

export default CanCreateWorkflow;
