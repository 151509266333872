import { BTGrid } from '@btas/jasper';
import React, { useContext, useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { wijmoKey } from '../../../configs/params';
import Spinner from '../../_shared/Spinner';
import { DataFlowEditorContext } from './DataFlowEditorContext';
import PreviewError from './DataFlowOutputPreview/PreviewError';
import {
  getOutputRecords,
  getColumns,
  formatOutputRecords,
  objToHeadersWithoutRecords,
} from './DataFlowOutputPreview/utils';

import { getOriginalName } from '../shared/utils/FieldHashUtils';

import { TEXT } from './shared/fieldTypes';
import './DataFlowOutputPreview/styles.scss';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { IMPROVE_DATAFLOW_PREVIEW } from '../../../constants/featureFlags';
import SpreadView from './DataFlowOutputPreview/SpreadView';
import arrowUp from './DataFlowOutputPreview/icons/Double-chevron-up.svg';
import { INPUT } from '../DataFlowEditorPage/elementType/types/shared/typesConstants';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';

export default function DataFlowOutputPreview({ callback }) {
  const { dataFlowState, previewState, previewActions } = useContext(DataFlowEditorContext);
  const { activeElement } = dataFlowState;
  const { workingElement } = dataFlowState;
  const { runPreview, setToggleInput } = previewActions;
  const { previewRun, isWaiting, error, validationErrors, previewRecords, totalRecordsCount } = previewState;

  const [previewRecordsCount, setPreviewRecordsCount] = useState({
    count: 0,
    isFiltered: false,
    isSorted: false,
  });

  const hasErrors = error || validationErrors;

  const outputPreviewRecords = getOutputRecords(dataFlowState, previewRecords);
  const previewFields = activeElement?.elementData?.fields;

  const formattedOutputPreviewRecords = useMemo(
    () => formatOutputRecords(previewFields, outputPreviewRecords),
    [outputPreviewRecords, previewFields]
  );

  const activeElementId = useMemo(() => activeElement?.id, [activeElement?.id]);

  const canRunPreview = activeElement && activeElement.elementType.canPreview(activeElement.elementData);

  const previewInspector = useMemo(() => activeElement && canRunPreview, [activeElement, canRunPreview]);

  const [isExpanded, setExpanded] = useState(false);
  const arrowRef = useRef(null);

  const upTip = useRef(null);
  const dnTip = useRef(null);

  const resetView = () => {
    setPreviewRecordsCount({
      count: 0,
      isFiltered: false,
      isSorted: false,
    });
  };

  useEffect(() => {
    callback(previewInspector);
  }, [callback, previewInspector]);

  useEffect(() => {
    if (activeElementId) {
      resetView();
    }
  }, [activeElementId]);

  useEffect(() => {
    if (activeElementId && canRunPreview) {
      runPreview(activeElementId);
    }

    setToggleInput(null);
  }, [setToggleInput, activeElementId, canRunPreview, runPreview, activeElement?.type]);

  const columns = useMemo(
    () => getColumns(activeElement, previewRun?.configuration?.elements[activeElementId]),
    [activeElement, previewRun, activeElementId]
  );

  const dataFiltered = useMemo(() => previewRecordsCount?.isFiltered, [previewRecordsCount?.isFiltered]);

  const sortedFormattedOutputPreviewRecords = useMemo(() => {
    if (isFeatureFlagEnabled(IMPROVE_DATAFLOW_PREVIEW) && columns) {
      // sort the elements so it matches with the ones displayed in the Block Configuration Panel
      const columnNames = columns
        .filter(column => column.hidden === false || column.hidden === undefined)
        .map(column => getOriginalName(column));

      const headers = objToHeadersWithoutRecords(columns);

      const records =
        formattedOutputPreviewRecords.records?.length > 0
          ? formattedOutputPreviewRecords.records?.map(record => {
              const row = {};
              columnNames.forEach(name => (row[name] = record[name]));

              return row;
            })
          : headers;

      return { records, recordTypes: formattedOutputPreviewRecords.recordTypes };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, formattedOutputPreviewRecords, dataFiltered]);

  const getGrid = useCallback(() => {
    if (isFeatureFlagEnabled(IMPROVE_DATAFLOW_PREVIEW)) {
      return (
        <SpreadView
          expand={isExpanded}
          previewRecordsCount={previewRecordsCount}
          records={sortedFormattedOutputPreviewRecords}
          setPreviewRecordsCount={setPreviewRecordsCount}
        ></SpreadView>
      );
    } else {
      return (
        <BTGrid
          stickyHeaders
          allowResizing={1}
          className="wkp-preview-grid"
          data={formattedOutputPreviewRecords}
          gridHeight={200}
          wijmoKey={wijmoKey}
        >
          {columns.map(({ name, type, isSelected, hidden }) => (
            <BTGrid.Header
              key={name}
              align="left"
              binding={name}
              columnClass={isSelected === false ? 'wkp-shaded' : null}
              hidden={hidden}
              minWidth={240}
              width={type === TEXT ? '4*' : '3*'}
            >
              {name}
            </BTGrid.Header>
          ))}
        </BTGrid>
      );
    }
  }, [isExpanded, previewRecordsCount, sortedFormattedOutputPreviewRecords, formattedOutputPreviewRecords, columns]);

  const returnGrid = () => {
    return hasErrors ? <PreviewError error={error} validationErrors={validationErrors} /> : getGrid();
  };

  const getPreviewTitle = useCallback(() => {
    const previewText = 'Preview Output ';
    const PREVIEW_RECORDS_LIMIT = 100;

    if (isWaiting) {
      return previewText.concat(`(loading records...)`);
    }

    if (hasErrors) {
      return previewText.concat(`(showing 0 records)`);
    }

    const containsNewSourceFiles = workingElement?.elementData?.containsNewSourceFiles;
    //if the input block has not any new files uploaded, then input title should show 0 records
    if (containsNewSourceFiles !== undefined && !containsNewSourceFiles && activeElement?.type === INPUT) {
      const defaultRecordsCount = 0;
      return previewText.concat(`(showing ${defaultRecordsCount} of ${defaultRecordsCount} records)`);
    }

    const filteredText = previewRecordsCount?.isFiltered && !isWaiting ? ' – Filtered' : '';
    if (totalRecordsCount && totalRecordsCount > 100) {
      const count = previewRecordsCount.isFiltered ? previewRecordsCount.count : PREVIEW_RECORDS_LIMIT;
      return previewText.concat(`(showing ${count} of ${totalRecordsCount} records)${filteredText}`);
    } else {
      const count = previewRecordsCount.isFiltered ? previewRecordsCount.count : previewRecords?.length;
      return previewText.concat(`(showing ${count} of ${previewRecords?.length} records)${filteredText}`);
    }
  }, [
    activeElement?.type,
    hasErrors,
    isWaiting,
    previewRecords?.length,
    previewRecordsCount,
    totalRecordsCount,
    workingElement?.elementData?.containsNewSourceFiles,
  ]);

  const handleArrow = () => {
    setExpanded(!isExpanded);
    arrowRef.current.classList.toggle('active');
  };

  const handleToolTips = () => {
    if (!isExpanded) {
      upTip.current.style.visibility = 'visible';
      dnTip.current.style.visibility = 'hidden';
    } else if (!isWaiting) {
      upTip.current.style.visibility = 'hidden';
      dnTip.current.style.visibility = 'visible';
    }
  };

  const clearToolTips = () => {
    upTip.current.style.visibility = 'hidden';
    dnTip.current.style.visibility = 'hidden';
  };

  const canEditWorkflow = useCanEditWorkflow();

  let previewClass;
  if (isExpanded) {
    previewClass = canEditWorkflow ? 'wkp-preview-expand' : 'wkp-preview-expand-no-palette';
  } else {
    previewClass = canEditWorkflow ? 'wkp-preview' : 'wkp-preview-no-palette';
  }

  const returnImprovePreview = () => {
    if (isFeatureFlagEnabled(IMPROVE_DATAFLOW_PREVIEW)) {
      return (
        <div className={previewClass}>
          <h4 className="wkp-preview-title">
            <span>{getPreviewTitle()} </span>
            {(previewRecordsCount?.isFiltered || previewRecordsCount?.isSorted) && (
              <span className="title-reset-view" onClick={() => resetView()}>
                Reset View
              </span>
            )}

            <div className="wkp-preview-pane-toolTip">
              <img
                ref={arrowRef}
                alt="wkp-preview-pane-arrow"
                className="wkp-preview-pane-arrow wkp-preview-title-pointer"
                src={arrowUp}
                onClick={() => handleArrow()}
                onMouseLeave={clearToolTips}
                onMouseOver={handleToolTips}
              />
              <span ref={upTip} className="wkp-preview-pane-tooltiptext">
                Slide up
              </span>
              <span ref={dnTip} className="wkp-preview-pane-tooltiptext">
                Slide down
              </span>
            </div>
          </h4>
          <div className="wkp-preview-body">{isWaiting ? <Spinner /> : returnGrid()}</div>
        </div>
      );
    } else {
      return (
        <div className="wkp-preview">
          <h4 className="wkp-preview-title">Preview (first 100 records)</h4>
          <div className="wkp-preview-body">{isWaiting ? <Spinner /> : returnGrid()}</div>
        </div>
      );
    }
  };

  return previewInspector ? returnImprovePreview() : null;
}
