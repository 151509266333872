import GC from '../../../../../../SpreadSheets';
import { INT_TAX_RATE } from '../../../../../_shared/DataReference/ReferenceType';
import { v4 as uuidv4 } from 'uuid';
import { isNumeric } from '../formulas';
export const intTaxRateFormulaName = 'INTTAXRATE';

const name = intTaxRateFormulaName;

const parameters = [
  {
    name: '"jurisdiction"',
    repeatable: false,
    optional: false,
  },
  {
    name: '"period_start_date"',
    repeatable: false,
    optional: false,
  },
  {
    name: 'taxable_income',
    repeatable: false,
    optional: true,
  },
];

const description =
  'Retrieves international tax rates from the tax law database. Taxable income parameter is optional. For jurisdictions with graduated income tax, if no taxable income is entered then the tax rate for the highest income bracket is returned; if taxable income is entered, then the effective rate is computed.';

const evaluationFunction = (args, data) => {
  const [context, country, date, income] = args;
  if (country && date) {
    const { row, column } = context;
    const sheet = context.source.getSheet();
    const sheetName = sheet.name();
    const cellTag = data.getCellReferenceTag(sheet, row, column);
    const formula = `=${sheet.getFormula(row, column)}`;
    const requestParameters = {
      row,
      column,
      country: country,
      periodStartDate: date,
      taxableIncome: income,
      type: INT_TAX_RATE,
      parameters: {
        Country: country,
        PeriodStartDate: date,
        TaxableIncome: income,
      },
      value: undefined,
      sheetName,
    };
    const hasCellTag = cellTag?.references?.length && cellTag?.references?.filter(x => x.type === INT_TAX_RATE)?.length;
    const referenceExistInTargetCell = data.referenceExistInTargetCell(row, column, sheetName, INT_TAX_RATE);
    if (!hasCellTag || data.isDragFillAction.current.isActive) {
      if (referenceExistInTargetCell) {
        requestParameters['id'] = referenceExistInTargetCell.id;
        data.enqueueDataReferenceReCalc(requestParameters);
      } else {
        requestParameters['id'] = uuidv4();
        data.enqueueDataReference(requestParameters);
      }
    } else if (hasCellTag) {
      const reference = cellTag.references.find(reference => reference.type === INT_TAX_RATE);
      const referenceId = referenceExistInTargetCell ? referenceExistInTargetCell.id : reference.id;
      if (!reference) {
        requestParameters['id'] = uuidv4();
        data.enqueueDataReference(requestParameters);
      } else {
        const referenceExistInWorkSheet = data.referenceExistInWorksheet(referenceId, sheetName);
        const referenceIsEnqueuedForRecalc = data.referenceIsEnqueuedForRecalc(row, column, sheetName);
        requestParameters['id'] = referenceId;
        const formulaMatch = data.isFormulaMatch(requestParameters);
        const cellChangedData =
          data.cellChangedData?.current &&
          (data.cellChangedData?.current?.row !== row ||
            data.cellChangedData?.current.col !== column ||
            data.cellChangedData?.current?.editor?.Li !== formula);
        if (!referenceExistInWorkSheet || data.isCopyPasteAction.current.isActive) {
          if (
            referenceExistInTargetCell &&
            data.isCopyPasteAction.current.isActive &&
            data.isCopyPasteAction.current.sameSheet &&
            data.isCopyPasteAction.current.entireSheet
          ) {
            return formatFormulaReturnValue(sheet.getValue(row, column), sheet, row, column, data);
          }

          requestParameters['id'] = uuidv4();
          data.enqueueDataReference(requestParameters);
        } else if ((!formulaMatch && referenceExistInTargetCell) || cellChangedData || referenceIsEnqueuedForRecalc) {
          data.enqueueDataReferenceReCalc(requestParameters);
          data.cellChangedData.current = null;
        }
      }
    }
    return formatFormulaReturnValue(
      data.renderCustomFormulaValues(cellTag, row, column, INT_TAX_RATE, sheetName),
      sheet,
      row,
      column,
      data
    );
  }
  return GC.Spread.CalcEngine.Errors.NotAvailable;
};

function formatFormulaReturnValue(value, sheet, row, column, data) {
  if (value === '') {
    return GC.Spread.CalcEngine.Errors.NotAvailable;
  } else if (isNumeric(value)) {
    const currentFormat = sheet.getFormatter(row, column);
    const format = Number.isInteger(Number(value) * 100) ? '0%' : '0.00%';
    if (!currentFormat) {
      sheet.setFormatter(row, column, format);
    } else {
      sheet.setFormatter(row, column, currentFormat);
    }
  }
  return value ? value : data.defaultValue;
}

export const intTaxRatesFormula = (gcSyncFunction, data) =>
  new gcSyncFunction({
    name,
    minArgs: 2,
    maxArgs: 3,
    descriptionData: {
      name,
      description,
      parameters,
    },
    evaluationFunction,
    data,
  });
