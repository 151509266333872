import React from 'react';
import { BTButton, BTIcon } from '@btas/jasper';
import { getFormattedDate } from '../../../../../../_shared/dateFormatting';
import { getUser } from '../../../../../../_shared/auth';

export default function SignOffList({ signOffs, onAddSignOffClick, isLocked, onSignOffDeleteClick }) {
  const userInfo = getUser();

  function handleSignOffDelete(id) {
    if (!isLocked) {
      onSignOffDeleteClick(id);
    }
  }
  return (
    <div className="wkp-sign-off-list">
      {signOffs.map(({ firstName, lastName, signOffDate, userId, id }) => (
        <p key={id}>
          {firstName} {lastName} | {getFormattedDate(signOffDate)}
          {userInfo.userId === userId && (
            <span className={`wkp-sign-off-delete-icon ${isLocked ? 'wkp-sign-off-locked' : ''}`}>
              <BTIcon data-testid="icon" icon="trash" onClick={() => handleSignOffDelete(id)} />
            </span>
          )}
        </p>
      ))}
      <BTButton btStyle="link" btType="add" disabled={isLocked} onClick={onAddSignOffClick}>
        Add another sign-off
      </BTButton>
    </div>
  );
}
