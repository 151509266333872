export const Spreadsheet = 'Spreadsheet';
export const Dataflow = 'Dataflow';

export const BucketData = 'Data';
export const Integration = 'Integration';

export const IntegrationType = {
  TaxProvision: 'TaxProvision',
  FixedAssets: 'FixedAssets',
};
export const ResourceType = {
  Spreadsheet: 'Spreadsheet',
  Dataflow: 'Dataflow',
};
