import React from 'react';
import CloseButton from './shared/CloseButton';
import SignOffs from './WorkflowPanel/SignOffs';
import Status from './WorkflowPanel/Status/Status';

export default function WorkflowPanel({ workpaperId, onClose }) {
  return (
    <div className="wkp-right-pane">
      <div className="wkp-right-pane-header">
        <h2>Workflow</h2>
        <CloseButton onClose={onClose} />
      </div>

      <div className="wkp-right-pane-wrapper">
        <Status id={workpaperId} />
        <SignOffs workpaperId={workpaperId} />
      </div>
    </div>
  );
}
