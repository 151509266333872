import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BTAlert } from '@btas/jasper';
import Grid from 'react-bootstrap/lib/Grid';
import Frame from '../_shared/Frame';
import SavedWorkpapers from './HomePage/SavedWorkpapers';
import JobsOverlayWindow from './common/JobsOverlayWindow/JobsOverlayWindow';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import TaxPeriodSelect from './HomePage/TaxPeriodSelect';
import useGrid from './HomePage/useGrid';
import './HomePage/styles.scss';
import useJobsOverlay from './common/JobsOverlayWindow/useJobsOverlay';
import StatusTiles from './HomePage/StatusTiles';
import { WORKPAPER_EXPORT_JOB_TYPE, WORKPAPER_RECOVERY_JOB_TYPE } from '../_shared/jobs/jobTypes';
import { WORKPAPER_JOB_STATUS_COMPLETED } from '../_shared/jobs/jobStatus';
import { getProductCodes } from '../_shared/getProductCodes';
import GeneralErrorMessage from '../_shared/GeneralErrorMessage';
import { workpaper_status } from '../editor/EditorPage/Spreadsheet/shared/constants';
import { ConfirmModal } from '../global-components/Confirmation/ConfirmModal';
import { startJob } from '../_shared/jobs/apis';
import CustomLogger from '../_shared/Logger/CustomLogger';
import { recoverWorkpaper } from './HomePage/apis';
import { isFeatureFlagEnabled } from '../../utils/featureFlags';
import { RECOVER_WORKPAPER } from '../../constants/featureFlags';
import ErrorRetrievingRole from '../data-flows/ErrorRetrievingRole';
import UserPermissionsContext from '../_shared/UserPermissionsContext';
import { getFormattedDate } from '../_shared/dateFormatting';
import { getWorkpaperVersions } from '../editor/EditorPage/Spreadsheet/SideBar/HistoryPanel/apis';

export default function HomePage() {
  const lastModifiedDateSort = { binding: 'lastModifiedDate', direction: 'DESC' };
  const {
    minimizeOverlay,
    processes,
    activeProcesses,
    hasProcess,
    hasFailedProcess,
    hasActiveProcess,
    loadJobs,
    setMinimizeOverlay,
    setCloseOverlay,
    bindOnJobCompleted,
    bindOnJobFailed,
  } = useJobsOverlay();

  const { state: { loadWorkbookFailed, loadWorkbookNotFound, workpaperId, workbookName } = {} } = useLocation();

  const [isTaxPeriodSelected, setIsTaxPeriodSelected] = useState(false);
  const [isSortingFromGrid, setIsSortingFromGrid] = useState(false);
  const [undeletedWorkpapers, setUndeletedWorkpapers] = useState(null);
  const [hasDeleteWfError, setHasDeleteWfError] = useState(null);
  const [showProductAlert, setShowProductAlert] = useState(false);
  const [showRecoverWorkpaperModal, setShowRecoverWorkpaperModal] = useState(loadWorkbookFailed || false);
  const [isRecovering, setIsRecovering] = useState(false);
  const [recoveryTimestamp, setRecoveryTimestamp] = useState({ timestamp: null, isLoading: true });

  const [gridConfig, gridActions, statusCount, totalCount] = useGrid(isSortingFromGrid);
  const { gridFilters } = gridConfig;
  const { fetchWorkpapers, setFilter, cleanGridSelection } = gridActions;

  const { userPermissions } = useContext(UserPermissionsContext);

  const deleteWorkflowErrorData = userPermissions.getDeleteWorkflowErrorData();

  useEffect(() => {
    if (loadWorkbookFailed || loadWorkbookNotFound) {
      window.history.replaceState({}, '');
    }
  }, [loadWorkbookFailed, loadWorkbookNotFound]);
  useEffect(
    () => {
      const newItems = processes.filter(
        p => p.status === WORKPAPER_JOB_STATUS_COMPLETED && p.jobType !== WORKPAPER_EXPORT_JOB_TYPE
      );

      if (newItems.length) {
        fetchWorkpapers({
          newItems: newItems.map(({ id }) => id),
          sorting: lastModifiedDateSort,
          filters: gridFilters,
        });
      }
    },
    // eslint-disable-next-line
    [activeProcesses, hasActiveProcess]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      const productCode = getProductCodes();
      if (productCode === null) {
        setShowProductAlert(true);
      }
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (workpaperId) {
      async function fetchRecoveryTimestamp() {
        try {
          const versions = await getWorkpaperVersions(workpaperId, false);
          const timestamp = versions.length >= 2 ? getFormattedDate(versions[1].lastModifiedDateUtc) : null;
          setRecoveryTimestamp({ timestamp, isLoading: false });
        } catch (error) {
          CustomLogger.pushLog(CustomLogger.operations.RECOVER, {
            message: 'This Workpaper has less than 2 versions',
            workpaperId: workpaperId,
          });
          setRecoveryTimestamp({ timestamp: null, isLoading: false });
        }
      }
      fetchRecoveryTimestamp();
    }
  }, [workpaperId]);

  const isRecoverWorkpaperEnabled = isFeatureFlagEnabled(RECOVER_WORKPAPER);

  async function onRecoverWorkpaperConfirm() {
    setIsRecovering(true);
    const { jobId } = await startJob({
      workpaperId,
      jobType: WORKPAPER_RECOVERY_JOB_TYPE,
      payload: {
        fileName: workbookName,
      },
    });

    function hideModal() {
      setIsRecovering(false);
      setShowRecoverWorkpaperModal(false);
    }

    bindOnJobCompleted(
      jobId,
      function (startTime) {
        hideModal();
        CustomLogger.pushLog(CustomLogger.operations.RECOVER, {
          duration: (Date.now() - startTime).toString(),
          workpaperId,
        });
        window.open(`/editor/${workpaperId}`, '_blank');
      },
      Date.now()
    );

    bindOnJobFailed(jobId, hideModal);

    await recoverWorkpaper({ workpaperId, jobId });
  }

  function onDeleteWfErrorDismiss() {
    userPermissions.clearDeleteWorkflowError();
    setHasDeleteWfError(null);
  }

  const recoverWorkpaperModal = {
    show: showRecoverWorkpaperModal && !recoveryTimestamp.isLoading && recoveryTimestamp.timestamp,

    title: 'Damaged Workpaper',
    message: `There was a problem opening the workpaper. Would you like to recover it to the previous version from ${recoveryTimestamp.timestamp}?`,
    confirmLabel: 'RECOVER',
    cancelLabel: 'CANCEL',
    onConfirm: onRecoverWorkpaperConfirm,
    onCancel: () => setShowRecoverWorkpaperModal(false),
    modalStyle: 'warning',
    confirmBtnId: 'wkpRecoverBtn',
    disableConfirm: isRecovering,
    isProcessing: isRecovering,
  };

  return (
    <Frame activeItem="home">
      <ErrorRetrievingRole></ErrorRetrievingRole>
      <div className="wkp-home-container">
        {loadWorkbookFailed && <GeneralErrorMessage dismissible={true} visible={loadWorkbookFailed} />}
        {showRecoverWorkpaperModal && isRecoverWorkpaperEnabled && <ConfirmModal modalState={recoverWorkpaperModal} />}
        {loadWorkbookNotFound && (
          <BTAlert appear dismissible fixed btStyle="danger" visible={loadWorkbookNotFound}>
            You are not authorized to perform this action.
          </BTAlert>
        )}
        {undeletedWorkpapers && (
          <BTAlert appear dismissible fixed btStyle="danger" visible={undeletedWorkpapers}>
            Failed to delete {undeletedWorkpapers.length} workpaper(s) because they are currently locked for editing:
            <br />
            {undeletedWorkpapers
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(workpaper => {
                const workpaperTaxPeriod = workpaper.taxPeriod !== null ? workpaper.taxPeriod : '[ Unassigned ]';
                const item = (
                  <p key={workpaper.id}>
                    {workpaper.status !== workpaper_status.Final
                      ? `'${workpaper.name}'- ${workpaperTaxPeriod} - Locked by ${workpaper.userFullName}`
                      : `'${workpaper.name}'- ${workpaperTaxPeriod} - Locked as Final`}
                  </p>
                );
                return item;
              })}
          </BTAlert>
        )}
        {hasDeleteWfError && (
          <BTAlert
            appear
            dismissible
            fixed
            btStyle="danger"
            visible={hasDeleteWfError}
            onDismiss={onDeleteWfErrorDismiss}
          >
            Failed to delete {deleteWorkflowErrorData.length} workpaper(s) because you do not have permission to delete
            others' items:
            <ul>
              {deleteWorkflowErrorData.map(item => (
                <li>{item}</li>
              ))}
            </ul>
          </BTAlert>
        )}

        <Grid fluid>
          <Row>
            <Col md={9}>
              <StatusTiles
                gridFilters={gridFilters}
                statusCount={statusCount}
                totalCount={totalCount}
                onSetFilterChange={setFilter}
              />
            </Col>

            <Col className="tax-period-container" md={3}>
              <TaxPeriodSelect
                actions={gridActions}
                config={gridConfig}
                isSortingFromGrid={isSortingFromGrid}
                setIsSortingFromGrid={setIsSortingFromGrid}
                setIsTaxPeriodSelected={setIsTaxPeriodSelected}
              />
            </Col>
          </Row>

          <SavedWorkpapers
            actions={gridActions}
            cleanGridSelection={cleanGridSelection}
            config={gridConfig}
            isSortingFromGrid={isSortingFromGrid}
            isTaxPeriodSelected={isTaxPeriodSelected}
            loadJobs={loadJobs}
            processes={processes}
            setHasDeleteWfError={setHasDeleteWfError}
            setIsSortingFromGrid={setIsSortingFromGrid}
            setUndeletedWorkpapers={setUndeletedWorkpapers}
          />
        </Grid>
        <JobsOverlayWindow
          hasFailedJob={hasFailedProcess}
          hasJobs={hasProcess}
          hasProcessingJob={hasActiveProcess}
          jobs={processes}
          minimize={minimizeOverlay}
          processingJobs={activeProcesses}
          setMinimize={setMinimizeOverlay}
          setOnClose={setCloseOverlay}
        />
        <GeneralErrorMessage dismissible={true} visible={showProductAlert} />
      </div>
    </Frame>
  );
}
