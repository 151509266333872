import React, { useEffect, useState, useContext } from 'react';
import { BTModal, BTButton, BTForm, BTComboBox, BTAlert } from '@btas/jasper';
import EditorContext from '../EditorContext';
import { getWorkpapers, getWorkbookSheets, copySheet } from './WorkpaperCopySheetModal/apis';
import ValidationErrors from '../../../_shared/ValidationError';
import { generalErrorMessage } from '../../../_shared/messages';
import { formulaHelpers } from './_spreadsheets/spreadHelpers';
import './WorkpaperCopySheetModal/styles.scss';
import { getDataFromLocalStorage, setDataToLocalStorage } from '../../../_shared/storage';
import CustomLogger from '../../../_shared/Logger/CustomLogger';

export default function WorkpaperCopySheetModal({ show, sourceWorkpaperId, onClose }) {
  const [workpapers, setWorkpapers] = useState([]);
  const [taxPeriods, setTaxPeriods] = useState([]);
  const [selectedWorkpaper, setSelectedWorkpaper] = useState();
  const [selectedTaxPeriod, setSelectedTaxPeriod] = useState();
  const [selectedWorkpaperId, setSelectedWorkpaperId] = useState();
  const [selectedSheets, setSelectedSheets] = useState();
  const [targetWorkpaperSheets, setTargetWorkpaperSheets] = useState([]);
  const [beforeSheet, setBeforeSheet] = useState(null);
  const [isCopying, setIsCopying] = useState(false);
  const [error, setError] = useState();

  const { spreadRef, setSuccessAlertMessage, workbookName } = useContext(EditorContext);
  const spread = spreadRef?.current;

  useEffect(() => {
    if (!show) {
      return;
    }
    getWorkpapers().then(workpapers => {
      const workpapersOptions = workpapers.map(wkp => ({ value: wkp, label: wkp.workpaperName }));
      setWorkpapers(workpapersOptions);
      if (workpapersOptions.length === 1) {
        setSelectedWorkpaper(workpapersOptions[0]);
      } else {
        const optionToSelect = workpapersOptions.find(option =>
          option.value.taxPeriods?.some(taxPeriod => taxPeriod.id === sourceWorkpaperId)
        );
        setSelectedWorkpaper(optionToSelect);
      }
    });
  }, [show, sourceWorkpaperId]);

  useEffect(() => {
    setTaxPeriods([]);
    if (selectedWorkpaper) {
      const taxPeriodOptions = selectedWorkpaper.value?.taxPeriods.map(({ id, period }) => ({
        value: id,
        label: period,
      }));

      if (taxPeriodOptions.length === 1) {
        const [taxPeriodOption] = taxPeriodOptions;
        setSelectedWorkpaperId(taxPeriodOption.value);

        taxPeriodOption.label === null ? setSelectedTaxPeriod(null) : setSelectedTaxPeriod(taxPeriodOption);
      } else {
        const metadataTaxPeriod = formulaHelpers.getWorkpaperTaxPeriod(spread);
        const optionToSelect = taxPeriodOptions.find(
          ({ value, label }) => value === sourceWorkpaperId && metadataTaxPeriod === label
        );
        setSelectedWorkpaperId(sourceWorkpaperId);
        setSelectedTaxPeriod(optionToSelect);
        setTaxPeriods(taxPeriodOptions);
      }
    }
  }, [selectedWorkpaper, sourceWorkpaperId, spread]);

  useEffect(() => {
    if (!show || !selectedWorkpaperId) {
      return;
    }

    setTargetWorkpaperSheets([]);
    setError();

    getWorkbookSheets(selectedWorkpaperId)
      .then(sheets => setTargetWorkpaperSheets(sheets))
      .catch(error => {
        error.message = error.message ?? generalErrorMessage;
        setError(error);
      });
  }, [show, selectedWorkpaperId]);

  useEffect(() => {
    setSelectedSheets(spread.sheets?.filter(sheet => sheet.isSelected()).map(sheet => sheet.name()));
  }, [spread.sheets]);

  const handleWorkpaperChange = wkp => setSelectedWorkpaper(wkp);

  const handleTaxPeriodChange = taxPeriod => {
    setSelectedTaxPeriod(taxPeriod);
    setSelectedWorkpaperId(taxPeriod.value);
  };

  const handleBeforeSheetChange = ({ target }) => {
    setBeforeSheet(target.value);
  };

  /**
   * Slices a string before the first match of a regular expression.
   * @param {RegExp} regex - The regular expression to match against the string.
   * @param {string} str - The string to slice.
   * @returns {string} The portion of the string before the first match of the regular expression, or the entire string if there is no match.
   */
  const sliceBeforeRegexMatch = (regex, str) => {
    const regexMatch = str.match(regex);
    return regexMatch ? str.slice(0, regexMatch.index).trim() : str;
  };

  /**
   * Generates a new unique name for the copied sheet.
   * @param {string} sheetToCopy - The name of the sheet to be copied.
   * @param {array} existingSheets - An array of objects representing the existing sheets in the target workbook.
   * @returns {string} A new unique name for the copied sheet.
   */
  const getAvailableSheetName = (existingSheetsNames, sheetToCopy) => {
    const MAX_NAME_LENGTH = 31;
    const copyRegex = /\(\d+\)$/;
    let baseName = sliceBeforeRegexMatch(copyRegex, sheetToCopy);
    let availableName;

    for (let sequenceNumber = 1; ; sequenceNumber++) {
      let suffix = ` (${sequenceNumber})`;
      availableName = `${baseName}${suffix}`;

      if (availableName.length > MAX_NAME_LENGTH) {
        const truncatedBaseName = baseName.slice(0, MAX_NAME_LENGTH - suffix.length);
        availableName = `${truncatedBaseName}${suffix}`;
      }

      if (!existingSheetsNames.includes(availableName)) {
        break;
      }
    }

    return availableName;
  };

  const copySheetLocally = async sheetToCopy => {
    const newName = getAvailableSheetName(
      spread.sheets.map(sheet => sheet.name()),
      sheetToCopy
    );

    let targetIndex = targetWorkpaperSheets.length;

    if (beforeSheet !== -1) {
      const beforeSheetArrayPointer = targetWorkpaperSheets.find(({ worksheetName }) => worksheetName === beforeSheet);
      const beforeSheetIndex = targetWorkpaperSheets.indexOf(beforeSheetArrayPointer);

      targetIndex = beforeSheetArrayPointer ? beforeSheetIndex : targetWorkpaperSheets.length;
    }

    const commandManager = spread.commandManager();

    const command = {
      cmd: 'copySheet',
      sheetName: sheetToCopy,
      newName,
      targetIndex,
      includeBindingSource: true,
    };

    commandManager.execute(command);
  };

  const handleCopy = async () => {
    let error;
    const operationStartTime = Date.now();
    const targetWorkpaperId = selectedWorkpaperId;
    const worksheetNames = selectedSheets;
    const selectedBeforeSheet = targetWorkpaperSheets.find(({ worksheetName }) => worksheetName === beforeSheet);
    const workpaperData = JSON.parse(getDataFromLocalStorage(sourceWorkpaperId) || '{}');
    workpaperData['copyWorksheet'] = true;
    workpaperData['currentSheets'] = spread.sheets.map(x => x.name());
    setDataToLocalStorage(sourceWorkpaperId, JSON.stringify(workpaperData));
    setIsCopying(true);
    setError();

    try {
      const result = await copySheet({
        sourceWorkpaperId,
        worksheetNames,
        targetWorkpaperId,
        beforeSheet: selectedBeforeSheet?.worksheetName,
      });

      if (!result.success) {
        const { errorMessage, systemErrorMessage } = result;
        error = errorMessage ?? systemErrorMessage;
        const copySheetException = { message: errorMessage ?? generalErrorMessage };
        setError(copySheetException);
      } else if (sourceWorkpaperId !== targetWorkpaperId) {
        setSuccessAlertMessage({
          type: 'COPY_SHEET',
          workpaperName: selectedWorkpaper.label,
          workpaperId: selectedTaxPeriod?.value,
          workpaperTaxPeriod: selectedTaxPeriod?.label,
        });
        onClose();
      } else {
        worksheetNames.forEach(async sheetName => await copySheetLocally(sheetName));
        onClose();
      }
    } finally {
      setIsCopying(false);
      CustomLogger.pushLog(CustomLogger.operations.COPY.SHEET, {
        error: JSON.stringify(error),
        duration: (Date.now() - operationStartTime).toString(),
        sourceWorkpaperId,
        sourceWorkpaperName: workbookName,
        targetWorkpaperId,
        targetWorkpaperName: selectedWorkpaper.label,
        selectedSheets: JSON.stringify(selectedSheets),
      });
    }
  };

  const sheets = () => {
    let sheetsOptions = targetWorkpaperSheets.map(({ worksheetName }) => ({
      key: worksheetName,
      value: worksheetName,
    }));
    if (targetWorkpaperSheets.length !== 0) {
      sheetsOptions.push({ key: -1, value: 'move to end' });
    }
    return sheetsOptions;
  };

  return (
    <BTModal
      className="workpaper-copy-sheet-modal"
      id="workpaper-copy-sheet-modal"
      show={show}
      size="modal-lg"
      title="Copy Sheet"
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <BTForm className="wkp-source">
          <BTForm.FormGroup
            required
            data-testid="copy-worksheet-workpaper-select"
            htmlFor="workpaper"
            label="To workpaper"
          >
            <BTComboBox
              popOutMenu
              disabled={!workpapers.length}
              id="workpaper-select"
              isSearchable={true}
              maxMenuHeight={100}
              options={workpapers}
              value={selectedWorkpaper}
              onChange={handleWorkpaperChange}
            />
          </BTForm.FormGroup>

          {taxPeriods && (
            <BTForm.FormGroup
              required
              data-testid="copy-worksheet-taxPeriod-select"
              htmlFor="taxPeriod"
              label="Tax Period"
            >
              <BTComboBox
                popOutMenu
                disabled={!taxPeriods.length}
                id="taxPeriod"
                isSearchable={true}
                maxMenuHeight={100}
                options={taxPeriods}
                value={selectedTaxPeriod}
                onChange={handleTaxPeriodChange}
              />
            </BTForm.FormGroup>
          )}

          <BTForm.FormGroup htmlFor="workpaper-sheets" label="Before sheet">
            <select
              multiple
              disabled={!targetWorkpaperSheets.length}
              id="workpaper-sheets"
              onChange={handleBeforeSheetChange}
            >
              {sheets()?.map(({ key, value }) => (
                <option key={key} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </BTForm.FormGroup>
        </BTForm>

        {!!error && (
          <BTAlert btStyle="danger" visible={!!error}>
            <ValidationErrors error={error} />
          </BTAlert>
        )}
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton id="cancel" onClick={onClose}>
          Cancel
        </BTButton>
        <BTButton
          btStyle="primary"
          disabled={!beforeSheet || isCopying || error}
          hasSpinner={isCopying}
          id="copy"
          onClick={handleCopy}
        >
          Copy
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}
