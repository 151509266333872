import React, { useContext } from 'react';
import { BTSpinner, BTButton, BTIcon } from '@btas/jasper';

import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { WKP_INPUT_FILE_IMPORT } from '../../../../constants/featureFlags';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import '../InputElementInspector/styles.scss';

const FilePropertiesPanel = ({ elementId, elementData, updateData }) => {
  const { sourceFileUpload } = useContext(DataFlowEditorContext);
  const { uploadedFilesByInput } = sourceFileUpload;

  const inputFileImportFlag = isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT);
  const getFileNameCharsLimit = 25;

  const getName = uploadedFile => {
    return uploadedFile?.name ?? null;
  };

  const truncatedName = (name, length) => {
    return name?.length > length ? `${name.substr(0, length)}...` : name;
  };

  const getTruncatedName = (uploadedFile, length) => {
    const fileName = getName(uploadedFile);
    return truncatedName(fileName, length);
  };

  const getUploadedInputFile = () => {
    const uploadedFile = uploadedFilesByInput[elementId];
    return uploadedFile;
  };

  const getElementTitle = file => {
    return file?.name.length > getFileNameCharsLimit ? getName(file) : '';
  };

  return (
    <div className="wkp-input-element-inspector">
      <h5>Source Data Files</h5>
      <div className="wkp-source-file-list">
        {inputFileImportFlag && elementData?.uploadedFile && (
          <>
            {elementData?.uploadedFile?.status === 'uploading' && (
              <div className="wkp-upload-file-in-progress">
                <div className="wkp-upload-file-status">
                  <div className="wkp-upload-file-elements">
                    <div className="wkp-upload-file-spinner-wrapper">
                      <BTSpinner size="1x" />
                    </div>
                    <span style={{ fontWeight: 'bold' }} title={getElementTitle(getUploadedInputFile())}>
                      Uploading '{getTruncatedName(getUploadedInputFile(), getFileNameCharsLimit)}'
                    </span>
                  </div>

                  <button className="wkp-progress-bar-btn-close" type="button" onClick={() => {}}>
                    <BTIcon icon="close" />
                  </button>
                </div>
                <div className="wkp-upload-file-text">You can continue working while this file is being processed.</div>
              </div>
            )}
            {elementData?.uploadedFile?.status === 'completed' && (
              <>
                <div className="wkp-upload-file-status">
                  <div className="wkp-upload-file-elements">
                    <div className="wkp-upload-file-success-icon">
                      <BTIcon icon="check-circle" />
                    </div>
                    <span
                      style={{ fontWeight: 'bold' }}
                      title={getElementTitle(getUploadedInputFile() || elementData?.uploadedFile)}
                    >
                      {getTruncatedName(getUploadedInputFile() || elementData?.uploadedFile, getFileNameCharsLimit)}
                    </span>
                  </div>
                  <button className="wkp-progress-bar-btn-close" type="button" onClick={() => {}}>
                    <BTIcon icon="close" />
                  </button>
                </div>
                <div className="wkp-upload-file-text">Next, specify where your data is located within the file.</div>
                <BTButton
                  btStyle="primary"
                  className="wkp-select-data-within-file"
                  id="select-data-within-file"
                  onClick={() => {}}
                >
                  SELECT DATA WITHIN FILE
                </BTButton>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FilePropertiesPanel;
