import React, { useState, useEffect, useContext } from 'react';
import { getUserPermissions } from './UserPermissionsContext/apis';
import Spinner from './Spinner';
import './UserPermissionsContext/styles.scss';
import { UserPermissionData } from './UserPermissionsContext/UserPermissionData';

export const UserPermissionsContext = React.createContext();
const { Provider, Consumer } = UserPermissionsContext;

function UserPermissionsProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [userPermissions, setUserPermissionsState] = useState(null);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      const permissionsResult = await getUserPermissions();
      const userPermissionData = new UserPermissionData(permissionsResult);
      setUserPermissionsState(userPermissionData);
      setIsLoading(false);
    };

    fetchUserPermissions();
  }, []);

  const setUserPermissions = userPermissions => {
    setUserPermissionsState(userPermissions);
  };

  return (
    <Provider
      value={{
        userPermissions,
        setUserPermissions,
      }}
    >
      {isLoading ? (
        <div className="wkp-provider-container">
          <Spinner />
        </div>
      ) : (
        children
      )}
    </Provider>
  );
}

export default UserPermissionsContext;

export { UserPermissionsProvider, Consumer as UserPermissionsConsumer };

export const useCanEditWorkflow = () => {
  const { userPermissions } = useContext(UserPermissionsContext);
  if (!userPermissions) {
    return false;
  }
  return userPermissions.canEditWorkflow();
};

export const useCanDownloadWorkflowOutputs = () => {
  const { userPermissions } = useContext(UserPermissionsContext);
  if (!userPermissions) {
    return false;
  }
  return userPermissions.canDownloadWorkflowOutputs();
};

export const useCanCreateWorkflow = () => {
  const { userPermissions } = useContext(UserPermissionsContext);
  if (!userPermissions) {
    return false;
  }
  return userPermissions.canCreateWorkflow();
};
