import * as fetch from '../../../_shared/fetch';
import { dataFlowServiceHostName } from '../../../../configs/params';
import qs from 'qs';

const SHA256 = require('crypto-js/sha256');

export async function previewRun(dataFlowId, configuration, selectedElementId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/runs/preview`;
  const configCopy = generateConfigWithHashes(configuration);
  const res = await fetch.post(reqUri, {
    body: JSON.stringify({ elementId: selectedElementId, configuration: configCopy }),
  });
  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }
  return data;
}

export function generateConfigWithHashes(configuration) {
  // Create a deep copy of the configuration
  const configCopy = JSON.parse(JSON.stringify(configuration));

  // Build up a map of connected elements by sourceId and another one by targetId
  const linkMap = createLinkMap(configCopy);

  // Track visited elements
  const visited = new Set();

  // Queue up all input blocks (all blocks with no inbound connections)
  const elementsToVisit = Object.values(configCopy.elements).filter(e => !linkMap.byTargetId[e.id]);

  // Loop through queued blocks
  let element;
  while ((element = elementsToVisit.shift())) {
    // Mark this element as visited
    visited.add(element.id);

    // Look up all immediate inbound elements sorted by port name
    const sourcesByPort = linkMap.byTargetId[element.id] || {};
    const sources = Object.keys(sourcesByPort)
      .sort()
      .map(sourcePort => sourcesByPort[sourcePort]);

    // Get config hashes for all immediate upstream elements
    const sourceHashes = sources.map(source => {
      if (source.targetPort && source.sourcePort) {
        return `${source.element.blockConfigHash}:${source.sourcePort}:${source.targetPort}`;
      } else {
        return source.element.blockConfigHash;
      }
    });

    // Split out name and bounds from other properties of this element so that it is not included
    // in the hash calculation
    const { name: ignored, bounds: ignored2, ...propsToHash } = element;

    // Convert hashable properties of the current block to a string
    const targetBlockConfig = consistentStringify(propsToHash);

    // Combine the config of the current block with the hashes from the source blocks
    const configString = targetBlockConfig.concat(...sourceHashes);

    // Set blockConfigHash property of current element
    element['blockConfigHash'] = SHA256(JSON.stringify(configString)).toString();

    // Look up all direct downstream elements
    const targets = linkMap.bySourceId[element.id] || [];
    const targetsToVisit = targets.map(target => target.element).filter(t => !visited.has(t));

    // Queue up all immediate downstream elements that have not already been processed
    elementsToVisit.push(...targetsToVisit);
  }

  return configCopy;
}

function consistentStringify(obj) {
  if (typeof obj === 'undefined') {
    return 'undefined';
  } else if (obj === null) {
    return 'null';
  }

  const keys = Object.keys(obj).sort();
  const keyValuePairs = keys.map(key => {
    const value = obj[key];
    const valueString = typeof value === 'object' ? consistentStringify(value) : JSON.stringify(value);
    return `${key}:${valueString}`;
  });

  return `{${keyValuePairs.join(', ')}}`;
}

function createLinkMap(configuration) {
  // Create two maps
  //   -- byTargetId: Map of block id to direct upstream blocks (map keyed by input port) :
  //      {blockId: {port1: element, port2: element, ...}
  //   -- bySourceId: Map of block id to outbound connections (array of connections)
  //      {blockId: [element, element, element]}
  return configuration.connections.reduce(
    (acc, connection) => {
      const byTargetId = acc.byTargetId[connection.targetId] || {};
      const bySourceId = acc.bySourceId[connection.sourceId] || [];

      const upstream = {
        element: configuration.elements[connection.sourceId],
        sourcePort: connection.sourcePort,
        targetPort: connection.targetPort,
      };
      const downstream = {
        element: configuration.elements[connection.targetId],
        sourcePort: connection.sourcePort,
        targetPort: connection.targetPort,
      };

      byTargetId[connection.targetPort] = upstream;
      bySourceId.push(downstream);

      acc.byTargetId[connection.targetId] = byTargetId;
      acc.bySourceId[connection.sourceId] = bySourceId;
      return acc;
    },
    { byTargetId: {}, bySourceId: {} }
  );
}

export async function getPreviewRun(id, previewRetries) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/runs/preview/${id}?retryFlag=${
    previewRetries > 0
  }&retryAttempt=${previewRetries + 1}`;
  const res = await fetch.get(reqUri);

  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function getPreviewOutputRecords(
  id,
  blockId,
  outputPort,
  limit = 100,
  offset = 1,
  sortField,
  sortDirection = 'ASC',
  searchExpr
) {
  let reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/runs/${id}/data?limit=${limit}&blockId=${blockId}&offset=${offset}&outputPort=${outputPort}`;

  if (sortField) {
    reqUri += `&${qs.stringify(sortField, { skipNulls: true })}&sortDirection=${sortDirection}`;
  }

  if (searchExpr) {
    const encodedExpr = qs.stringify(searchExpr, { skipNulls: true, delimiter: ';' });
    reqUri += `&searchExpr=${encodedExpr}`;
  }

  const res = await fetch.get(reqUri);

  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function validatePreviewDataFlow(dataFlowRunId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowRunId}/preview/validate`;
  const res = await fetch.post(reqUri, { body: JSON.stringify({}) });

  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}
