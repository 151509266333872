import React, { useMemo } from 'react';
import { BTButton, BTForm } from '@btas/jasper';
import FieldToAggregateRow from './FieldToAggregate/FieldToAggregateRow';
import { NUMERIC } from '../shared/fieldTypes';
import { SUM } from '../shared/aggregateTypes';
import './FieldToAggregate/styles.scss';

const defaultFieldToAggregate = { field: '', aggregateFunction: SUM };

const FieldsToAggregate = ({ elementId, fields, fieldsToAggregate = [], groupBy, onUpdate }) => {
  const aggregateFieldsOptions = useMemo(
    () =>
      fields
        .filter(field =>
          field.type === NUMERIC && field.original_name
            ? !groupBy.includes(field.original_name)
            : !groupBy.includes(field.name)
        )
        .map(field => ({
          label: field.original_name ? field.original_name : field.name,
          value: field.name,
        })),
    [fields, groupBy]
  );

  const onRemove = fieldToAggregateIndex => () =>
    onUpdate(
      fieldsToAggregate.length > 1
        ? fieldsToAggregate.filter((_, index) => index !== fieldToAggregateIndex)
        : [defaultFieldToAggregate]
    );

  const onFieldUpdate = fieldToAggregateIndex => newFieldToAggregate =>
    onUpdate(
      fieldsToAggregate.map((oldFieldToAggregate, index) =>
        index === fieldToAggregateIndex ? newFieldToAggregate : oldFieldToAggregate
      )
    );

  return (
    <BTForm.FormGroup className="wkp-fields-to-aggregate" label="Fields to aggregate">
      {fieldsToAggregate.map((fieldToAggregate, fieldToAggregateIndex) => (
        <FieldToAggregateRow
          key={`${fieldToAggregateIndex}-${fieldToAggregate.field}-${fieldToAggregate.aggregateFunction}`}
          aggregateFieldsOptions={aggregateFieldsOptions}
          elementId={elementId}
          fieldToAggregate={fieldToAggregate}
          onRemove={onRemove(fieldToAggregateIndex)}
          onUpdate={onFieldUpdate(fieldToAggregateIndex)}
        />
      ))}
      <BTButton
        btStyle="link"
        btType="add"
        className="wkp-add-aggregate-btn"
        onClick={() => onUpdate([...fieldsToAggregate, defaultFieldToAggregate])}
      >
        Add another field
      </BTButton>
    </BTForm.FormGroup>
  );
};

export default FieldsToAggregate;
