import { useEffect, useRef } from 'react';
import { WORKPAPER_EXPORT_JOB_TYPE } from '../../_shared/jobs/jobTypes';
import { startJob, updateJob } from '../../_shared/jobs/apis';
import { generateStaticVersion, exportWorkpapers, getStaticVersionStatus } from './apis';
import { WORKPAPER_JOB_STATUS_COMPLETED, WORKPAPER_JOB_STATUS_IN_PROGRESS } from '../../_shared/jobs/jobStatus';
import useJobsOverlay from '../common/JobsOverlayWindow/useJobsOverlay';
import CustomLogger from '../../_shared/Logger/CustomLogger';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { SJS_API } from '../../../constants/featureFlags';

export default function useExportFile(loadJobs, processes, cleanGridSelection) {
  const prevProcessRef = useRef();
  const { bindOnJobCompleted } = useJobsOverlay();
  const exportFiles = async selection => {
    selection.map(async ({ id: workpaperId, name: fileName }) => {
      const { jobId } = await startJob({
        workpaperId,
        jobType: WORKPAPER_EXPORT_JOB_TYPE,
        payload: {
          fileName,
        },
      });
      bindOnJobCompleted(
        jobId,
        function (startTime) {
          CustomLogger.pushLog(CustomLogger.operations.EXPORT, {
            duration: (Date.now() - startTime).toString(),
            workpaperId,
            fileName,
          });
        },
        Date.now()
      );
      await loadJobs();
      if (isFeatureFlagEnabled(SJS_API)) {
        const staticStatus = await getStaticVersionStatus(workpaperId);
        if (!staticStatus?.isExcelGenerated) {
          await retryFetchExcelVersion(workpaperId);
        }
        await updateJob(jobId, WORKPAPER_JOB_STATUS_COMPLETED, workpaperId);
      } else {
        await generateStaticVersion(workpaperId);
      }
    });

    cleanGridSelection();
  };

  useEffect(() => {
    if (processes && processes.length > 0 && prevProcessRef.current && prevProcessRef.current.length > 0) {
      if (processes.length === prevProcessRef.current.length) {
        const jobChanged = findStatusChangedValue(
          prevProcessRef.current,
          processes,
          WORKPAPER_JOB_STATUS_IN_PROGRESS,
          WORKPAPER_JOB_STATUS_COMPLETED
        );
        if (jobChanged && jobChanged.jobType === WORKPAPER_EXPORT_JOB_TYPE)
          downloadWorkpaper(jobChanged.workpaperId, jobChanged.jobId);
      }
    }
    prevProcessRef.current = processes;
  }, [processes]);

  function findStatusChangedValue(prevProcesses, newProcesses, inProgress, completed) {
    const index = prevProcesses.findIndex(
      (obj1, i) =>
        obj1.workpaperId === newProcesses[i].workpaperId &&
        obj1['status'] === inProgress &&
        newProcesses[i]['status'] === completed
    );
    if (index !== -1) {
      return newProcesses[index];
    }

    return null;
  }

  async function retryFetchExcelVersion(id) {
    let isExcelGenerated = false;
    let retries = 600;

    while (!isExcelGenerated && --retries >= 0) {
      if (retries === 0) {
        throw new Error('Excel version generation failed. Reload the page');
      }

      await new Promise(r => setTimeout(r, 1000));
      const staticStatus = await getStaticVersionStatus(id);
      if (staticStatus) {
        ({ isExcelGenerated } = staticStatus);
      }
    }
  }

  async function downloadWorkpaper(id, jobId) {
    const presignedUrl = await exportWorkpapers({ id, jobId });
    const link = document.createElement('a');
    link.href = presignedUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return { exportFiles };
}
