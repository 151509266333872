export const initialComponentsState = {
  graph: null,
  paper: null,
  paperScroller: null,
  stencil: null,
  navigator: null,
};

export const ComponentsActions = {
  setGraph: 'set-graph',
  setPaper: 'set-paper',
  setStencil: 'set-stencil',
  setNavigator: 'set-navigator',
};

export const componentsActions = dispatch => ({
  setGraph: graph => dispatch({ type: ComponentsActions.setGraph, graph }),
  setPaper: (paper, paperScroller, clientDimensions) =>
    dispatch({
      type: ComponentsActions.setPaper,
      paper,
      paperScroller,
      clientDimensions,
    }),
  setStencil: stencil => dispatch({ type: ComponentsActions.setStencil, stencil }),
  setNavigator: navigator => dispatch({ type: ComponentsActions.setNavigator, navigator }),
});

export const componentsReducer = (state, action) => {
  switch (action.type) {
    case ComponentsActions.setGraph:
      return { ...state, graph: action.graph };

    case ComponentsActions.setPaper:
      return {
        ...state,
        paper: action.paper,
        paperScroller: action.paperScroller,
        clientDimensions: action.clientDimensions,
      };

    case ComponentsActions.setStencil:
      return { ...state, stencil: action.stencil };

    case ComponentsActions.setNavigator:
      return { ...state, navigator: action.navigator };

    default:
      return state;
  }
};
