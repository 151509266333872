import React, { useContext, useState } from 'react';
import { BTModal, BTButton } from '@btas/jasper';
import { deleteDataFlows } from './apis';
import { getWorkflowsUserCanDelete } from '../../auth/permissions/CanDeleteWorkflow';
import UserPermissionsContext from '../../_shared/UserPermissionsContext';
import CustomLogger from '../../_shared/Logger/CustomLogger';
const deleteDataFlowErrorCode = 'WKP2014';

export default function DeleteDataFlowsModal({ selectedDataFlows, show, onClose, onDeleteProcessed, onError }) {
  const [isDeleting, setIsDeleting] = useState(false);
  const ids = selectedDataFlows.map(sel => sel.id);

  const { userPermissions } = useContext(UserPermissionsContext);

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      if (userPermissions.isFeatureFlagEnabled()) {
        const dataFlowsUserCanDelete = getWorkflowsUserCanDelete(ids, selectedDataFlows, userPermissions);

        if (dataFlowsUserCanDelete.size > 0) {
          await deleteDataFlows(Array.from(dataFlowsUserCanDelete.keys()));
        }

        if (dataFlowsUserCanDelete.size !== selectedDataFlows.length) {
          const failedToDelete = selectedDataFlows.filter(dataFlow => !dataFlowsUserCanDelete.has(dataFlow.id));
          CustomLogger.pushLog(CustomLogger.operations.DELETE_WORKFLOW, {
            ids: failedToDelete.map(dataFlow => dataFlow.id),
          });
          userPermissions.setErrorData(failedToDelete.map(dataFlow => dataFlow.name));
        }
      } else {
        await deleteDataFlows(ids);
      }

      onDeleteProcessed();
    } catch (error) {
      CustomLogger.pushLog(CustomLogger.operations.DELETE_WORKFLOW, {
        message: error.message,
      });
      if (error.code === deleteDataFlowErrorCode) {
        onError({
          [error.target]: error.message,
        });
      }
    }

    setIsDeleting(false);
  };

  return (
    <BTModal btStyle="warning" show={show} title="Delete Data Flows" onCloseClick={onClose}>
      <BTModal.Body>
        <p>The selected data flows will be permanently deleted. Are you sure?</p>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton data-testid="close-button" onClick={onClose}>
          Cancel
        </BTButton>
        <BTButton btStyle="primary" disabled={isDeleting} hasSpinner={isDeleting} onClick={handleDelete}>
          Delete
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}
