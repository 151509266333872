import React from 'react';
import { BTButton } from '@btas/jasper';

export default function EmptyState({ onAddSignOffClick, isLocked }) {
  return (
    <div className="wkp-sign-off-empty-state-panel">
      <p>No sign-offs have been added to this workpaper</p>
      <BTButton btStyle="link" btType="add" disabled={isLocked} onClick={onAddSignOffClick}>
        Add sign-off
      </BTButton>
    </div>
  );
}
