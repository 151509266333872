import React, { useState } from 'react';
import { BTModal, BTButton } from '@btas/jasper';
import { format } from 'date-fns';
import './WorkpaperSendToTPModal/styles.scss';
import sendToTPScreenShot from './WorkpaperSendToTPModal/TP_Amounts_Export_SVG.svg';
import {
  copyFiles,
  createNodeObject,
  existedNode,
  exportToTPClassic,
  getPresignedUrl,
  updateNodeObject,
  uploadWorkpaper,
} from './WorkpaperSendToTPModal/apis';
import { TAX_PROVISION_URL } from './shared/constants';
import GeneralErrorMessage from '../../../_shared/GeneralErrorMessage';
import { getUser, getUserInfo } from '../../../_shared/auth';
import { dataFlowServiceHostName } from '../../../../configs/params';
import { ResourceType, IntegrationType } from './WorkpaperSendToTPModal/constants';

export default function WorkpaperSendToTPModal({
  show,
  sourceWorkpaperId,
  onClose,
  showSuccessModal,
  sourceTaxPeriod,
  sourceWorkpaperName,
}) {
  const [fetchError, setFetchError] = useState(null);
  const [fetching, setFetching] = useState(false);

  const userInfo = getUser();
  const hasProvSubscription = userInfo?.subscriptions && userInfo.subscriptions.includes('PROV');

  const formatFilename = (sourceWorkpaperId, fileName) => {
    const fileTime = format(new Date(), 'yyyyMMddHHmmssSSSS');
    const formatedFileName = `${sourceWorkpaperId}-${fileTime}.xlsx`;
    return formatedFileName;
  };

  const generateKey = fileName => {
    const userInfo = getUser();
    const { companyId = null } = userInfo != null && (typeof userInfo === 'string' ? JSON.parse(userInfo) : userInfo);

    return `TP_EXPORT/${companyId}/spreadsheet/${fileName}`;
  };

  const generateClassicKey = (sourceWorkpaperId, fileName) => {
    const userInfo = getUser();
    const { companyId = null } = userInfo != null && (typeof userInfo === 'string' ? JSON.parse(userInfo) : userInfo);

    return `${companyId}/wkp-output-files/spreadsheet/${sourceWorkpaperId}/${fileName}`;
  };

  const workbookExport = async (excelIO, json) => {
    return new Promise((resolve, reject) => {
      excelIO.save(
        json,
        blob => {
          resolve(blob);
        },
        error => {
          reject(error);
        }
      );
    });
  };

  const openTaxProvision = () => {
    window.open(TAX_PROVISION_URL, '_blank');
  };

  const sendToTaxProvisionV2 = async (sourceWorkpaperId, sourceWorkpaperName, sourceTaxPeriod, fileName, location) => {
    const downloadPresignedResult = await getPresignedUrl(fileName, location);
    const downloadPresignedUrl = downloadPresignedResult.presignedUrls.find(output => output[fileName])?.[fileName];

    const fileData = {
      name: fileName,
      location: location,
      presignedUrl: downloadPresignedUrl,
      destination: ['tax-provision.bna.com'],
      fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    const objectPayload = {
      resourceId: sourceWorkpaperId,
      name: sourceWorkpaperName,
      resourceUrl: `${dataFlowServiceHostName}/editor/${sourceWorkpaperId}`,
      resourceTaxPeriod: sourceTaxPeriod,
      files: [fileData],
      resourceType: ResourceType.Spreadsheet,
      canSendEvent: hasProvSubscription,
      integrationType: IntegrationType.TaxProvision,
    };

    const nodeExisted = await existedNode(sourceWorkpaperId);

    if (nodeExisted?.existed) {
      await updateNodeObject(objectPayload);
    } else {
      await createNodeObject(objectPayload);
    }
  };

  const sendToTaxProvisionClassic = async (sourceWorkpaperId, location, fileName) => {
    const userInfo = await getUserInfo();
    const email = userInfo?.designatedUser?.email || userInfo?.sharedUser?.email;

    // generate url for TP 1.0
    const tpClassicLocation = generateClassicKey(sourceWorkpaperId, fileName);
    const copyPayload = {
      sourceBucketType: 'Integration',
      sourcePath: location,
      destinationBucketType: 'Data',
      destinationPath: tpClassicLocation,
    };

    // copy file between buckets
    await copyFiles(copyPayload);

    // call lambda to tax provision
    const sendToTPClassicPayload = {
      email: email,
      filePath: tpClassicLocation,
    };

    await exportToTPClassic(sendToTPClassicPayload);
  };

  const proceedData = async () => {
    setFetchError(null);
    setFetching(true);
    const { GC } = window;
    const spread = GC.Spread.Sheets.findControl(document.querySelector('[gcuielement="gcSpread"]'));
    const sheet = spread.getActiveSheet();
    const excelIO = new GC.Spread.Excel.IO();

    const json = sheet.toJSON();
    const tempElement = document.createElement('DIV');
    const tempSpread = new GC.Spread.Sheets.Workbook(tempElement);
    tempSpread.shetCount = 1;
    tempSpread.getActiveSheet().fromJSON(json);
    const excelToSave = tempSpread.toJSON();

    try {
      const blob = await workbookExport(excelIO, excelToSave);
      const fileName = formatFilename(sourceWorkpaperId, sheet);

      const location = generateKey(fileName);
      const uploadPresignedResult = await getPresignedUrl(fileName, location, true);
      const uploadPresignedUrl = uploadPresignedResult.presignedUrls.find(output => output[fileName])?.[fileName];
      await uploadWorkpaper(blob, uploadPresignedUrl);

      if (hasProvSubscription) {
        await sendToTaxProvisionV2(sourceWorkpaperId, sourceWorkpaperName, sourceTaxPeriod, fileName, location);
      }

      await sendToTaxProvisionClassic(sourceWorkpaperId, location, fileName);

      setFetching(false);
      onClose();
      showSuccessModal();
    } catch (err) {
      setFetching(false);
      setFetchError(err);
    } finally {
      tempElement.remove();
    }
  };

  return (
    <BTModal
      className="workpaper-send-to-tp-modal"
      id="workpaper-send-to-tax-provision-modal"
      show={show}
      size="modal-lg"
      title="Before You Send to Bloomberg Tax Provision"
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <p>
          Before you send, ensure this sheet is formatted for an <b>Amounts Upload</b>. If your data is not formatted,
          you can create a template by performing an <b>Export</b> from the Tax Provision application and selecting{' '}
          <b>Amounts</b> as the data to export.
        </p>
        <div>
          <img alt="send to tax provision screenshot" className="tpscreenshot" src={sendToTPScreenShot} />
        </div>
        {fetchError && <GeneralErrorMessage dismissible={false} />}
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton id="cancel" onClick={onClose}>
          Cancel
        </BTButton>
        <BTButton id="open-tax-provision" onClick={openTaxProvision}>
          OPEN TAX PROVISION
        </BTButton>
        <BTButton btStyle="primary" disabled={fetching} hasSpinner={fetching} id="sendTp" onClick={proceedData}>
          PROCEED WITH SEND
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}
