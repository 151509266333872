import * as wkpFetch from '../../../_shared/fetch';
import { spreadsheetServiceHostName } from '../../../../configs/params';
export async function getWorkpaperDataReferences(workpaperId, sheetName = '') {
  const response = await wkpFetch.get(
    `${spreadsheetServiceHostName}/spreadsheet/datareferences/${workpaperId}${sheetName && '/' + sheetName}`
  );
  if (!response.ok) {
    return null;
  }
  return await response.json();
}

export async function createWorkpaperDataReferences(workpaperId, metadata) {
  const response = await wkpFetch.post(
    `${spreadsheetServiceHostName}/spreadsheet/datareferences/create/${workpaperId}`,
    {
      body: JSON.stringify({ details: metadata }),
    }
  );
  if (!response || !response.ok) {
    return null;
  }
  try {
    return await response.json();
  } catch {
    return null;
  }
}

export async function resolveFormulaValues(workpaperId, outputRequest) {
  const requestBody = JSON.stringify(outputRequest);
  const response = await wkpFetch.post(
    `${spreadsheetServiceHostName}/spreadsheet/datareferences/resolve/${workpaperId}`,
    {
      body: requestBody,
    }
  );

  window.dataReferenceResolve && window.dataReferenceResolve.resolve();

  return response;
}

export async function updateReferences(workpaperId, references) {
  const body = JSON.stringify({
    workpaperId,
    details: references.map(({ row, column, id: referenceId, parameters, value, sheetName }) => ({
      row,
      column,
      referenceId,
      newValue: value,
      sheetName,
      parameters: typeof parameters === 'string' ? parameters : JSON.stringify(parameters),
    })),
  });
  return await wkpFetch.put(`${spreadsheetServiceHostName}/spreadsheet/datareferences`, {
    body,
  });
}

export async function copyReferences(workpaperId, references) {
  const body = JSON.stringify({
    workpaperId,
    details: references,
  });
  const response = await wkpFetch.post(`${spreadsheetServiceHostName}/spreadsheet/datareferences/copy`, {
    body,
  });
  if (!response.ok && !response.status !== 504) {
    throw await response.json();
  }
  return response;
}

export async function deleteReferences(workpaperId, referenceIds) {
  const body = JSON.stringify({
    workpaperId: workpaperId,
    referenceIds,
  });
  const response = await wkpFetch.remove(`${spreadsheetServiceHostName}/spreadsheet/datareferences`, {
    body,
  });
  if (!response.ok) {
    throw await response.json();
  }
  return response;
}

export async function getAssociatedDataTransformations(workpaperId, taxPeriod, currTaxPeriod) {
  const response = await wkpFetch.get(
    `${spreadsheetServiceHostName}/spreadsheet/datareferences/${workpaperId}/tax-period/${taxPeriod}/current-tax-period/${currTaxPeriod}`
  );
  if (response.status !== 200) {
    return null;
  }
  const data = await response.json();
  return data;
}
