import React, { useContext } from 'react';
import { BTForm, BTInput, BTButton, BTComboBox, BTCheckbox, BTTextarea } from '@btas/jasper';
import EditorContext from '../../../EditorContext';
import { isGlobalTemplateAccount } from '../../../../../_shared/auth';
import { MAX_ALLOWED_CHARACTERS } from '../../shared/constants';

export default function Form({
  state,
  didCheckboxChange,
  comboboxOptions,
  setShowConfirmationModal,
  onChange,
  onCheckboxChange,
  onComboboxChange,
  onSaveClick,
  onCancelClick,
  onDescriptionChange,
}) {
  const { isLocked, isGlobalTemplate: isReadOnly } = useContext(EditorContext);
  const {
    name,
    entity,
    taxPeriod,
    workpaperType,
    isGlobalTemplate,
    jurisdiction,
    selectedTags,
    fieldErrors,
    errors,
    isSaving,
    templateDescription,
  } = state;

  return (
    <div className="wkp-meta-form">
      <BTForm className="wkp-meta-form-fields">
        <BTForm.FormGroup
          required
          data-testid="settings-name-field"
          disabled={isLocked || isReadOnly}
          errorText={fieldErrors['Name']}
          hasError={fieldErrors['Name'] !== undefined}
          htmlFor="name"
          label="Name"
        >
          <BTInput id="name" value={name} onChange={onChange('name')} />
        </BTForm.FormGroup>
        <BTForm.FormGroup
          data-testid="settings-entity-field"
          disabled={isLocked || isReadOnly}
          errorText={fieldErrors['Entity']}
          hasError={fieldErrors['Entity'] !== undefined}
          htmlFor="entity"
          label="Entity"
        >
          <BTComboBox
            canCreateNew
            isClearable
            createText="Add entity"
            id="entity"
            isSearchable={true}
            maxMenuHeight={100}
            noResultsMessage="No entities created. Start typing to add an entity."
            options={comboboxOptions.entities}
            placeholder="Select an entity, or start typing to add new"
            value={entity}
            onChange={onComboboxChange('entity')}
          />
        </BTForm.FormGroup>
        <BTForm.FormGroup
          data-testid="settings-tax-period-field"
          disabled={isLocked || isReadOnly}
          errorText={fieldErrors['TaxPeriod']}
          hasError={fieldErrors['TaxPeriod'] !== undefined}
          htmlFor="taxPeriod"
          label="Tax period"
        >
          <BTComboBox
            canCreateNew
            isClearable
            createText="Add tax period"
            id="taxPeriod"
            isSearchable={true}
            maxMenuHeight={100}
            noResultsMessage="No tax periods created. Start typing to add a tax period."
            options={comboboxOptions.taxPeriods}
            placeholder="Select a tax period, or start typing to add new"
            value={taxPeriod}
            onChange={onComboboxChange('taxPeriod')}
          />
        </BTForm.FormGroup>

        <BTForm.FormGroup
          data-testid="settings-jurisdiction-field"
          disabled={isLocked || isReadOnly}
          errorText={fieldErrors['Jurisdiction']}
          hasError={fieldErrors['Jurisdiction'] !== undefined}
          htmlFor="jurisdiction"
          label="Jurisdiction"
        >
          <BTComboBox
            canCreateNew
            isClearable
            createText="Add jurisdiction"
            id="jurisdiction"
            isSearchable={true}
            maxMenuHeight={100}
            noResultsMessage="No jurisdictions created. Start typing to add a jurisdiction."
            options={comboboxOptions.jurisdictions}
            placeholder="Select a jurisdiction, or start typing to add new"
            value={jurisdiction}
            onChange={onComboboxChange('jurisdiction')}
          />
        </BTForm.FormGroup>
        <BTForm.FormGroup
          data-testid="settings-workpaper-type-field"
          disabled={isLocked || isReadOnly}
          errorText={fieldErrors['workpaperType']}
          hasError={fieldErrors['workpaperType'] !== undefined}
          htmlFor="workpaperType"
          label="Workpaper type"
        >
          <BTComboBox
            canCreateNew
            isClearable
            createText="Add workpaper type"
            id="workpaperType"
            isSearchable={true}
            maxMenuHeight={100}
            noResultsMessage="No workpaper types created. Start typing to add a workpaper type."
            options={comboboxOptions.workpaperTypes}
            placeholder="Select a workpaper type, or start typing to add new"
            value={workpaperType}
            onChange={onComboboxChange('workpaperType')}
          />
        </BTForm.FormGroup>
        {isGlobalTemplateAccount() && (
          <BTForm.FormGroup data-testid="settings-workpaper-type-checkbox">
            <BTCheckbox
              checked={isGlobalTemplate}
              disabled={isLocked}
              id="is-global-template"
              label="Mark as global template"
              onChange={onCheckboxChange}
            />
          </BTForm.FormGroup>
        )}
        <BTForm.FormGroup
          data-testid="settings-template-desciption-field"
          disabled={isLocked || isReadOnly}
          errorText={fieldErrors['templateDescription']}
          hasError={fieldErrors['templateDescription'] !== undefined}
          htmlFor="templateDescription"
          label="Template description"
        >
          <BTTextarea
            hasError={fieldErrors?.TemplateDescription}
            maxLength={MAX_ALLOWED_CHARACTERS}
            value={templateDescription}
            onChange={onDescriptionChange}
          />
        </BTForm.FormGroup>
        {fieldErrors?.TemplateDescription && (
          <div style={{ color: 'red', marginTop: '5px' }}>{fieldErrors.TemplateDescription}</div>
        )}
        {errors &&
          errors.map(e => (
            <span key={e.message} className="help-text text-danger">
              {e.message}
            </span>
          ))}

        <BTForm.FormGroup
          data-testid="settings-tags-field"
          disabled={isLocked || isReadOnly}
          errorText={fieldErrors['Tag']}
          hasError={fieldErrors['Tag'] !== undefined}
          htmlFor="Tags"
          label="Tags"
        >
          <BTComboBox
            canCreateNew
            isClearable
            createText="Add new tag"
            id="global-tags"
            isMulti={true}
            isSearchable={true}
            maxMenuHeight={100}
            noResultsMessage="No tags created. Start typing to add a tag."
            options={comboboxOptions.tags}
            placeholder="Select a tag, or start typing to add new"
            value={selectedTags}
            onChange={onComboboxChange('selectedTags')}
          />
        </BTForm.FormGroup>
      </BTForm>
      <div className="row">
        <div className="col-xs-6">
          <BTButton disabled={isSaving} onClick={onCancelClick}>
            Cancel
          </BTButton>
        </div>
        <div className="col-xs-6">
          <BTButton
            btStyle="primary"
            disabled={isSaving || isLocked}
            hasSpinner={isSaving}
            onClick={() => (didCheckboxChange() ? setShowConfirmationModal(true) : onSaveClick())}
          >
            Save
          </BTButton>
        </div>
      </div>
    </div>
  );
}
