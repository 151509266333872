import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { BTButton } from '@btas/jasper';
import { faForward, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { startJob } from '../../_shared/jobs/apis';
import { copyWorkpapers } from './apis';
import { WORKPAPER_COPY_JOB_TYPE } from '../../_shared/jobs/jobTypes';
import DeleteModal from './SavedWorkpapers/DeleteModal';
import CreateWorkpapersModal from './CreateWorkpapersModal';
import useExportFile from './useExportFile';
import useJobsOverlay from '../common/JobsOverlayWindow/useJobsOverlay';
import { getDataFromLocalStorage } from '../../_shared/storage';
import RollforwardModal from './RollforwardModal';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import CustomLogger from '../../_shared/Logger/CustomLogger';
import { RECYCLE_BIN_ENABLED } from '../../../constants/featureFlags';
import recycleBinIcon from './WorkpaperActionButtons/wkp_recycle_bin_icon.svg';
import './WorkpaperActionButtons/styles.scss';
import CanCreateWorkflow from '../../auth/permissions/CanCreateWorkflow';
import { CanDeleteWorkflow } from '../../auth/permissions/CanDeleteWorkflow';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

export default function WorkpapersActionButtons({
  actions,
  cleanGridSelection,
  setHasDeleteWfError,
  setUndeletedWorkpapers,
  loadJobs,
  processes,
  workpapersDropzone,
  gridSorting,
  gridFilters,
  selection,
}) {
  const { fetchWorkpapers } = actions;
  const [showNewWorkpaperModal, setShowNewWorkpaperModal] = useState(false);
  const [showRollForwardModal, setShowRollForwardModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const queryClient = new QueryClient();
  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleDeleteProcessed = () => {
    setShowDeleteModal(false);
    fetchWorkpapers({ sorting: gridSorting, filters: gridFilters });
  };
  const { exportFiles } = useExportFile(loadJobs, processes, cleanGridSelection);
  const { bindOnJobCompleted } = useJobsOverlay();

  const onCreateWorkpapersModalClose = () => {
    setShowNewWorkpaperModal(false);
  };

  const onDeleteClick = () => setShowDeleteModal(true);

  const onClickImport = () => {
    workpapersDropzone.open();
  };

  const onClickCopy = async selection => {
    selection.forEach(async ({ id: workpaperId, name: fileName }) => {
      const { jobId } = await startJob({
        workpaperId,
        jobType: WORKPAPER_COPY_JOB_TYPE,
        payload: {
          fileName,
        },
      });
      bindOnJobCompleted(
        jobId,
        function (startTime) {
          CustomLogger.pushLog(CustomLogger.operations.COPY.WORKPAPER, {
            duration: (Date.now() - startTime).toString(),
            workpaperId,
            fileName,
          });
        },
        Date.now()
      );
      await loadJobs();
      await copyWorkpapers(workpaperId, jobId);
      await loadJobs();
    });
    cleanGridSelection();
  };

  const onNewClick = () => {
    setShowNewWorkpaperModal(true);
  };

  const closeModal = useCallback(() => {
    setShowNewWorkpaperModal(false);
  }, []);

  const onOpenRollForward = () => {
    setShowRollForwardModal(true);
  };

  const onCloseRollForward = () => {
    setShowRollForwardModal(false);
  };

  const handleCreateWorkpaper = newWorkpaperId => {
    closeModal();
    const interval = setInterval(() => {
      if (!getDataFromLocalStorage(newWorkpaperId)) {
        fetchWorkpapers({ sorting: gridSorting, filters: gridFilters });
        clearInterval(interval);
      }
    }, 500);
  };

  const noItemsSelected = selection.length === 0;

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <div className="wkp-grid-actions">
          <CanCreateWorkflow
            onYes={() => (
              <BTButton btStyle="link" btType="icon" icon={faPlus} onClick={onNewClick}>
                New workpaper
              </BTButton>
            )}
          />
          <CanCreateWorkflow
            onYes={() => (
              <BTButton
                btStyle="link"
                btType="icon"
                disabled={false}
                icon={faUpload}
                id="import-workpapers"
                onClick={onClickImport}
              >
                Import workpapers
              </BTButton>
            )}
          />
          <CanCreateWorkflow
            onYes={() => (
              <BTButton
                btStyle="link"
                btType="copy"
                disabled={noItemsSelected}
                id="copy-workpapers"
                onClick={() => onClickCopy(selection)}
              >
                Copy
              </BTButton>
            )}
          />
          <BTButton
            btStyle="link"
            btType="icon"
            disabled={selection.length !== 1}
            icon={faForward}
            id="wkp-open-rollforward"
            title={
              selection.length !== 1
                ? 'The rollforward feature is only available when a single workpaper is selected'
                : ''
            }
            onClick={onOpenRollForward}
          >
            Rollforward
          </BTButton>
          <BTButton
            btStyle="link"
            btType="download"
            disabled={noItemsSelected}
            id="wkp-export-to-excel"
            onClick={() => exportFiles(selection)}
          >
            Export to Excel
          </BTButton>
          <CanDeleteWorkflow
            onYes={() => (
              <BTButton btStyle="link" btType="delete" disabled={noItemsSelected} onClick={onDeleteClick}>
                Delete
              </BTButton>
            )}
          />

          {isFeatureFlagEnabled(RECYCLE_BIN_ENABLED) && (
            <Link
              aria-label="Recycle bin"
              className="wkp-recycle-bin-button"
              disabled={!isFeatureFlagEnabled(RECYCLE_BIN_ENABLED)}
              id="wkp-recycle-bin-btn"
              title="Recycle bin"
              to="/workpapers/recycle-bin"
            >
              <img alt="recycle Bin" src={recycleBinIcon} />
              Recycle bin
            </Link>
          )}
        </div>
        <DeleteModal
          selection={selection}
          setHasDeleteWfError={setHasDeleteWfError}
          setUndeletedWorkpapers={setUndeletedWorkpapers}
          show={showDeleteModal}
          onClose={handleDeleteModalClose}
          onDeleteProcessed={handleDeleteProcessed}
        />
        {showNewWorkpaperModal && (
          <CreateWorkpapersModal
            loadJobs={loadJobs}
            onClose={onCreateWorkpapersModalClose}
            onCreateDone={handleCreateWorkpaper}
            onOuterClick={closeModal}
          />
        )}
        {showRollForwardModal && selection[0] && (
          <RollforwardModal
            cleanGridSelection={cleanGridSelection}
            loadJobs={loadJobs}
            workpaperId={selection[0].id}
            workpaperName={selection[0].name}
            onClose={onCloseRollForward}
          />
        )}
      </QueryClientProvider>
    </>
  );
}
