/**
 * This file overrides the paint method, keeping the older paint methods hierarchy untouched.
 *
 * The changes here are only related to Link Workpaper and Source Data Formula.
 */

import GC from '../../../../../SpreadSheets';
import { isSourceDataFormulaMatch } from './formulas';
import { getFormulaArgumentValues, getFiltersFromDynamicArguments } from './sourceDataFormulaHelper';
import { getDataFlowOutputs } from './cellOverrides/apis';
import { exportIcons } from './commands/cellReview/exportIcons';
import { registerTooltip, unregisterTooltip } from './tooltipHelper';
import { isReviewedCell, isCellOnScreenValidator } from './commands/cellReview/utils';
import { adjustCells, REVIEWED } from './commands/_shared/adjustCells';
import { getDataFlowByOutputId } from '../SideBar/ConnectionPanel/apis';
import { CELL_REVIEW } from '../../../../_shared/DataReference/ReferenceType';
import { isFeatureFlagEnabled } from '../../../../../utils/featureFlags';
import { CELL_REVIEW_ENABLED } from '../../../../../constants/featureFlags';

// out of the formula, first 2 arguments are known (no key needs to be present on the arguments list)
// the rest belong to the dynamic formula (are key-value pairs)
const FIXED_ARGUMENTS_COUNT = 2;

const { checkExclamationIcon, incomingValueIndicatorMark } = exportIcons();
function delayTooltip(ms) {
  const start = Date.now();
  while (Date.now() - start < ms) {}
}

function isReviewedCellDataReference(cellTag) {
  if (cellTag && cellTag.references) {
    return cellTag.references.some(reference => reference.type === CELL_REVIEW);
  }
  return false;
}

export function workpaperSourceDataDefinition(tooltipManager) {
  const oldPaint = GC.Spread.Sheets.CellTypes.Base.prototype.paint;

  GC.Spread.Sheets.CellTypes.Base.prototype.paint = function (ctx, _, x, y, w, h, __, context) {
    const modifiedArguments = [...arguments];
    oldPaint.apply(this, modifiedArguments);
    const { sheet, row, col } = context;
    const formula = sheet.getFormula(row, col);
    const sourceDataFormulaMatch = isSourceDataFormulaMatch(formula);
    const tooltipId = `wkp_data_source_row_${row}_col_${col}`;
    const unregister = () => unregisterTooltip(tooltipManager, tooltipId);
    if (sourceDataFormulaMatch) {
      unregister();
      const cell = sheet.getCell(row, col);
      let reviewedCell = false;
      if (isFeatureFlagEnabled(CELL_REVIEW_ENABLED)) {
        reviewedCell = isReviewedCellDataReference(cell.tag());
      } else {
        reviewedCell = isReviewedCell(row, col, sheet.tag());
      }
      const value = cell.value();
      ctx.save();
      ctx.rect(x, y, w, h);
      if (value === '') {
        ctx.drawImage(checkExclamationIcon.image, x + 2, y + 2, 16, 16);
      } else {
        ctx.drawImage(incomingValueIndicatorMark.image, x + 1, y + 1);
      }

      ctx.restore();
      // we want the blue triangle (from "createSourceDataLinkIndicator") to not shift its position
      const { newX, newW } = adjustCells(x, w, reviewedCell ? REVIEWED : false);
      modifiedArguments[2] = newX;
      modifiedArguments[4] = newW;
    } else {
      unregister();
      oldPaint.apply(this, modifiedArguments);
    }
  };

  GC.Spread.Sheets.CellTypes.Base.prototype.getHitInfo = function (x, y, cellStyle, cellRect, context) {
    return {
      x,
      y,
      row: context.row,
      col: context.col,
      cellStyle,
      cellRect: cellRect,
      sheetArea: context.sheetArea,
      context,
    };
  };
  GC.Spread.Sheets.CellTypes.Base.prototype.processMouseEnter = function (hitInfo) {
    const { row, col, context, cellRect } = hitInfo;
    const { x, y, height, width } = cellRect;
    const sheet = hitInfo.sheet;
    const formula = sheet.getFormula(row, col);
    const sourceDataFormulaMatch = isSourceDataFormulaMatch(formula);
    const tooltipId = `wkp_data_source_row_${row}_col_${col}`;
    const unregister = () => unregisterTooltip(tooltipManager, tooltipId);
    unregister();
    if (sourceDataFormulaMatch) {
      delayTooltip(500);
      if (hitInfo) {
        registerTooltip(
          tooltipManager,
          x,
          y,
          { height, width },
          { x: width / 2 + 1, y: 5 },
          tooltipId,
          createSourceDataLinkIndicator(sheet, sourceDataFormulaMatch, context, formula),
          false,
          isCellOnScreenValidator({ sheet, row, col, onNotActive: unregister })
        );
        return true;
      }
    }
    unregister();
    return false;
  };

  GC.Spread.Sheets.CellTypes.Base.prototype.processMouseLeave = function (hitInfo) {
    const { row, col } = hitInfo;
    const tooltipId = `wkp_data_source_row_${row}_col_${col}`;
    unregisterTooltip(tooltipManager, tooltipId);
    return true;
  };
}

const buildTooltipMessage = (outputs, outputId, outputField, name, taxPeriod, parsedFilters) => {
  let msg = `Source: data transformation workflow '${name}'`;

  if (outputs.length > 1) {
    const outputName = outputs.find(x => x.id === outputId)?.name;
    msg += `, output '${outputName}'`;
  }

  msg += taxPeriod ? `,\nfor tax period '${taxPeriod}'` : '';

  msg += outputField ? `,\noutput field '${outputField}'` : '';

  parsedFilters.forEach(({ field, value }) => (msg += `\nand ${field} = '${value}'`));

  return msg;
};

function createSourceDataLinkIndicator(sheet, sdcFormulaMatch, context, formula) {
  let content;

  const { row, col } = context;
  const value = sheet.getCell(row, col).value();

  if (value === '') {
    content = () =>
      'This source data connection returned no matching data. Check that your SOURCE_DATA parameters are correct and that you have imported source files into your data transformation.';
  } else {
    const { outputId } = sdcFormulaMatch.groups;
    content = async () => {
      const dataFlowInfo = await getDataFlowByOutputId(outputId);

      if (dataFlowInfo?.connectionError) {
        if (dataFlowInfo?.nullOutputId) {
          return 'No data transformation workflow output block matches the ID provided.';
        } else if (!dataFlowInfo?.published) {
          return `Publish the data transformation workflow '${dataFlowInfo.dataFlowName}' for tax period '${dataFlowInfo.taxPeriod}' to see the data connection properties.`;
        }
      }

      const {
        deleted,
        dataFlow: { name, outputs },
      } = await getDataFlowOutputs({ dataFlowId: dataFlowInfo.dataFlowId });

      if (deleted || !name || !outputs) {
        return 'The data transformation for the source data connection is no longer available.';
      }

      const argumentValues = getFormulaArgumentValues(sheet, formula);
      const [, outputField] = argumentValues;

      const dynamicArguments = argumentValues.slice(FIXED_ARGUMENTS_COUNT, argumentValues.length);
      // maps key-value pairs into an object
      const parsedFilters = getFiltersFromDynamicArguments(dynamicArguments);

      return buildTooltipMessage(outputs, outputId, outputField, name, dataFlowInfo?.taxPeriod, parsedFilters);
    };
  }

  return content;
}
