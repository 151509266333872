import { onTabularDataConnectionPresent } from './onTabularDataConnectionPresent';
/**
 * Creates a new function to override default onOpen method for the context menu
 *
 * The override executes the original onOpen function too, so we only extend it with the desired behavior
 */
export function contextualMenuOnOpenOverride(spread) {
  const oldOnOpen = spread.contextMenu.onOpenMenu;
  spread.contextMenu.onOpenMenu = function (menuData, itemsDataForShown, hitInfo, spread) {
    oldOnOpen.apply(this, arguments);

    const sheet = spread.getActiveSheet();

    onTabularDataConnectionPresent(sheet, itemsDataForShown);
  };
}
