export function onTabularDataConnectionPresent(sheet, menuItems) {
  const tabularDataTableName = sheet.tag()?.tabularDataTableName;
  const setDisable = disable => {
    const affectedItems = [
      'gc.spread.contextMenu.insertColumns',
      'gc.spread.contextMenu.insertRows',
      'gc.spread.contextMenu.deleteColumns',
      'gc.spread.contextMenu.deleteRows',
    ];
    menuItems
      .filter(menuItem => affectedItems.includes(menuItem.command))
      .forEach(menuItem => (menuItem.disable = disable));
  };
  if (tabularDataTableName) {
    const table = sheet.tables.findByName(tabularDataTableName);
    let { col, row, rowCount, colCount } = table.range();
    const selections = sheet.getSelections();
    for (let selectionIndex = 0; selectionIndex < selections.length; selectionIndex++) {
      for (let rowX = 0; rowX < selections[selectionIndex].rowCount; rowX++) {
        for (let colY = 0; colY < selections[selectionIndex].colCount; colY++) {
          const colIdx = selections[selectionIndex].col + colY;
          const rowIdx = selections[selectionIndex].row + rowX;
          // if the click is outside of the sheet data (header), then selections[selectionIndex].row will be -1, hence the condition
          const rowMatch = selections[selectionIndex].row === -1 || (rowIdx >= row && rowIdx < row + rowCount);
          // if the click is outside of the sheet data (row number), then selections[selectionIndex].col will be -1, hence the condition
          const colMatch = selections[selectionIndex].col === -1 || (colIdx >= col && colIdx < col + colCount);
          if (rowMatch && colMatch) {
            setDisable(true);
            return;
          }
        }
      }
    }
  }
  setDisable(false);
}
