import React, { useCallback, useMemo } from 'react';
import ElementInspector from './shared/ElementInspector';
import { BTComboBox, BTForm } from '@btas/jasper';
import './shared/LeftRightFieldsSelector/styles.scss';

const SortElementInspector = ({ elementData, updateData, ...props }) => {
  const fieldTypes = useMemo(
    () =>
      elementData.fields.reduce(
        (acum, value) => ({
          ...acum,
          [value.name]: value.type,
        }),
        {}
      ),
    [elementData.fields]
  );

  const handleChangeField = useCallback(
    (selected, i) => {
      updateData({
        sortedFields: elementData.sortedFields.map((sorted, j) => {
          if (i === j) {
            return {
              name: selected.value,
              order: sorted.order ? sorted.order : 'ASC',
              type: fieldTypes[selected.value],
              original_name: elementData.fields.find(element => element.name === selected.value)?.original_name,
            };
          }

          return sorted;
        }),
      });
    },
    [elementData.sortedFields, fieldTypes, updateData, elementData.fields]
  );

  const handleChangeDirection = useCallback(
    (selected, i) => {
      updateData({
        sortedFields: elementData.sortedFields.map((sorted, j) => {
          if (i === j) {
            return {
              ...sorted,
              order: selected.value,
            };
          }

          return sorted;
        }),
      });
    },
    [elementData.sortedFields, updateData]
  );

  const fields = useMemo(
    () =>
      elementData.fields
        .filter(field => !elementData.sortedFields.some(sorted => sorted.name === field.name))
        .map(field => ({
          label: field.original_name || field.name,
          value: field.name,
        })),
    [elementData.fields, elementData.sortedFields]
  );

  const sorts = useMemo(
    () =>
      elementData.sortedFields.map((field, i) => {
        let dir = null;
        const fieldName = elementData.fields.find(element => element.name === field.name)?.original_name || field.name;
        if (field.order === 'ASC') {
          dir = orders[field.type][0];
        } else if (field.order === 'DESC') {
          dir = orders[field.type][1];
        }

        return (
          <div key={field.name} className="row">
            <div className="col-xs-6">
              <BTForm.FormGroup
                errorText={`Unknown field '${fieldName}'`}
                hasError={field.name !== '' && fieldTypes[field.name] === undefined}
                label="Sort By"
              >
                <BTComboBox
                  popOutMenu
                  isSearchable={false}
                  maxMenuHeight={100}
                  options={fields}
                  value={{ label: fieldName || field.name, value: field.name }}
                  onChange={value => handleChangeField(value, i)}
                />
              </BTForm.FormGroup>
            </div>
            <div className="col-xs-5">
              <BTForm.FormGroup label="Direction">
                <BTComboBox
                  popOutMenu
                  isSearchable={false}
                  maxMenuHeight={100}
                  options={orders[field.type]}
                  value={dir}
                  onChange={value => handleChangeDirection(value, i)}
                />
              </BTForm.FormGroup>
            </div>
          </div>
        );
      }),
    [fieldTypes, elementData.sortedFields, fields, handleChangeField, handleChangeDirection, elementData.fields]
  );

  return (
    <ElementInspector elementData={elementData} updateData={updateData} {...props}>
      <div className="wkp-fields-selector">
        <BTForm>{sorts}</BTForm>
      </div>
    </ElementInspector>
  );
};

const orders = {
  text: [
    { label: 'A to Z', value: 'ASC' },
    { label: 'Z to A', value: 'DESC' },
  ],
  numeric: [
    { label: 'Smallest to largest', value: 'ASC' },
    { label: 'Largest to smallest', value: 'DESC' },
  ],
  date: [
    { label: 'Oldest to newest', value: 'ASC' },
    { label: 'Newest to oldest', value: 'DESC' },
  ],
};

export default SortElementInspector;
