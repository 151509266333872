import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PickFileDialog from '../PickFileDialog/PickFileDialog';
import FileDialog from '../FileDialog/FileDialog';
import { BTAlert } from '@btas/jasper';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { useSFTPSourceFile } from './useSFTPSourceFile';
import { getErrorMessage } from '../../shared/Status';
import { useInputSourceFiles } from './useInputSourceFiles';
import Spinner from '../../../_shared/Spinner';
import FileSettings from '../FileSettings/FileSettings';

const SFTPInputSubInspector = ({ elementData, elementId, updateData }) => {
  const { dataFlowState, dataFlowActions, sourceFileUpload } = useContext(DataFlowEditorContext);
  const { setFilePropertiesDialog } = dataFlowActions;
  const { workingElement, taxPeriod, filePropertiesDialog } = dataFlowState;

  const [generalError, setGeneralError] = useState({
    message: undefined,
  });

  const dataflowId = dataFlowState.id;

  const { sourceFiles, setSourceFiles } = useInputSourceFiles(
    dataFlowState.id,
    elementId,
    elementData?.pendingSourceFileVersionId,
    filePropertiesDialog?.reloadFiles
  );

  const fields = elementData.fields || [];

  const history = useHistory();
  const onErrorCallback = code => {
    setFilePropertiesDialog({
      popup: false,
      loading: false,
      saving: false,
      sheets: [],
      reloadFiles: false,
    });
    elementData.integrationType = null;
    setGeneralError({ message: getErrorMessage(code) });
  };

  const handleDismissAlert = () => {
    history.replace(`/data-flows/${dataflowId}/editor`);
    setGeneralError(null);
  };

  const { uploadedSFTPFile, selectedSFTPFile, selectedSFTPFileSheetData, setOpenSourceFile, openSourceFile } =
    useSFTPSourceFile(elementId, onErrorCallback);

  useEffect(() => {
    if (openSourceFile) {
      const { type, name } = openSourceFile;
      const isCsvFile = type.toLowerCase() === 'csv';
      setFilePropertiesDialog({
        isCsvFile: isCsvFile,
        useSFTP: true,
        systemCode: 'sftp',
        fileName: name,
        uploadedSFTPFile,
        selectedSFTPFile,
        selectedSFTPFileSheetData,
        popup: true,
        loading: false,
        uploadExistingFile: false,
        fileDialogTaxPeriod: taxPeriod,
        sourceInfo: {
          dataFlowId: dataFlowState.id,
          inputId: elementId,
          elementData: { ...workingElement.elementData, containsNewSourceFiles: true },
        },
        reloadFiles: true,
      });
    }
  }, [
    openSourceFile,
    uploadedSFTPFile,
    selectedSFTPFile,
    selectedSFTPFileSheetData,
    dataFlowState.id,
    elementId,
    setFilePropertiesDialog,
    workingElement.elementData,
    taxPeriod,
  ]);

  const onOpenFile = file => {
    setOpenSourceFile(file);
  };

  const { isUploading } = sourceFileUpload;

  const importSFTPFileDialog = () => {
    return (
      <PickFileDialog
        dataFlowActions={dataFlowActions}
        dataFlowState={dataFlowState}
        setGeneralError={setGeneralError}
        onOpenFile={onOpenFile}
      />
    );
  };

  return (
    <div className="sftp-input-element-inspector">
      {fields.length > 0 && sourceFiles.length > 0 && !sourceFiles[0].error ? (
        <FileSettings
          elementData={elementData}
          elementId={elementId}
          importFile={importSFTPFileDialog}
          setGeneralError={setGeneralError}
          setSourceFiles={setSourceFiles}
          sourceFiles={sourceFiles}
          updateData={updateData}
        />
      ) : isUploading ? (
        <Spinner />
      ) : (
        <PickFileDialog
          dataFlowActions={dataFlowActions}
          dataFlowState={dataFlowState}
          setGeneralError={setGeneralError}
          onOpenFile={onOpenFile}
        />
      )}

      <FileDialog
        dataFlowActions={dataFlowActions}
        dataFlowState={dataFlowState}
        setGeneralError={setGeneralError}
        setOpenSourceFile={setOpenSourceFile}
        setSourceFiles={setSourceFiles}
        sourceFiles={sourceFiles}
      />
      <BTAlert
        appear
        dismissible
        fixed
        btStyle="danger"
        visible={!!generalError?.message}
        onDismiss={handleDismissAlert}
      >
        {generalError ? generalError.message : ''}
      </BTAlert>
    </div>
  );
};

export default SFTPInputSubInspector;
