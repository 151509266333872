import React, { useState, useEffect, useRef, useContext } from 'react';
import CloseButton from './shared/CloseButton';
import { getComboboxData, putIsGlobalTemplate, getAllTags, createOrUpdateTags } from './SettingsPanel/apis';
import Form from './SettingsPanel/Form';
import './SettingsPanel/styles.scss';
import EditorContext from '../../EditorContext';
import { registerGlobalCustomFunctions } from '../../Spreadsheet/_spreadsheets/';
import { useMetadata } from './SettingsPanel/useMetadata';
import { BTButton, BTModal } from '@btas/jasper';
import GeneralErrorMessage from '../../../../_shared/GeneralErrorMessage';
import { isGlobalTemplateAccount } from '../../../../_shared/auth';

const modalInfo = {
  true: {
    title: 'Mark as Global Template',
    message: 'This workpaper will be available to all customers. Are you sure?',
    confirmButtonText: 'Mark as global template',
  },
  false: {
    title: 'Unmark as Global Template',
    message: 'This workpaper will no longer be available to customers. Are you sure?',
    confirmButtonText: 'Unmark as global template',
  },
};

export default function SettingsPanel({ workpaperId, onClose }) {
  const metadataRef = useRef({});
  const { getMetadata, putMetadata } = useMetadata({ workpaperId });
  const [comboboxOptions, setComboboxOptions] = useState({
    entities: [],
    taxPeriods: [],
    workpaperTypes: [],
    jurisdictions: [],
    tags: [],
  });

  const {
    spreadRef,
    setWorkbookName,
    enqueueCommands,
    enqueueDataReference,
    renderCustomFormulaValues,
    getCellReferenceTag,
    gcBaseCustomFunction,
    enqueueDataReferenceReCalc,
    isDragFillAction,
    isCopyPasteAction,
    referenceExistInWorksheet,
    referenceExistInTargetCell,
    referenceIsEnqueuedForRecalc,
    isFormulaMatch,
    stateTaxJurisdictions,
    cellChangedData,
  } = useContext(EditorContext);
  const [form, setForm] = useState({
    id: '',
    name: '',
    entity: undefined,
    taxPeriod: undefined,
    workpaperType: undefined,
    isGlobalTemplate: false,
    jurisdiction: undefined,
    selectedTags: undefined,
    fieldErrors: {},
    errors: [],
    isSaving: false,
    templateDescription: '',
  });

  const [originalIsGlobalTemplate, setOriginalIsGlobalTemplate] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const { id, name, entity, taxPeriod, workpaperType, jurisdiction, isSaving, templateDescription } = form;
  useEffect(() => {
    async function get() {
      const data = await getComboboxData();
      const tags = await getAllTags(workpaperId);
      setComboboxOptions({
        entities: data.entities.map(e => ({ value: e, label: e })),
        taxPeriods: data.taxPeriods.map(e => ({ value: e, label: e })),
        workpaperTypes: data.workpaperTypes.map(e => ({ value: e, label: e })),
        jurisdictions: data.jurisdictions.map(e => ({ value: e, label: e })),
        tags: tags.tags.map(e => ({ value: e.name, label: e.name, id: e.id })),
      });
    }

    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function get() {
      const createComboboxItem = text => (text ? { value: text, label: text } : undefined);
      const data = await getMetadata();
      const tags = await getAllTags(workpaperId);
      metadataRef.current = data;
      const resourceTags = tags
        ? tags?.resourceTags?.map(r => ({ id: r, name: tags?.tags?.find(t => t.id === r).name }))
        : [];
      const selectedTagOptions = resourceTags
        ? resourceTags.map(e => ({ value: e.name, label: e.name, id: e.id }))
        : [];

      setForm(form => ({
        ...form,
        id: workpaperId,
        name: data.name,
        entity: createComboboxItem(data.entity),
        taxPeriod: createComboboxItem(data.taxPeriod),
        jurisdiction: createComboboxItem(data.jurisdiction),
        workpaperType: createComboboxItem(data.workpaperType),
        isGlobalTemplate: data.isGlobalTemplate,
        templateDescription: data.templateDescription,
        selectedTags: selectedTagOptions,
      }));

      setOriginalIsGlobalTemplate(data.isGlobalTemplate);

      setWorkbookName(data.name);
    }

    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workpaperId]);

  useEffect(() => {
    if (!isSaving) {
      return;
    }

    async function save() {
      setShowErrorMessage(false);
      const newEntity = entity ? entity.value : undefined;
      const newTaxPeriod = taxPeriod ? taxPeriod.value : undefined;
      const newWorkpaperType = workpaperType ? workpaperType.value : undefined;
      const newJurisdiction = jurisdiction ? jurisdiction.value : undefined;
      const currentTaxPeriod = metadataRef.current.taxPeriod;
      const isGlobalTemplate = form.isGlobalTemplate;
      try {
        if (isGlobalTemplateAccount() && originalIsGlobalTemplate !== isGlobalTemplate) {
          setShowConfirmationModal(false);

          try {
            await putIsGlobalTemplate({ id: workpaperId, isGlobalTemplate });
            setOriginalIsGlobalTemplate(isGlobalTemplate);
          } catch (error) {
            setShowErrorMessage(true);
            console.error('Error marking global template:', error, showErrorMessage);
            return false;
          }
        }

        await putMetadata({
          id,
          name,
          entity: newEntity,
          taxPeriod: newTaxPeriod,
          currentTaxPeriod: currentTaxPeriod,
          jurisdiction: newJurisdiction,
          workpaperType: newWorkpaperType,
          templateDescription,
        });

        validateTagFieldLength(form.selectedTags);

        const createNewTagPayLoad = {
          tags: form.selectedTags.map(t => ({ id: t.id, name: t.value })),
          resourceId: workpaperId,
          resourceType: 'Spreadsheet',
        };
        await createOrUpdateTags(createNewTagPayLoad);

        registerGlobalCustomFunctions(
          newTaxPeriod,
          workpaperId,
          enqueueCommands,
          spreadRef.current,
          enqueueDataReference,
          renderCustomFormulaValues,
          getCellReferenceTag,
          gcBaseCustomFunction,
          enqueueDataReferenceReCalc,
          isDragFillAction,
          isCopyPasteAction,
          referenceExistInWorksheet,
          referenceExistInTargetCell,
          referenceIsEnqueuedForRecalc,
          isFormulaMatch,
          stateTaxJurisdictions,
          cellChangedData
        );
        setWorkbookName(name);
        return true;
      } catch (error) {
        if (Array.isArray(error)) {
          const errors = error.filter(e => !e.target);
          const fieldErrors = error
            .filter(e => e.target)
            .reduce((prev, curr) => ({ ...prev, [curr.target]: curr.message }), {});

          setForm({
            ...form,
            errors,
            fieldErrors,
            isSaving: false,
          });

          return false;
        }
      } finally {
        // setForm needs to do functional update on form to maintain the consistency
        setForm(state => ({ ...state, isSaving: false }));
      }
    }

    (async function () {
      const ok = await save();
      if (ok) {
        onClose(false);
        // showConfirmationModal means the global template checkbox changed state
        if (window && showConfirmationModal) window.location.reload();
      }
    })();
    // isSaving is the only one dependency needed for this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const handleDescriptionChange = e => {
    let fieldErrors = form.fieldErrors;
    delete fieldErrors?.TemplateDescription;
    setForm({
      ...form,
      templateDescription: e.target.value,
      fieldErrors,
    });
  };

  const validateTagFieldLength = tags => {
    const exceedMaxLen = tags.filter(t => t.isNew && t.value.length > 30);
    if (exceedMaxLen.length > 0) {
      const err = [{ target: 'Tag', message: 'Max tag length allow is 30 characters' }];
      throw err;
    }
  };
  const handleComboboxChange = id => opt => {
    setForm({
      ...form,
      [id]: opt,
    });
  };

  const handleChange =
    id =>
    ({ target: { value } }) => {
      setForm({
        ...form,
        [id]: value,
      });
    };

  const handleCheckboxChange = ({ target: { checked } }) => {
    setForm({
      ...form,
      isGlobalTemplate: checked,
    });
  };

  const handleSaveClick = async () => setForm({ ...form, fieldErrors: {}, errors: [], isSaving: true });

  const didCheckboxChange = () => originalIsGlobalTemplate !== form.isGlobalTemplate;

  return (
    <div className="wkp-right-pane">
      <div className="wkp-right-pane-header">
        <h2>Workpaper Settings</h2>
        <CloseButton onClose={onClose} />
      </div>

      {showConfirmationModal && (
        <BTModal
          id="research-disclaimer-modal"
          show={true}
          size="modal-sm"
          title={modalInfo[form.isGlobalTemplate].title}
          onCloseClick={() => setShowConfirmationModal(false)}
        >
          <BTModal.Body className="feedbackForm">
            <p style={{ fontWeight: 500 }}>{modalInfo[form.isGlobalTemplate].message}</p>
          </BTModal.Body>
          <BTModal.Footer>
            <>
              <BTButton btStyle="default" onClick={() => setShowConfirmationModal(false)}>
                Cancel
              </BTButton>
              <BTButton btStyle="primary" onClick={handleSaveClick}>
                {modalInfo[form.isGlobalTemplate].confirmButtonText}
              </BTButton>
            </>
          </BTModal.Footer>
        </BTModal>
      )}

      <GeneralErrorMessage dismissible={true} visible={showErrorMessage} />
      <Form
        comboboxOptions={comboboxOptions}
        didCheckboxChange={didCheckboxChange}
        setShowConfirmationModal={setShowConfirmationModal}
        state={form}
        templateDescriptionValue={templateDescription}
        onCancelClick={onClose}
        onChange={handleChange}
        onCheckboxChange={handleCheckboxChange}
        onComboboxChange={handleComboboxChange}
        onDescriptionChange={handleDescriptionChange}
        onSaveClick={handleSaveClick}
      />
    </div>
  );
}
