import * as api from '../../../../_shared/fetch';
import { spreadsheetServiceHostName } from '../../../../../configs/params';

export async function copySheet({ sourceWorkpaperId, worksheetNames, targetWorkpaperId, beforeSheet }) {
  const response = await api.post(
    `${spreadsheetServiceHostName}/spreadsheet/workbook/${sourceWorkpaperId}/copy-sheet/${targetWorkpaperId}`,
    {
      body: JSON.stringify({
        worksheetNames,
        beforeSheet,
      }),
    }
  );

  const result = { success: response.ok };

  if (!response.ok) {
    if (response.status === 400) {
      const data = await response.json();
      result.errorMessage = data.title;
    } else {
      result.systemErrorMessage = await response.text();
    }
  }

  return result;
}

export async function getWorkpapers() {
  const response = await api.get(`${spreadsheetServiceHostName}/spreadsheet/workpapers`);
  return await response.json();
}

export async function getWorkbookSheets(workpaperId) {
  const response = await api.get(`${spreadsheetServiceHostName}/spreadsheet/workbook/${workpaperId}/worksheets`);
  return await response.json();
}
