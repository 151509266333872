import React, { useEffect, useState } from 'react';
import { DataFlowCanvas } from './DataFlowCanvas';
import { DataFlowEditorContext } from './DataFlowEditorContext';
import { DataFlowEditorHeaderBar } from './DataFlowEditorHeaderBar';
import DataFlowInspector from './DataFlowInspector';
import { DataFlowNavigator } from './DataFlowNavigator';
import DataFlowOutputPreview from './DataFlowOutputPreview';
import { DataFlowStencil } from './DataFlowStencil';
import DirtyStateHandler from './DirtyStateHandler';
import FailedToRestoreWarning from './FailedToRestoreWarning';
import { useDataFlowStateReducer } from './useDataFlowStateReducer';
import usePreviewRun from './DataFlowOutputPreview/usePreviewRun';
import { DataFlowConfirmationModal } from './DataFlowConfirmationModal';
import { useConfirmationModal } from './useConfirmationModal';
import ValidateDataFlow from './ValidateDataFlow';
import useUploadSourceFile from './InputElementInspector/useUploadSourceFile';
import { useFeatureFlags } from './../../../utils/useFeatureFlags';
import DataFlowSideBar from './DataFlowSideBar';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_DATAFLOW_SIDEBAR } from '../../../constants/featureFlags';
import ErrorRetrievingRole from '../ErrorRetrievingRole';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';

const DataFlowEditorPageContent = ({ dataFlow }) => {
  const [dataFlowState, dataFlowActions] = useDataFlowStateReducer(dataFlow);
  const [previewState, previewActions] = usePreviewRun(dataFlowState, dataFlowActions);
  const [confirmationModalState, showConfirmationModal] = useConfirmationModal();
  const sourceFileUpload = useUploadSourceFile(dataFlowState, dataFlowActions);
  const { featureFlag: featureFlags } = useFeatureFlags();
  const [inspectorIsPresent, setInspectorIsPresent] = useState(false);
  const [previewIsPresent, setPreviewIsPresent] = useState(false);
  const [openedSidePanel, setOpenedSidePanel] = useState(null);
  const canEditWorkflow = useCanEditWorkflow();
  const editorContext = {
    dataFlowState,
    dataFlowActions,
    previewState,
    previewActions,
    showConfirmationModal,
    sourceFileUpload,
    featureFlags,
  };
  const checkOutputPreviewRender = value => {
    setPreviewIsPresent(value);
  };

  const checkInspectorRender = value => {
    setInspectorIsPresent(value);
  };
  const handleSideBarIconClick = panel => () => {
    if (!dataFlowState.activeElement?.id) {
      setOpenedSidePanel(prev => (prev === panel ? null : panel));
    }
    if (dataFlowState.activeElement?.id && panel !== 'configuration') {
      setOpenedSidePanel(panel);
    } else if (dataFlowState.activeElement?.id && panel === 'configuration') {
      setOpenedSidePanel(null);
    }
  };

  const handleOnPanelClose = () => {
    dataFlowActions.clearActiveElement();
    setOpenedSidePanel(null);
  };

  useEffect(() => {
    setOpenedSidePanel(null);
  }, [dataFlowState.isClickingCanvas, dataFlowState.activeElement?.id]);

  return (
    <div className="wkp-data-flows-editor-page">
      <ErrorRetrievingRole appear dismissible fixed btStyle="warning"></ErrorRetrievingRole>
      <DataFlowEditorContext.Provider value={editorContext}>
        <DataFlowEditorHeaderBar />
        <div className="wkp-data-flow-editor">
          <DataFlowCanvas
            configInspectorIsPresent={!!openedSidePanel}
            initialDataFlow={dataFlow}
            inspectorIsPresent={inspectorIsPresent}
            previewIsPresent={previewIsPresent}
          />
          <DataFlowInspector callback={checkInspectorRender} />
          <DataFlowOutputPreview callback={checkOutputPreviewRender} />
          {canEditWorkflow && <DataFlowStencil />}
          <DataFlowNavigator />
          <DataFlowConfirmationModal modalState={confirmationModalState} />
        </div>
        <DirtyStateHandler />
        <FailedToRestoreWarning />
        <ValidateDataFlow dataFlowId={dataFlow.id} />
        {isFeatureFlagEnabled(WKP_DATAFLOW_SIDEBAR) && (
          <DataFlowSideBar
            activeElement={dataFlowState.activeElement?.id}
            handleOnPanelClose={handleOnPanelClose}
            handleSideBarIconClick={handleSideBarIconClick}
            openedSidePanel={openedSidePanel}
          />
        )}
      </DataFlowEditorContext.Provider>
    </div>
  );
};

export default DataFlowEditorPageContent;
