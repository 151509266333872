import { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { BTButton, BTIcon, BTModal, BTGrid } from '@btas/jasper';
import React from 'react';
import './styles.scss';
import useGrid from './useGrid';
import { wijmoKey } from '../../../../configs/params';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import workpaperFileIcon from './wkp_file_icon.svg';

const PickFileDialog = ({ dataFlowActions, dataFlowState, setGeneralError, onOpenFile }) => {
  const { setSFTPInputProperties } = dataFlowActions;
  const { sftpInputProperties, workingElement } = dataFlowState;

  const [gridConfig, gridActions] = useGrid(setGeneralError);
  const { isLoading, gridData, /*defaultSort,*/ selectedObject } = gridConfig;
  const { /*setGridSorting,*/ setSelectedObject, setRefreshedSFTP } = gridActions;
  const { items } = gridData;

  const [gridDataItems, setGridDataItems] = useState(items);
  const [breadcrumbPath, setBreadcrumbPath] = useState([]);
  const [validationError, setValidationError] = useState({});
  const [selectedFile, setSelectedFile] = useState({});

  const history = useHistory();

  useEffect(() => {
    return () => {
      setSFTPInputProperties({
        loading: false,
        popup: false,
      });
    };
  }, [setSFTPInputProperties]);

  useEffect(() => {
    const updateGridItems = async () => {
      setGridDataItems(items);
    };
    updateGridItems();
    if (!workingElement?.elementData?.fields || workingElement?.elementData?.isSourceFileDeleted) {
      setSFTPInputProperties({ popup: true });
    }
  }, [
    items,
    breadcrumbPath,
    selectedObject,
    setSFTPInputProperties,
    setBreadcrumbPath,
    selectedFile,
    workingElement?.elementData?.fields,
    workingElement?.elementData?.isSourceFileDeleted,
  ]);

  const onRowSelected = rowList => {
    if (rowList.length > 1 || rowList.length === 0) {
      setSelectedFile(null);
      return;
    }

    const file = rowList[0];
    onSelectionChanged(file);
  };

  const onSelectionChanged = file => {
    let breadcrumbToUpdate = breadcrumbPath;
    if (!file) {
      // restart the path when file is null
      breadcrumbToUpdate.splice(0, breadcrumbPath.length);
      setBreadcrumbPath(breadcrumbToUpdate);
      setSelectedObject(file);
      setSelectedFile(null);
      return;
    }

    if (file.type === 'folder') {
      const pathIndex = breadcrumbPath.findIndex(object => {
        return object.path === file.path;
      });
      if (pathIndex > -1) {
        // only remove all the elements after the found folder
        breadcrumbToUpdate.splice(pathIndex + 1);
      } else {
        if (!file.prefix) {
          file.prefix = '';
        }
        file.prefix += `/${file.name}`;
        breadcrumbToUpdate.push(file);
      }

      setBreadcrumbPath(breadcrumbToUpdate);

      setSelectedObject(file);
      setSelectedFile(null);
      return;
    }
    // set the file to open
    setSelectedFile(file);
  };

  /* TODO: Implement sort */
  /*
  const onSortChange = order => setGridSorting(order);
  const handleSort = columnSort => {
    onSortChange(columnSort);
  };*/

  const isValidFile = fileExtension => {
    return ['xlsx', 'csv'].includes(fileExtension?.toLowerCase());
  };

  const isValidSelection = fileExtension => {
    return ['folder', 'xlsx', 'csv'].includes(fileExtension?.toLowerCase());
  };

  const onOpen = file => {
    setValidationError({});
    setSFTPInputProperties({
      popup: false,
      loading: false,
    });
    onOpenFile(file);
  };

  const onClose = () => {
    setValidationError({});
    setSFTPInputProperties({
      popup: false,
      loading: false,
    });
    history.goBack();
  };

  const nameCell = ({ rowData }) => {
    if (!rowData) return null;
    let icon = 'file';
    switch (rowData.type) {
      case 'folder':
        icon = 'folder';
        break;
      case 'xlsx':
      case 'csv':
        icon = 'file-excel';
        break;
      default:
        break;
    }
    if (!isValidSelection(rowData.type)) {
      return (
        <span className="sftp-invalid-file-cell">
          <BTIcon icon={icon} />
          {rowData.name}
        </span>
      );
    }

    return (
      <>
        {['csv', 'xlsx'].includes(rowData.type) ? (
          <img alt="icon wkp file" className="wkp-file-excel-icon" src={workpaperFileIcon} />
        ) : (
          <BTIcon className="wkp-folder-icon-class" icon={icon} />
        )}
        <span className="sftp-file-cell">{rowData.name}</span>
      </>
    );
  };

  const typeCell = ({ rowData }) => {
    if (!rowData) return null;
    let type;
    switch (rowData.type.toLowerCase()) {
      case 'folder':
        type = 'Folder';
        break;
      case 'xlsx':
        type = 'Microsoft Excel Worksheet';
        break;
      case 'csv':
        type = 'Comma Separated Values File';
        break;
      default:
        type = 'Unsupported File';
        break;
    }
    if (!isValidSelection(rowData.type)) {
      return <span className="sftp-invalid-file-cell">{type}</span>;
    }

    return (
      <>
        <span>{type}</span>
      </>
    );
  };

  const lastModifiedCell = ({ rowData }) => {
    if (!rowData) return null;
    if (!isValidSelection(rowData.type)) {
      return <span className="sftp-invalid-file-cell">{rowData.lastModified}</span>;
    }

    return (
      <>
        <span>{rowData.lastModified}</span>
      </>
    );
  };

  const fileSizeCell = ({ rowData }) => {
    if (!rowData) return null;

    if (!isValidSelection(rowData.type)) {
      return <span className="sftp-invalid-file-cell">{rowData.fileSize}</span>;
    }

    return (
      <>
        <span>{rowData.fileSize}</span>
      </>
    );
  };

  const renderBreadcrumb = () => {
    return (
      <>
        <div className="wkp-dialog-breadcrumb">
          <BTButton btStyle="link" onClick={() => onSelectionChanged(null)}>
            SFTP
          </BTButton>
          {breadcrumbPath.map(folder => {
            return (
              <>
                <BTIcon icon="chevron-right" />
                <BTButton btStyle="link" onClick={() => onSelectionChanged(folder)}>
                  {folder.name}
                </BTButton>
              </>
            );
          })}
        </div>
      </>
    );
  };

  const getEmptyStateDescription = () => {
    return (
      <>
        Files uploaded to the SFTP site will appear here.&ensp;
        <Link rel="noopener noreferrer" target="_blank" to={`/help/index.html?article=SFTP`}>
          Help with SFTP site.
        </Link>
      </>
    );
  };

  return (
    <BTModal
      className="wkp-sftp-pick-file-dialog"
      show={sftpInputProperties?.popup}
      size="modal-lg"
      title="Select Source File"
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <div className="wkp-dialog-path">
          {renderBreadcrumb()}
          <div className="wkp-dialog-refresh">
            <BTButton btStyle="link" onClick={() => setRefreshedSFTP(true)}>
              <FontAwesomeIcon className="fa-fw" icon={faSync} />
              Refresh
            </BTButton>
          </div>
        </div>
        <BTGrid
          allowSelection
          //defaultSortedColumn={[defaultSort]}
          data={gridDataItems}
          isLoading={isLoading}
          loadingType={'spinner'}
          wijmoKey={wijmoKey}
          onClearFilterClick={onRowSelected}
          onSelectionChanged={onRowSelected}
        >
          <BTGrid.Header
            key="name"
            align="left"
            binding="name"
            cellTemplate={nameCell}
            maxWidth={350}
            width="2*"
            //onSort={handleSort}
          >
            Name
          </BTGrid.Header>
          <BTGrid.Header
            key="type"
            align="left"
            binding="type"
            cellTemplate={typeCell}
            maxWidth={220}
            minWidth={220}
            width="2*"
            //onSort={handleSort}
          >
            Type
          </BTGrid.Header>
          <BTGrid.Header
            key="lastModified"
            align="left"
            binding="lastModified"
            cellTemplate={lastModifiedCell}
            maxWidth={200}
            width="2*"
            //onSort={handleSort}
          >
            Last modified
          </BTGrid.Header>
          <BTGrid.Header
            key="fileSize"
            align="left"
            binding="fileSize"
            cellTemplate={fileSizeCell}
            maxWidth={90}
            width="*"
            //onSort={handleSort}
          >
            File size
          </BTGrid.Header>
          <BTGrid.EmptyState
            emptyStateDescription={getEmptyStateDescription()}
            icon={<BTIcon icon={'folder'} />}
            message={'This folder is empty.'}
          />
        </BTGrid>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton onClick={onClose}>Cancel</BTButton>
        <BTButton
          btStyle="primary"
          disabled={!isValidFile(selectedFile?.type) || Object.keys(validationError).length > 0}
          hasSpinner={false}
          onClick={() => onOpen(selectedFile)}
        >
          Select
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
};

export default PickFileDialog;
