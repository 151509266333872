import { cloneDeep, isEqual } from 'lodash';
import { getElementType } from '../shared/graphUtils';
import { SaveStateActions, SAVE_STATE } from './saveStateReducer';
import { InputElementType } from '../elementType/types/InputElementType';
import { LinksActions } from './linksReducer';
import { unhighlightElement, highlightElement } from '../shared/restyleCell';

export const initialElementsState = {
  activeElement: null,
  workingElement: null,
  workingElementIsDirty: false,
  elements: {},
};

export const ElementsActions = {
  setActiveElement: 'set-active-element',
  clearActiveElement: 'clear-active-element',
  addElement: 'add-element',
  removeElement: 'remove-element',
  commitWorkingElement: 'commit-working-element',
  updateWorkingElement: 'update-working-element',
  syncWorkingElement: 'sync-working-element',
  setElementData: 'set-element-data',
  setFindAndReplacePropDialog: 'set-find-replace-properties',
  setFilePropertiesDialog: 'set-file-properties',
  setFAInputProperties: 'set-fa-input-properties',
  setSFTPInputProperties: 'set-sftp-input-properties',
  updateOutputOnExport: 'update-output-on-export',
  setWKPFileImportProperties: 'set-wkp-file-import-properties',
};

export const elementsActions = dispatch => ({
  setActiveElement: cell => dispatch({ type: ElementsActions.setActiveElement, cell }),
  clearActiveElement: () => dispatch({ type: ElementsActions.clearActiveElement }),
  updateWorkingElement: elementData =>
    dispatch({
      type: ElementsActions.updateWorkingElement,
      elementData,
    }),
  syncWorkingElement: elementData =>
    dispatch({
      type: ElementsActions.syncWorkingElement,
      elementData,
    }),
  commitWorkingElement: () => dispatch({ type: ElementsActions.commitWorkingElement }),
  addElement: (cell, dataOverrides, restore) =>
    dispatch({
      type: ElementsActions.addElement,
      cell,
      dataOverrides,
      restore,
    }),
  removeElement: (elementId, restore) => dispatch({ type: ElementsActions.removeElement, elementId, restore }),
  setElementData: (elementId, elementData) =>
    dispatch({
      type: ElementsActions.setElementData,
      elementId,
      elementData,
    }),
  setFindAndReplacePropDialog: value => dispatch({ type: ElementsActions.setFindAndReplacePropDialog, value }),
  setFilePropertiesDialog: value => dispatch({ type: ElementsActions.setFilePropertiesDialog, value }),
  setFAInputProperties: value => dispatch({ type: ElementsActions.setFAInputProperties, value }),
  setSFTPInputProperties: value => dispatch({ type: ElementsActions.setSFTPInputProperties, value }),
  updateOutputOnExport: value => dispatch({ type: ElementsActions.updateOutputOnExport, value }),
  setWKPFileImportProperties: value => dispatch({ type: ElementsActions.setWKPFileImportProperties, value }),
});

export const elementsReducer = (state, action) => {
  switch (action.type) {
    case ElementsActions.setActiveElement: {
      const { cell } = action;
      const elementId = cell.id;

      if (state.activeElement?.id !== elementId) {
        const activeElement = state.elements[elementId];
        unhighlightElement(state.activeElement);
        highlightElement(activeElement);

        return {
          ...commitWorkingElement(state),
          ...syncWorkingElementWithActive(activeElement),
        };
      } else {
        return state;
      }
    }

    case LinksActions.setActiveLink:
    case ElementsActions.clearActiveElement:
      const { activeElement, workingElement, ...newState } = commitWorkingElement(state);
      unhighlightElement(activeElement);
      return newState;

    case ElementsActions.updateWorkingElement: {
      const { workingElement } = state;
      const { elementData } = workingElement;

      if (!isEqual(elementData, action.elementData)) {
        return {
          ...state,
          workingElement: { ...workingElement, elementData: { ...elementData, ...action.elementData } },
          workingElementIsDirty: true,
          saveMenu: { saveButton: false, saveAndPubButton: false, exportButton: true, state: SAVE_STATE.dirty },
        };
      } else {
        return state;
      }
    }

    case ElementsActions.commitWorkingElement: {
      const { activeElement, workingElement } = state;
      const newActiveElement = activeElement
        ? { ...activeElement, elementData: workingElement.elementData }
        : undefined;

      return {
        ...commitWorkingElement(state),
        ...syncWorkingElementWithActive(newActiveElement),
      };
    }

    case ElementsActions.syncWorkingElement: {
      const { activeElement, workingElement } = state;
      const newWorkingElement = {
        ...workingElement,
        elementData: { ...workingElement.elementData, ...action.elementData },
      };
      const newActiveElement = activeElement
        ? { ...activeElement, elementData: newWorkingElement.elementData }
        : undefined;

      if (!isEqual(workingElement.elementData, action.elementData)) {
        return {
          ...commitWorkingElement(state),
          ...syncWorkingElementWithActive(newActiveElement),
        };
      } else {
        return state;
      }
    }

    case ElementsActions.addElement: {
      const { cell, dataOverrides = {} } = action;
      const elementType = getElementType(cell);
      const elementData = { ...elementType.initialData, ...dataOverrides };
      const newElement = { id: cell.id, cell, elementData, elementType, type: elementType.type };

      return {
        ...state,
        elements: { ...state.elements, [newElement.id]: newElement },
      };
    }

    case ElementsActions.removeElement: {
      const { elementId } = action;

      // Remove element
      const newElements = { ...state.elements };
      delete newElements[elementId];

      const newState = { ...state, elements: newElements };

      // If removed element is currently active, clear active and working elements
      if (newState?.activeElement?.id === elementId) {
        delete newState.activeElement;
        delete newState.workingElement;
        newState.workingElementIsDirty = false;
      }
      return newState;
    }

    case ElementsActions.setElementData: {
      const newElementData = action.elementData;
      const currentElement = state.elements[action.elementId];
      if (!currentElement || isEqual(newElementData, currentElement.elementData)) {
        return state;
      }

      const newElement = {
        ...currentElement,
        elementData: newElementData,
      };

      const newElements = {
        ...state.elements,
        [action.elementId]: newElement,
      };

      let newActiveElement;
      if (state.activeElement?.id === action.elementId) {
        newActiveElement = newElement;
      }

      return {
        ...state,
        elements: newElements,
        ...syncWorkingElementWithActive(newActiveElement, false),
      };
    }

    case SaveStateActions.setDirty: {
      // If we've just saved the data flow (isDirty is false), clear reference to pending source data
      // from any input blocks.
      const { isDirty } = action;
      if (!isDirty) {
        const newElements = Object.keys(state.elements).reduce((newElements, elementId) => {
          let element = state.elements[elementId];

          if (element.type === InputElementType.TYPE) {
            const { pendingSourceFileVersionId, ...rest } = element.elementData;
            element = { ...element, elementData: rest };
          }

          newElements[elementId] = element;
          return newElements;
        }, {});

        let activeElement;
        if (state.activeElement) {
          activeElement = newElements[state.activeElement.id];
        }

        return {
          ...state,
          elements: newElements,
          ...syncWorkingElementWithActive(activeElement),
        };
      } else {
        return state;
      }
    }
    case ElementsActions.setFindAndReplacePropDialog: {
      const { value } = action;

      return {
        ...state,
        findAndReplaceDialog: value,
      };
    }
    case ElementsActions.setFilePropertiesDialog: {
      const { value } = action;
      return {
        ...state,
        filePropertiesDialog: value,
      };
    }
    case ElementsActions.setFAInputProperties: {
      const { value } = action;
      return {
        ...state,
        faInputProperties: value,
      };
    }

    case ElementsActions.setSFTPInputProperties: {
      const { value } = action;
      return {
        ...state,
        sftpInputProperties: value,
      };
    }

    case ElementsActions.updateOutputOnExport: {
      const { value } = action;
      const copyState = { ...state };
      let element = copyState.elements[value.id];
      element.elementData['export'] = value.export;
      if (value) {
        return {
          ...copyState,
          saveMenu: { saveButton: true, saveAndPubButton: true, exportButton: false, state: SAVE_STATE.published },
        };
      } else {
        return state;
      }
    }

    case ElementsActions.setWKPFileImportProperties: {
      const { value } = action;
      return {
        ...state,
        wkpFileImportProperties: value,
      };
    }

    default:
      return state;
  }

  function commitWorkingElement(state) {
    const { workingElement } = state;

    if (workingElement) {
      const currentElement = state.elements[workingElement.id];
      const newElementData = workingElement.elementData;

      if (!newElementData.name) {
        newElementData.name = currentElement.elementData.name;
      }

      if (newElementData.hasOwnProperty('name')) {
        currentElement.cell.attr('.name/text', newElementData.name);
      }

      const newElements = {
        ...state.elements,
        [workingElement.id]: { ...currentElement, elementData: workingElement.elementData },
      };

      return {
        ...state,
        elements: newElements,
        workingElementIsDirty: false,
      };
    } else {
      return state;
    }
  }

  function syncWorkingElementWithActive(activeElement, workingElementIsDirty = false) {
    const workingElement = activeElement
      ? { ...activeElement, elementData: cloneDeep(activeElement.elementData) }
      : undefined;
    return { activeElement, workingElement, workingElementIsDirty };
  }
};
