import GC from '../../../../../../SpreadSheets';
import { v4 as uuidv4 } from 'uuid';
import { STATE_APPORTION } from '../../../../../_shared/DataReference/ReferenceType';
import { isNumeric } from '../formulas';

export const stateApportionFormulaName = 'STATEAPPORTION';

const name = stateApportionFormulaName;

const parameters = [
  {
    name: '"jurisdiction"',
    repeatable: false,
    optional: false,
  },
  {
    name: '"period_start_date"',
    repeatable: false,
    optional: false,
  },
  {
    name: '"factor"',
    repeatable: false,
    optional: false,
  },
];

const description = ' Retrieves the applicable state apportionment factor weighting for sales, property, or payroll.';

const evaluationFunction = (args, data) => {
  const [context, state, date, factor] = args;

  if (state && date && factor) {
    const { row, column } = context;
    const sheet = context.source.getSheet();
    const sheetName = sheet.name();
    const cellTag = data.getCellReferenceTag(sheet, row, column);
    const formula = `=${sheet.getFormula(row, column)}`;

    const requestParameters = {
      row,
      column,
      state,
      date,
      factor,
      type: STATE_APPORTION,
      parameters: {
        State: state,
        Date: date,
        Type: factor,
      },
      value: undefined,
      sheetName,
    };
    const hasCellTag =
      cellTag?.references?.length && cellTag?.references?.filter(x => x.type === STATE_APPORTION)?.length;
    const referenceExistInTargetCell = data.referenceExistInTargetCell(row, column, sheetName, STATE_APPORTION);
    if (!hasCellTag || data.isDragFillAction.current.isActive) {
      if (referenceExistInTargetCell) {
        requestParameters['id'] = referenceExistInTargetCell.id;
        data.enqueueDataReferenceReCalc(requestParameters);
      } else {
        requestParameters['id'] = uuidv4();
        data.enqueueDataReference(requestParameters);
      }
    } else if (hasCellTag) {
      const reference = cellTag.references.find(reference => reference.type === STATE_APPORTION);
      const referenceId = referenceExistInTargetCell ? referenceExistInTargetCell.id : reference.id;
      if (!reference) {
        requestParameters['id'] = uuidv4();
        data.enqueueDataReference(requestParameters);
      } else {
        const referenceExistInWorkSheet = data.referenceExistInWorksheet(referenceId, sheetName);
        const referenceIsEnqueuedForRecalc = data.referenceIsEnqueuedForRecalc(row, column, sheetName);
        requestParameters['id'] = referenceId;
        const formulaMatch = data.isFormulaMatch(requestParameters);
        const cellChangedData =
          data.cellChangedData?.current &&
          (data.cellChangedData?.current?.row !== row ||
            data.cellChangedData?.current.col !== column ||
            data.cellChangedData?.current?.editor?.Li !== formula);
        if (!referenceExistInWorkSheet || data.isCopyPasteAction.current.isActive) {
          if (
            referenceExistInTargetCell &&
            data.isCopyPasteAction.current.isActive &&
            data.isCopyPasteAction.current.sameSheet &&
            data.isCopyPasteAction.current.entireSheet
          ) {
            return formatFormulaReturnValue(sheet.getValue(row, column), sheet, row, column, data);
          }
          requestParameters['id'] = uuidv4();
          data.enqueueDataReference(requestParameters);
        } else if ((!formulaMatch && referenceExistInTargetCell) || cellChangedData || referenceIsEnqueuedForRecalc) {
          data.enqueueDataReferenceReCalc(requestParameters);
          data.cellChangedData.current = null;
        }
      }
    }
    return formatFormulaReturnValue(
      data.renderCustomFormulaValues(cellTag, row, column, STATE_APPORTION, sheetName),
      sheet,
      row,
      column,
      data
    );
  }
  return GC.Spread.CalcEngine.Errors.NotAvailable;
};

function formatFormulaReturnValue(value, sheet, row, column, data) {
  if (value === '') {
    return GC.Spread.CalcEngine.Errors.NotAvailable;
  } else if (isNumeric(value)) {
    const currentFormat = sheet.getFormatter(row, column);
    const format = Number.isInteger(Number(value) * 100) ? '0%' : '0.00%';
    if (!currentFormat) {
      sheet.setFormatter(row, column, format);
    } else {
      sheet.setFormatter(row, column, currentFormat);
    }
  }
  return value ? value : data.defaultValue;
}

export const stateApportionFormula = (gcSyncFunction, data) =>
  new gcSyncFunction({
    name,
    minArgs: 3,
    maxArgs: 3,
    descriptionData: {
      name,
      description,
      parameters,
    },
    evaluationFunction,
    data,
  });
