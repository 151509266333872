import { env } from '../env';
const NodeJsAPIPath = env.REACT_APP_API_NODE_PATH || 'http://localhost:13200/workpapers-node';
const DotNetCoreAPIPath = env.REACT_APP_API_PATH || 'http://localhost:13100';
const SpreadsheetModelAPIPath = env.REACT_APP_API_SPREADSHEET_MODEL_PATH || 'http://localhost:13600';
const DataflowAPIPath = env.REACT_APP_API_DATA_FLOW_PATH || 'http://localhost:13400/workpapers-dataflow';
const NodeAPIPath = env.REACT_APP_NODE_API_GATEWAY_URL || env.REACT_APP_WKP_API_GATEWAY_URL;

export const workpapersServiceHostName = DotNetCoreAPIPath;
export const workpapersNodeServiceHostName = NodeJsAPIPath;
export const workpapersSpreadsheetModelHostName = SpreadsheetModelAPIPath;
export const spreadsheetsServiceHostName = DotNetCoreAPIPath;
export const uploadServiceHostName = DotNetCoreAPIPath;
export const loginServiceHostName = NodeJsAPIPath;
export const historyServiceHostName = DotNetCoreAPIPath;
export const bnaPicksServiceHostName = NodeJsAPIPath;
export const taxRatesServiceUrl = `${
  env.REACT_APP_LOCAL_TAX_RATES_URL || env.REACT_APP_WKP_API_GATEWAY_URL
}/tax/tax-rates`;
export const taxLawGuidanceServiceHostName = NodeJsAPIPath;
export const usersServiceHostName = DataflowAPIPath;
export const dataFlowServiceHostName = DataflowAPIPath;
export const nodeAPIServiceHostName = NodeAPIPath;

export const ecdsLoginPageUrl = env.REACT_APP_ECDS_LOGIN_PAGE_URL;
export const ecdsLogoutPageUrl = env.REACT_APP_ECDS_LOGOUT_PAGE_URL;
export const AUTHENTICATOR_ENDPOINT = `${env.REACT_APP_WKP_API_GATEWAY_URL}/token`;
export const PROVISION_ENDPOINT = `${env.REACT_APP_WKP_API_GATEWAY_URL}/system/state`;
export const workflowAutomationUrl = env.REACT_APP_WORKFLOW_AUTOMATION_URL;
export const consoleLogging = env.REACT_APP_CONSOLE_LOGGING;
export const faLearnMoreLink = 'https://pro.bloombergtax.com/fixed-assets/';
export const fixedAssetsLoginPageUrl =
  env.REACT_APP_FIXED_ASSETS_LOGIN_PAGE_URL || 'https://advantage.bloombergtaxtech.com/';

export const wijmoKey =
  env.REACT_APP_WIJMO_KEY ||
  'Bloomberg Industry Group,148342826924478#B0IwIIzYHMyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQVdzSY9EWjVVcStSOi5kbBVFeOtWNIBDZH3GZ9Zmbm5mWmFlcRdlalxGZCVXRYNWSSlDOqZle9JjewE7KolmUyNnar2yN5UWS8VjSVZkYmtSUaZGR8V4YwYEN8MDSkdHc8ZUYidXMZBnUyoVNzV5MiVTQiFUex3kdKFUSVhVN5dzVKpFOXh5SlNHZl36VHxGcOd7QsNlZIdWO6hUVyg7URZXe8BTO6smavw6d85kexRXcZpXdDp4URxGVJRDWzw6cK5kS03kY5lTbRJUSvZUR5gDVFlGc89UakF5dQRncQJTT8d6cyYlRFxmMKRketVjb5xESTdHarEldYZ7L9tyVxoWURhzRoZnZU9UaMVGTvoUbwVENmR7KWJWRZhlUZlETqJ5cxpGZ8F5S5JVUkd7d7QkejpEV0pWQoBFOyAzVOFDSklFSBRVR8V6d9VTaqxWRWhHN7VmI0IyUiwiIGJkNyEUMxQjI0ICSiwSO6UTM6ITOwETM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLigzM7UjNwAiMwcDMxIDMyIiOiQncDJCLi46bj9CehR7ZyVmYt36bsJmLqwSbvNmLoNWZ4hXY4dmclJWbv3Gbi9iKs46bj9yZk9Wai9iKiojIz5GRiwiIwV7bydEI9JHdzVHZulEInJXZi56bvxmQiojIh94QiwiI8cDN4ITO6IDOyQzM8QTMiojIklkIs4XXbpjIMxNZ';
export const spreadjsKey =
  'Bloomberg Industry Group,271293316296237#B1e1mcL3CVTZ5NxhGTsV5U9gTR6V4Yh9Ec8M4alRXZ0hTSJBHUpZkeIhjQOlzLRFVSIBzRQBXNzp6auJVU9VDeyN5TEl7LaRkbNFzYGpkbvwGRYl4Zx8WZQ3GRDtiSWdneLdUUiBnejRDMn36V6RGUENkdGlURCVlMUZWbZVzRxYzVyo4aSZnVxM5QHZDRrI7R9NESWRWe93Gc5A7d0hzdodXMDVmUxNldxYjVCRXeztya5NGMWdHVpR7NxY6dCpHb6kzM88GbuxkQ7cEZKZ5TYl5Q6JDT0l6LOdmRoxmcxkXcvt6YlNmVLNkI0IyUiwiI4gzMERTMGZjI0ICSiwyM9IjM5ITN4cTM0IicfJye35XX3JSVBtUUiojIDJCLicTMuYHITpEIkFWZyB7UiojIOJyebpjIkJHUiwiI5IjNxEDMgkTMyEzMyAjMiojI4J7QiwiIwV7bydEI9JHdzVHZulEInJXZi56bvxmQiojIh94QiwiI7MjM6kjM6EzMzkjMxcjMiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQ9olS0J7S7t4L6YVeUtUNV56b43SWGJ4YwIkbvhneTh7KXFUWIFle78GW8dWbwcFVuNGaMBjWR3EMDRVUWZmR7R5bnBXcOl4Q7FEb4p5cEJ4MQBHbhBlTBJjcWF7c4VFcYpVSwQjW7M5T117L';
export const spreadjsDesignerKey =
  'Bloomberg Industry Group,129783534967523#B1wOeJVenNXb6l6NvBXa6ZVO6Q6bBFHd0ZGMrRkNQd7U5VjMFZHVnhFZztySSlnbTVmcqBjbXZmbJtmQKhnR856Y9Y6T9tkTwdkRYNWUvY4UntyQTFjeodEUMdjR7pkMRtCZhRGS5p7aJd7ZVh6ZvhTQTVGahRzKTBFUXZWdvJkT5NlZGR4TCd7QoBzL9gzLONjYlJ7Kx9kdPZkdtNDNqZVNEJlcspmNGlWNWpERJp5aSRDRm3iVKJ7QxMFU9RnRtB7brdFUHlnTT9ETsZURkl7KzRUYXp6KO5WeCh4cQZnbsVGdJNWUzAlMBRVWvFDeIZXevcFaOdmI0IyUiwiIxMUMBF4MBVjI0ICSiwyMwADMwIjN5kTM0IicfJye#4Xfd5nITdlM4IiOiMkIsIyNx8idg86bkRWQtIXZudWazVGRtMlSkFWZyB7UiojIOJyebpjIkJHUiwiI8IjNxEDMgkTMyEzMyAjMiojI4J7QiwiIwV7bydEI9JHdzVHZulEInJXZi56bvxmQiojIh94QiwiIzITN7YTO4MTNzgzN9ITMiojIklkIs4XXiUGbiFGV43mdpBlIbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQpZmZwMHOydUNhVzS5c7R8dWSa3WRxklcmdDe9dEa6InNUBXcz3GOhxkbEFDbZt4Ks3GNvI7VLFTONREOxUTeudmMthTcTB7L8hFdHJFcOFGV6MTZrE6NtdnNiZNZgl';

export const featureFlagsServiceHostName = env.REACT_APP_WKP_API_GATEWAY_URL;
export const spreadsheetServiceHostName = env.REACT_APP_WORKBOOK_COMMANDS || env.REACT_APP_WKP_API_GATEWAY_URL;
export const maxCommandQueueRetries = env.REACT_APP_COMMAND_QUEUE_MAX_RETRIES || 5;
export const maxCommandFailsRetries = env.REACT_APP_COMMAND_FAILS_MAX_RETRIES || 2;
export const commandCheckStatusThreshold = env.REACT_APP_COMMAND_SAVE_TIMEOUT || 1_000;
export const dataReferenceUpdateTimeout = env.REACT_APP_DATA_REFERENCE_UPDATE_TIMEOUT || 1000;
export const cleanUpJobsTimeoutInSeconds = env.REACT_APP_CLEANUP_JOBS_TIMEOUT_IN_SECONDS || 330;
export const numberOfTokenRefreshesToRedirectOnLogin =
  env.REACT_APP_NUMBER_OF_TOKEN_REFRESHES_TO_REDIRECT_ON_LOGIN || 24;
export const tokenExpirationTimeout = 240000; // 4 min for token expiration timeout
export const maxCommandDataSizeInMB = env.REACT_APP_MAX_COMMAND_DATA_SIZE_IN_MB || 3;
export const launchDarklyClientID = env.REACT_APP_API_LD_CLIENT_ID;
export const EnviromentName = env.REACT_APP_NODE_ENV;
export const HardDelete = env.REACT_APP_NODE_HARD_DELETE;
export const EnviromentUrl = env.REACT_APP_ENVIROMENT_URL;
export const globalAccountId = env.REACT_APP_GLOBAL_ACCOUNT_ID || 'test-global-template-id';

export const DEFAULT_SHEET_ROW_COUNT = 200;
export const DEFAULT_SHEET_COL_COUNT = 26;
export const workpapersReleaseVersion = env.REACT_APP_WORKPAPERS_RELEASE_VERSION;
export const userDataAPIPath = env.REACT_APP_API_USER_DATA_PATH || 'http://localhost:14000';
export const taxProvisionURL = env.REACT_APP_TAX_PROVISION_URL;
export const taxProvisionClassicURL = env.REACT_APP_TAX_PROVISION_CLASSIC_URL;
export const coreAPIHostName = env.REACT_APP_CORE_API_GATEWAY_URL || env.REACT_APP_WKP_API_GATEWAY_URL;
