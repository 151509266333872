import React from 'react';

import { useParams } from 'react-router-dom';
import Frame from '../_shared/Frame';
import Spinner from '../_shared/Spinner';
import { useDataFlow } from './DataFlowEditorPage/useDataFlow';
import DataFlowEditorPageContent from './DataFlowEditorPage/DataFlowEditorPageContent';
import './DataFlowEditorPage/styles.scss';

export default function DataFlowEditorPage() {
  const { id } = useParams();
  const [dataFlow, { isLoading, isError }] = useDataFlow(id);

  return (
    <Frame activeItem="data-flows">
      {isLoading && <Spinner />}
      {!isLoading && dataFlow && <DataFlowEditorPageContent dataFlow={dataFlow} />}
      {isError && <div className="wkp-load-general-error">Failed to load data flow</div>}
    </Frame>
  );
}
