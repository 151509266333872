import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { JasperThemeProvider } from '@btas/jasper';
import AuthRoute from './App/AuthRoute';
import EditorPageRoute from './App/EditorPageRoute';
import GeneralErrorBoundary from './App/GeneralErrorBoundary';
import AuthLoginPage from './components/auth/AuthLoginPage';
import HomePage from './components/home/HomePage';
import EditorRedirect from './components/editor/EditorRedirect';
import DataFlowsPage from './components/data-flows/DataFlowsPage';
import DataFlowEditorPage from './components/data-flows/DataFlowEditorPage';
import Spinner from './components/_shared/Spinner';
import useWorkpaperApp from './utils/useWorkpaperApp';
import { useAuth } from './components/auth/hooks/useAuth';
import { isFeatureFlagEnabled } from './utils/featureFlags';
import { WKP_LANDING_ON_DATAFLOW } from './constants/featureFlags';
import { usePendo } from './utils/usePendo';
import { useMaintenanceMode } from './components/global-components/Hooks/useMaintenanceMode';
import EditorPage from './components/editor/EditorPage';
import { displayApplicationBrowserConsoleLogs } from './components/_shared/consoleLogger';
import RecycleBin from './components/home/RecycleBin';
import { UserPermissionsProvider } from './components/_shared/UserPermissionsContext';

function App() {
  displayApplicationBrowserConsoleLogs();
  useMaintenanceMode();
  const { user } = useAuth();
  const { isFeatureFlagsLoaded } = useWorkpaperApp();
  usePendo();
  return (
    <JasperThemeProvider>
      <GeneralErrorBoundary>
        {!user || !isFeatureFlagsLoaded ? (
          <Spinner />
        ) : (
          <UserPermissionsProvider>
            <Switch>
              <Route exact component={AuthLoginPage} path="/auth/login" />
              <AuthRoute exact component={HomePage} path="/workpapers" />
              <AuthRoute exact component={RecycleBin} path="/workpapers/recycle-bin" />
              <EditorPageRoute exact component={EditorRedirect} path="/editor" />
              <EditorPageRoute exact component={EditorPage} path="/editor/:id" />
              <EditorPageRoute exact component={EditorPage} path="/editor/:id/history/:versionId" />
              <AuthRoute exact component={DataFlowsPage} path="/data-flows" />
              <AuthRoute
                exact
                path="/data-flows/import"
                render={props => <DataFlowsPage {...props} showImportDialog />}
              />
              <AuthRoute component={DataFlowEditorPage} path="/data-flows/:id/editor" />
              <Redirect to={isFeatureFlagEnabled(WKP_LANDING_ON_DATAFLOW) ? '/data-flows' : '/workpapers'} />
            </Switch>
          </UserPermissionsProvider>
        )}
      </GeneralErrorBoundary>
    </JasperThemeProvider>
  );
}

export default App;
