import { useState, useCallback, useEffect, useContext } from 'react';
import UserPreferencesContext from '../../../_shared/UserPreferencesContext';
import { getParsedList } from './apis';
import { getSFTPlistObject } from '../InputElementInspector/apis';
import { getErrorMessage } from '../../shared/Status';

const defaultSort = { binding: 'name', direction: 'ASC' };
const initialOptions = {
  currentFolder: '/',
  validFiles: ['folder', '.xslx', '.csv'],
};

const initialGridData = { items: [], totalCount: 0, options: initialOptions };

export default function useGrid(setGeneralError) {
  const { userPreferences, setUserPreferences } = useContext(UserPreferencesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [sftpRootList, setSftpRootList] = useState();
  const [gridData, setGridData] = useState(initialGridData);
  const [selectedObject, setSelectedObject] = useState(null);
  const [refreshedSFTP, setRefreshedSFTP] = useState(false);

  const [gridSorting, setGridSorting] = useState(() => {
    if (userPreferences.pickFileDialogColumnSort) {
      return userPreferences.pickFileDialogColumnSort;
    }
    return defaultSort;
  });

  const parseSFTPList = useCallback(
    async function (configs) {
      const sorting = configs?.sorting ?? defaultSort;
      const selected = configs?.selected ?? selectedObject;
      const gridData = getParsedList({
        sorting,
        selected,
        sftpRootList: configs.sftpList,
      });
      setGridData({
        ...gridData,
      });
      setIsLoading(false);
    },
    [selectedObject]
  );

  useEffect(() => {
    const fetchSFTPRootList = async () => {
      if (refreshedSFTP || !sftpRootList) {
        try {
          const res = await getSFTPlistObject();
          setRefreshedSFTP(false);
          setSftpRootList(res);
        } catch (err) {
          setGeneralError({ message: getErrorMessage(err.code) });
        }
      }
    };
    fetchSFTPRootList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshedSFTP]);

  useEffect(() => {
    setIsLoading(true);
    if (sftpRootList && (gridSorting || selectedObject)) {
      parseSFTPList({ sorting: gridSorting, selected: selectedObject, sftpList: sftpRootList });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parseSFTPList, gridSorting, selectedObject, sftpRootList]);

  const state = {
    isLoading,
    gridData,
    initialOptions,
    selectedObject,
    defaultSort,
    gridSorting,
  };

  const actions = {
    setIsLoading,
    setGridSorting(order) {
      setGridSorting(order);
      setUserPreferences({
        ...userPreferences,
        pickFileDialogColumnSort: order,
      });
    },
    fetchSFTP: parseSFTPList,
    setSelectedObject,
    setRefreshedSFTP,
  };

  return [state, actions];
}
