import { useRef, useState, useCallback } from 'react';
import { deleteLock, setLock, getLock, generateStaticVersion, getStaticVersionStatus } from './../apis';
import { logoutSubscribe } from '../../../_shared/auth';
import { useInterval } from './../../../global-components/Hooks/useInterval';
import { getUser } from '../../../_shared/auth';

const lockValidationError = 'WKP0007';

export default function useLockWorkpaper({
  id,
  isLocked,
  allCommandsProcessedAsync,
  setIsLocked,
  setSpreadAsReadonly,
}) {
  const [delayInterval] = useState(10_000);
  const [isRunningInterval, setIsRunningInterval] = useState(false);
  const visibilityChangeHandler = useRef();
  const renewLockInterval = useRef();
  const logoutUnsubscribe = useRef();

  const releaseLock = useCallback(
    async id => {
      await allCommandsProcessedAsync();
      await deleteLock(id);
      await verifyStaticVersion(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allCommandsProcessedAsync]
  );

  renewLockInterval.current = useInterval(
    async () => {
      renewLock();
    },
    isRunningInterval ? delayInterval : null
  );

  const RegisterLockEvents = () => {
    renewLock();
    logoutUnsubscribe.current = logoutSubscribe(lockCleanup);
    setIsRunningInterval(true);
    visibilityChangeHandler.current = async () => {
      if (document.visibilityState === 'hidden') {
        setIsRunningInterval(false);
        renewLockInterval.current = null;
      } else {
        const lockInfo = await getLock(id);
        const userInfo = getUser();
        if (lockInfo == null) {
          lockCleanup();
          RegisterLockEvents();
        } else if (lockInfo && lockInfo.userId === userInfo.userId) {
          return;
        } else {
          setIsLocked(true);
          setSpreadAsReadonly();
        }
      }
    };
    window.addEventListener('unload', lockCleanup);
    document.addEventListener('visibilitychange', visibilityChangeHandler.current);
  };

  const lockCleanup = useCallback(async () => {
    if (!isLocked) {
      setIsRunningInterval(false);
      document.removeEventListener('visibilitychange', visibilityChangeHandler.current);
      window.removeEventListener('unload', lockCleanup);
      logoutUnsubscribe.current?.unsubscribe();
      renewLockInterval.current = null;
      await releaseLock(id);
    }
  }, [releaseLock, id, isLocked]);

  const renewLock = async () => {
    try {
      await setLock(id);
    } catch ({ code }) {
      if (code === lockValidationError) {
        lockCleanup();
      }
    }
  };

  const verifyStaticVersion = async id => {
    const staticStatus = await getStaticVersionStatus(id);
    if (!staticStatus?.isStatic && !staticStatus?.isInProgress && !staticStatus?.isCellReviewMigrationInProgress) {
      await generateStaticVersion(id);
    }
    if (staticStatus?.isInProgress) {
      window.location.reload();
    }
  };

  return {
    RegisterLockEvents,
    lockCleanup,
    renewLockInterval,
  };
}
