import { BTForm, BTComboBox } from '@btas/jasper';

export default function SheetList({ disabled, loading, isRequired, hasError, sheets, sheetData, onChange }) {
  const fieldValue = sheets?.length === 1 ? sheets[0] : sheets?.find(o => sheetData?.sheetName === o.label);

  return (
    <BTForm.FormGroup
      disabled={disabled}
      errorText={'Sheet is required.'}
      hasError={hasError}
      htmlFor="sheetName"
      label="Select the sheet containing your source data"
      required={isRequired}
    >
      <BTComboBox
        popOutMenu
        defaultValue={sheets ? sheets[0] : null}
        disabled={loading}
        id="sheetName"
        isLoading={loading}
        loadingMessage="Reading sheet names"
        maxMenuHeight={100}
        name="sheetName"
        options={sheets}
        placeholder="Select sheet"
        value={fieldValue}
        onChange={onChange}
      />
    </BTForm.FormGroup>
  );
}
