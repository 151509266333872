import * as wkpFetch from '../../_shared/fetch';
import { workpapersNodeServiceHostName, spreadsheetServiceHostName } from '../../../configs/params';
import { generalErrorMessage } from '../../_shared/messages';
import { getDataFromLocalStorage } from '../../_shared/storage';
import { getKeyData } from './Spreadsheet/_spreadsheets/apis';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { CELL_REVIEW_MIGRATION } from '../../../constants/featureFlags';

let staticGenerationError;
export async function getWorkbook({ id, versionId, statusRetryRef }) {
  if (versionId) {
    try {
      return await fetchPresignedUrl(await getWorkbookModel(id, versionId));
    } catch (error) {
      throwStaticVersionError(error);
    }
  } else {
    if (isFeatureFlagEnabled(CELL_REVIEW_MIGRATION)) {
      const { creatingWorkpaper } = JSON.parse(getDataFromLocalStorage(id) || '{}');
      if (!creatingWorkpaper) {
        const keyValuePairs = await getKeyData({ workpaperId: id });
        const hasCellReviewMigrationRan = keyValuePairs.find(pair => pair.key === 'cellReviewMigrated')?.value;
        if (hasCellReviewMigrationRan !== 'true') {
          try {
            await migrateCellReviews(id);
          } catch (error) {
            if (!(error?.response && error?.response?.status === 504)) {
              console.log('Error migrating cell reviews:', error);
            }
          }
        }
      }
    }
    try {
      const staticStatus = await getStaticVersionStatus(id);
      if (!staticStatus?.isStatic || staticStatus?.isCellReviewMigrationInProgress) {
        let presignedUrl;
        if (
          (!staticStatus?.isInProgress || staticStatus?.staticFailure) &&
          !staticStatus?.isCellReviewMigrationInProgress
        ) {
          presignedUrl = await generateStaticVersion(id);
        } else {
          presignedUrl = await getWorkbookModel(id);
        }
        await retryFetchStaticVersion(id, statusRetryRef);
        return await fetchPresignedUrl(presignedUrl);
      } else {
        return await fetchPresignedUrl(await getWorkbookModel(id));
      }
    } catch (error) {
      throwStaticVersionError(error);
    }
  }
}

const throwStaticVersionError = error => {
  const newError = new Error();
  newError.details = [
    {
      code: 'STATIC_VERSION_ERROR',
      message: generalErrorMessage,
      error: error.message,
      errorDetail: staticGenerationError,
    },
  ];
  throw newError;
};

export async function retryFetchStaticVersion(id, statusRetryRef) {
  let isStatic = false;
  let staticFailure = false;
  let isCellReviewMigrationInProgress = true;
  let retries = 240;

  while ((!isStatic || isCellReviewMigrationInProgress) && --retries >= 0) {
    if (retries === 0) {
      staticGenerationError = 'Maximun retries';
      throw new Error('Static version generation failed. Reload the page');
    }

    if (statusRetryRef && !statusRetryRef.current) {
      staticGenerationError = 'Status request';
      throw new Error('Status request aborted');
    }

    if (staticFailure) {
      staticGenerationError = 'static generation failure';
      throw new Error('Static version generation has failed');
    }

    await new Promise(r => setTimeout(r, 1000));
    const staticStatus = await getStaticVersionStatus(id);
    if (staticStatus) {
      ({ isStatic, staticFailure, isCellReviewMigrationInProgress } = staticStatus);
    }
  }
}

async function getWorkbookModel(id, versionId) {
  const url = `${spreadsheetServiceHostName}/spreadsheet/files/${id}${versionId ? '/version/' + versionId : ''}`;
  const workbookResponse = await wkpFetch.get(url);
  const res = workbookResponse.clone();

  if (!workbookResponse.ok) {
    const errorText = await res.text();
    if (errorText) {
      staticGenerationError = errorText;
      throw Error(staticGenerationError);
    }
  } else {
    const { presignedUrl } = await res.json();
    return presignedUrl;
  }
}

async function fetchPresignedUrl(presignedUrl) {
  let response = await fetch(presignedUrl);
  const res = response.clone();

  if (!response.ok) {
    const errorText = await res.text();
    if (errorText) {
      staticGenerationError = errorText;
      throw Error(staticGenerationError);
    }
  } else {
    const workbook = await res.json();
    return {
      workbook,
      workbookLength: parseInt(res.headers.get('content-length')),
    };
  }
}

export async function getWorkbookMetadata({ id, versionId }) {
  const url = `${spreadsheetServiceHostName}/spreadsheet/metadata/${id}${versionId ? '/' + versionId : ''}`;
  const metadataResponse = await wkpFetch.get(url);
  const res = metadataResponse.clone();

  if (!res.ok) {
    const errorText = await res.text();
    if (errorText) {
      staticGenerationError = errorText;
      throw Error(staticGenerationError);
    }
  } else {
    const { name, taxPeriod, readOnly, isGlobalTemplate } = await res.json();
    return {
      metadata: {
        name,
        taxPeriod,
      },
      readOnly,
      isGlobalTemplate,
    };
  }
}

export async function getLock(workpaperId) {
  const response = await wkpFetch.get(`${spreadsheetServiceHostName}/spreadsheet/workpapers/${workpaperId}/lock`);
  const data = await response.json();

  if (!response.ok) {
    throw data.error;
  }

  return data;
}

export async function setLock(workpaperId) {
  const response = await wkpFetch.post(`${spreadsheetServiceHostName}/spreadsheet/workpapers/${workpaperId}/lock`);
  const data = await response.json();

  if (!response.ok) {
    throw data.error;
  }

  return data;
}

export async function deleteLock(workpaperId) {
  const response = await wkpFetch.remove(`${spreadsheetServiceHostName}/spreadsheet/workpapers/${workpaperId}/lock`);
  const data = await response.json();

  if (!response.ok) {
    throw data.error;
  }

  return data;
}

export async function getTaxProvisionExport(workpaperId) {
  const response = await wkpFetch.get(
    `${workpapersNodeServiceHostName}/api/v1/workpapers/${workpaperId}/tax-provision-export`
  );
  const data = await response.json();

  if (!response.ok) {
    throw Error('Error retrieving the export information.');
  }

  return data;
}

export async function createTaxProvisionExport(workpaperId) {
  const response = await wkpFetch.post(
    `${workpapersNodeServiceHostName}/api/v1/workpapers/${workpaperId}/tax-provision-export`
  );

  if (!response.ok) {
    throw Error('Error when sending file to tax provision.');
  }
}

export async function deleteTaxProvisionExport(workpaperId) {
  const response = await wkpFetch.remove(
    `${workpapersNodeServiceHostName}/api/v1/workpapers/${workpaperId}/tax-provision-export`
  );

  if (!response.ok) {
    throw Error('An error has ocurred');
  }
}

export async function generateStaticVersion(workpaperId) {
  const response = await wkpFetch.get(
    `${spreadsheetServiceHostName}/spreadsheet/static-version/${workpaperId}/generate`
  );
  const res = response.clone();

  if (!response.ok) {
    const errorText = await res.text();
    if (errorText) {
      staticGenerationError = errorText;
      throw Error(staticGenerationError);
    }
  } else {
    const data = await res.json();
    return data.presignedUrl;
  }
}

export async function getStaticVersionStatus(id) {
  const response = await wkpFetch.get(`${spreadsheetServiceHostName}/spreadsheet/static-version/${id}/status`);
  const res = response.clone();

  if (!response.ok) {
    const errorText = await res.text();
    if (errorText) {
      staticGenerationError = errorText;
      throw Error(staticGenerationError);
    }
  } else {
    return await res.json();
  }
}

export async function migrateCellReviews(id) {
  return await wkpFetch.post(`${spreadsheetServiceHostName}/spreadsheet/datareferences/cell-reviews/migrate/${id}`);
}
