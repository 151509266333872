import qs from 'qs';
import * as api from '../../_shared/fetch';
import { dataFlowServiceHostName } from '../../../configs/params';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_INPUT_FILE_IMPORT } from '../../../constants/featureFlags';

export async function getDataFlowSourceFiles(dataFlowId, inputId) {
  const response = await api.get(`${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/${inputId}`);
  return response.json();
}

export async function uploadSourceFile(sourceFile, fileName, setUploadedFilesByInput, setUploadToS3Error) {
  let presignedUrlQuery;
  if (isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT)) {
    presignedUrlQuery = qs.stringify({ fileName });
  } else {
    presignedUrlQuery = qs.stringify({ fileName: sourceFile.name });
  }
  const setUploadedFileError = () => {
    setUploadedFilesByInput({
      status: 'error',
      name,
    });
    setUploadToS3Error(uploadS3Error);
  };
  const presignedUrlResult = await api.get(
    `${dataFlowServiceHostName}/api/v1/data-flows/source-files/presigned-url?${presignedUrlQuery}`
  );
  const presignedUrlData = await presignedUrlResult.json();
  if (!presignedUrlResult.ok) {
    setUploadedFileError();
    throw presignedUrlData.error;
  }
  const { url, key, bucket } = presignedUrlData;

  const name = fileName;
  const location = key;
  const uploadS3Error = 'upload to s3 error';

  if (isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT)) {
    setUploadToS3Error(undefined);
    setUploadedFilesByInput({
      status: 'uploading',
      name,
      location,
    });
    fetch(url, {
      method: 'PUT',
      body: sourceFile,
    })
      .then(result => {
        if (!result.ok) {
          throw new Error();
        }
        setUploadedFilesByInput({
          status: 'completed',
          name,
          location,
        });
      })
      .catch(() => {
        setUploadedFileError();
      });
  } else {
    const uploadResult = await fetch(url, {
      method: 'PUT',
      body: sourceFile,
    });
    if (!uploadResult.ok) {
      throw new Error('Failed to upload source file. Please check your internet connection and try again.');
    }
  }

  return { name: sourceFile.name, path: key, bucket };
}

export async function fetchRetry(cbFunc, delay) {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        resolve(await cbFunc);
      } catch (err) {
        reject(err);
      }
    }, delay);
  });
}

export async function copyObjectBetweenBuckets({ sourceKey, fileName }) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/copy-object`;
  const params = {
    sourceKey,
    fileName,
  };

  const res = await api.post(reqUri, {
    body: JSON.stringify({ params }),
  });

  const data = await res.json();

  if (!res.ok) {
    throw res.error;
  }

  return data;
}
