import React, { useContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { BTAlert } from '@btas/jasper';
import { attachBehavior } from './Spreadsheet/_spreadsheets';
import { addSpreadShortcut } from './Spreadsheet/_spreadsheets/commands/shortcuts/entry';
import {
  dateToString,
  getGCExpression,
  getSourceDataArgumentValues,
  getSourceDataArguments,
  isReferenceType,
} from './Spreadsheet/_spreadsheets/sourceDataFormulaHelper';
import EditorContext from './EditorContext';
import SpreadView from './Spreadsheet/SpreadView';
import SideBar from './Spreadsheet/SideBar';
import WorkpaperCopySheetModal from './Spreadsheet/WorkpaperCopySheetModal';
import WorkpaperSendToTPModal from './Spreadsheet/WorkpaperSendToTPModal';
import WorkpaperSendToTPSuccessModal from './Spreadsheet/WorkpaperSendToTPSuccessModal';
import ReadOnlyVersionBar from './Spreadsheet/ReadOnlyVersionBar';
import WorkpaperLockedBar from './Spreadsheet/WorkpaperLockedBar';
import TaxLawSummary from './Spreadsheet/TaxLawSummary';
import GC from '../../../SpreadSheets';
import { CELL_REVIEW } from '../../_shared/DataReference/ReferenceType';
import { UNMARK_CELL_REVIEWED_COMMAND } from './Spreadsheet/_spreadsheets/commands/cellReview/constants';
import './styles.scss';
import { CELL_REVIEW_ENABLED } from '../../../constants/featureFlags';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { getUser } from '../../_shared/auth';
import GeneralErrorMessage from '../../_shared/GeneralErrorMessage';
import useHistoryTracker from './HistoryTracker/useHistoryTracker';

export default function Spreadsheet({
  handleFileMenuAction,
  navigator,
  tooltipManager,
  userLockFullName,
  versionId,
  workpaperId,
  onNeedsReload,
  cellReviewFunctionManager,
}) {
  const {
    spreadRef,
    setCommandMap,
    setCommandsVisibleContext,
    setHasPendingChanges,
    isLocked,
    isLoading,
    commandFailed,
    maxFails,
    successAlertMessage,
    setSuccessAlertMessage,
    workpaperStatus,
    dataReferenceHistoryTracker,
    isGlobalTemplate,
    taxPeriod,
    workbookName,
  } = useContext(EditorContext);

  const [showWorkpaperCopySheetModal, setShowWorkpaperCopySheetModal] = useState(false);
  const [cellDataSourceConnection, setCellDataSourceConnection] = useState();
  const [showWorkpaperSendToTPModal, setShowWorkpaperSendToTPModal] = useState(false);
  const [showWorkpaperSendToTSuccessModal, setShowWorkpaperSendToTSuccessModal] = useState(false);
  const { addToHistory, historyTracker, undoAction } = useHistoryTracker(cellReviewFunctionManager, workpaperId);
  dataReferenceHistoryTracker.current = { addToHistory, historyTracker, undoAction };
  const onCellSelection = () => {
    const spread = spreadRef.current;
    const sheet = spread.getActiveSheet();
    const row = sheet.getActiveRowIndex();
    const col = sheet.getActiveColumnIndex();
    const cell = sheet.getCell(row, col);
    const formula = cell.formula();
    const argumentValues = getSourceDataArgumentValues(sheet, formula);

    handleContextMenuOptions(spread);

    if (argumentValues) {
      const [dataFlowOutputElementId, outputField, ...filters] = argumentValues;
      const [, , ...notEvaluatedFilters] = getSourceDataArguments(sheet, formula);

      const selectedFilters = {};
      for (let i = 0; i < filters.length; i = i + 2) {
        const filterValue = filters[i + 1] instanceof Date ? dateToString(filters[i + 1]) : filters[i + 1];
        const notEvaluatedFilter = notEvaluatedFilters[i + 1];
        const reference = isReferenceType(notEvaluatedFilter.type) ? notEvaluatedFilter.value : undefined;
        selectedFilters[filters[i]] = {
          labelReference: isReferenceType(notEvaluatedFilters[i].type) ? notEvaluatedFilters[i].value : undefined,
          cell: { value: filterValue, reference },
        };
      }
      const sdcFormulaWrapper = getGCExpression(sheet, formula);

      setCellDataSourceConnection({
        dataFlowOutputElementId,
        outputField,
        selectedFilters,
        sdcFormulaWrapper,
      });
    } else {
      setCellDataSourceConnection(null);
    }
  };

  const handleContextMenuOptions = spread => {
    // Clean up
    spread.contextMenu.menuData = spread.contextMenu.menuData.filter(
      ({ command, name, text }) => command && name && text
    );

    if (isFeatureFlagEnabled(CELL_REVIEW_ENABLED)) {
      handleUnmarkReviewMenuOption(spread);
    }
    // Other conditional menu options
  };

  const handleUnmarkReviewMenuOption = spread => {
    const sheet = spread.getActiveSheet();
    const selections = sheet.getSelections();
    const user = getUser();
    const { dataReferences } = cellReviewFunctionManager.current;
    let selectionContainsUserCellReviews = false;

    selections.forEach(selected => {
      for (let rowX = 0; rowX < selected.rowCount; rowX++) {
        for (let colY = 0; colY < selected.colCount; colY++) {
          const row = selected.row + rowX;
          const column = selected.col + colY;

          const cellReviewRefs = dataReferences.current.filter(
            dRef =>
              dRef.type === CELL_REVIEW && dRef.row === row && dRef.column === column && dRef.sheetName === sheet.name()
          );

          // eslint-disable-next-line no-loop-func
          cellReviewRefs?.forEach(ref => {
            const parameters = JSON.parse(ref.parameters);
            if (parameters.ReviewerUserId === user.userId) {
              selectionContainsUserCellReviews = true;
            }
          });
        }
      }
    });

    selectionContainsUserCellReviews
      ? spread.contextMenu.menuData.push({
          text: 'Unmark reviewed',
          name: UNMARK_CELL_REVIEWED_COMMAND,
          command: UNMARK_CELL_REVIEWED_COMMAND,
        })
      : (spread.contextMenu.menuData = spread.contextMenu.menuData.filter(
          ({ command }) => command !== UNMARK_CELL_REVIEWED_COMMAND
        ));
  };

  useEffect(() => {
    const spread = spreadRef.current;
    spread.bind(GC.Spread.Sheets.Events.SelectionChanged, onCellSelection);
    spread.bind(GC.Spread.Sheets.Events.ActiveSheetChanged, onCellSelection);

    return () => {
      spread.unbind(GC.Spread.Sheets.Events.SelectionChanged, onCellSelection);
      spread.unbind(GC.Spread.Sheets.Events.ActiveSheetChanged, onCellSelection);
      tooltipManager.clean();
    };
    // Removing lint checks for dependencies because there is no need to re-execute
    // when spreadRef changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReadOnlyMode = Boolean(versionId);
  const [readOnlyModeDisplayName, setReadOnlyModeDisplayName] = useState('');

  const handleWorkbookInit = ({ Spread: spread, _config }) => {
    spreadRef.current = spread;
    addSpreadShortcut(spread, { undoAction });
    setCommandMap(_config.commandMap);

    attachBehavior({
      spread,
      workpaperId,
      tooltipManager,
      linkingFormulaReadOnly: versionId,
      navigator,
      setCommandsVisibleContext,
    });
  };

  const handleWorkpaperCopySheetModalOpen = () => setShowWorkpaperCopySheetModal(true);
  const handleWorkpaperCopySheetModalClose = () => setShowWorkpaperCopySheetModal(false);
  const handleSuccessAlertClose = () => setSuccessAlertMessage(null);
  const handleWorkpaperSendToTPModalOpen = () => setShowWorkpaperSendToTPModal(true);
  const handleWorkpaperSendToTPModalClose = () => setShowWorkpaperSendToTPModal(false);
  const handleWorkpaperSendToTPSuccessModalOpen = () => setShowWorkpaperSendToTSuccessModal(true);
  const handleWorkpaperSendToTPSuccessModalClose = () => setShowWorkpaperSendToTSuccessModal(false);

  const AlertTypes = {
    COPY_SHEET: RenderCopySheetAlert,
    // Add other complex alert types and their rendering functions here
  };

  function RenderCopySheetAlert({ alertData }) {
    const handleClick = () => {
      const newTabUrl = window.location.origin + `/editor/${alertData.workpaperId}`;

      setSuccessAlertMessage(null);
      window.open(newTabUrl, '_blank');
    };

    return (
      <>
        The selected worksheets have been successfully copied to workpaper{' '}
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a style={{ cursor: 'pointer' }} onClick={handleClick}>
          {alertData.workpaperName}
        </a>{' '}
        {alertData.workpaperTaxPeriod && `for tax period ${alertData.workpaperTaxPeriod}`}
      </>
    );
  }

  function RenderAlert({ alertType, alertData }) {
    if (typeof alertData === 'string') {
      return alertData;
    }
    const Renderer = AlertTypes[alertType];
    return <Renderer alertData={alertData} />;
  }

  return maxFails ? (
    <Redirect to={'/'} />
  ) : (
    <div className="wkp-spreadsheet-container">
      {commandFailed && <GeneralErrorMessage dismissible={false} visible={commandFailed} />}

      <BTAlert
        appear
        dismissible
        fixed
        btStyle="success"
        visible={!!successAlertMessage}
        onDismiss={handleSuccessAlertClose}
      >
        {successAlertMessage && <RenderAlert alertData={successAlertMessage} alertType={successAlertMessage?.type} />}
      </BTAlert>

      {isGlobalTemplate && (
        <BTAlert appear dismissible fixed btStyle="warning" visible={true}>
          This workpaper has been marked as a global template and is locked as a read-only. To unlock this workpaper,
          open the <strong>workpaper settings panel</strong> and <strong>Unmark as Global Template</strong>.
        </BTAlert>
      )}

      {onReadOnlyMode && <ReadOnlyVersionBar displayName={readOnlyModeDisplayName} workpaperId={workpaperId} />}

      {isLocked && !onReadOnlyMode && (
        <WorkpaperLockedBar
          userName={userLockFullName}
          workpaperStatus={workpaperStatus}
          onNeedsReload={onNeedsReload}
        />
      )}

      <SpreadView
        key={`${workpaperId}${versionId}`}
        addToHistory={addToHistory}
        cellReviewFunctionManager={cellReviewFunctionManager}
        handleFileMenuAction={handleFileMenuAction}
        isLoading={isLoading}
        readOnly={onReadOnlyMode || isLocked || isGlobalTemplate}
        setCommandsVisibleContext={setCommandsVisibleContext}
        setHasPendingChanges={setHasPendingChanges}
        versionId={versionId}
        workpaperId={workpaperId}
        onWorkbookInit={handleWorkbookInit}
        onWorkpaperCopySheetModalOpen={handleWorkpaperCopySheetModalOpen}
        onWorkpaperSendToTPModalOpen={handleWorkpaperSendToTPModalOpen}
      />
      <SideBar
        cellDataSourceConnection={cellDataSourceConnection}
        inHistoryViewMode={onReadOnlyMode}
        isLocked={isLocked}
        setReadOnlyModeDisplayName={setReadOnlyModeDisplayName}
        workpaperId={workpaperId}
        onCellSelection={onCellSelection}
      />

      <TaxLawSummary />

      {showWorkpaperCopySheetModal && (
        <WorkpaperCopySheetModal
          sheetToCopy={spreadRef.current.getActiveSheet().name()}
          show={showWorkpaperCopySheetModal}
          sourceWorkpaperId={workpaperId}
          onClose={handleWorkpaperCopySheetModalClose}
          onNeedsReload={onNeedsReload}
        />
      )}

      {showWorkpaperSendToTPModal && (
        <WorkpaperSendToTPModal
          closeSuccessModal={handleWorkpaperSendToTPSuccessModalClose}
          show={handleWorkpaperSendToTPModalOpen}
          showSuccessModal={handleWorkpaperSendToTPSuccessModalOpen}
          sourceTaxPeriod={taxPeriod}
          sourceWorkpaperId={workpaperId}
          sourceWorkpaperName={workbookName}
          onClose={handleWorkpaperSendToTPModalClose}
        />
      )}
      {showWorkpaperSendToTSuccessModal && (
        <WorkpaperSendToTPSuccessModal
          show={handleWorkpaperSendToTPSuccessModalOpen}
          onClose={handleWorkpaperSendToTPSuccessModalClose}
        />
      )}
    </div>
  );
}
