import { BTAlert, BTButton, BTComboBox, BTForm, BTModal } from '@btas/jasper';
import ValidationErrors from '../../../_shared/ValidationError';
import './TaxPeriodSelection/styles.scss';

const TaxPeriodSelection = ({
  generalError,
  infoMessage,
  newTaxPeriod,
  taxPeriod,
  taxPeriodError,
  taxPeriodOptions,
  onClose,
  onNextClick,
  onTaxPeriodChange,
}) => {
  return (
    <BTModal
      className="rollforward-modal"
      id="create-modal-container"
      show={true}
      size="modal-lg"
      title="Rollforward Workpaper"
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <div className="body-modal-height">
          <p>Provide the tax period for the new workpaper that will be created on rollforward.</p>
          <div className="dialog-subtitle">Tax period of selected workpaper</div>
          <p className="current-tax-period">{taxPeriod !== 'unassigned' ? taxPeriod : '[ Unassigned ]'}</p>

          <div className="new-tax-period">
            <BTForm.FormGroup
              required
              data-testid="rollforward-new-tax-period"
              errorText={taxPeriodError}
              hasError={!!taxPeriodError}
              htmlFor="newTaxPeriod"
              label="Tax period of new workpaper"
            >
              <BTComboBox
                canCreateNew
                isClearable
                popOutMenu
                createText="Add"
                id="newTaxPeriod"
                isSearchable={true}
                maxMenuHeight={100}
                noResultsMessage="No tax periods created. Start typing to add a tax period."
                options={taxPeriodOptions}
                placeholder='This can be any label you like,  e.g. "TY2020", "Provision 2021 Q2", etc.'
                value={newTaxPeriod}
                onChange={onTaxPeriodChange}
              />
            </BTForm.FormGroup>
          </div>
        </div>

        {generalError && (
          <BTAlert btStyle="danger" visible={!!generalError}>
            <ValidationErrors error={generalError} />
          </BTAlert>
        )}

        {infoMessage && (
          <BTAlert btStyle="info" visible={!!infoMessage}>
            <ValidationErrors error={infoMessage} />
          </BTAlert>
        )}
      </BTModal.Body>

      <BTModal.Footer>
        <BTButton id="cancel" onClick={onClose}>
          CANCEL
        </BTButton>

        <BTButton btStyle="primary" hasSpinner={false} id="next" onClick={onNextClick}>
          NEXT
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
};

export default TaxPeriodSelection;
