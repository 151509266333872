import { LogLevel, faro } from '@grafana/faro-web-sdk';
import { getUser } from '../auth';
import { EnviromentName } from '../../../configs/params';
export default class CustomLogger {
  static operations = {
    UPLOAD: 'upload-workpaper',
    OPEN: 'open-workpaper',
    DELETE: 'delete-workpaper',
    EXPORT: 'export-workpaper',
    COPY: {
      WORKPAPER: 'copy-workpaper',
      SHEET: 'copy-worksheet',
    },
    PROCESS_COMMAND: 'process-command',
    NAVIGATE: 'navigate',
    STATIC_VERSION_GENERATION: 'static-version-generation',
    RESTORE: 'restore-workpaper',
    BLOCK_TO_PREVIEW: 'block-to-preview',
    DELETE_WORKFLOW: 'delete_workflow',
    RETRIEVE_USER_PERMISSIONS: 'retrieve-user-permissions',
    GENERAL: 'general',
    RECOVER: 'recover-workpaper',
  };

  static durations = Object.create(null);

  static start(log) {
    CustomLogger.durations[log] = {
      duration: Date.now(),
    };
  }

  static addDurationPayload(log, payload = {}) {
    if (CustomLogger.durations[log]) {
      CustomLogger.durations[log] = {
        ...CustomLogger.durations[log],
        ...payload,
      };
    }
  }

  static end(log) {
    if (!CustomLogger.durations[log]) {
      return;
    }

    const user = getUser();

    faro?.api?.pushLog([log], {
      context: {
        ...CustomLogger.durations[log],
        userId: user?.userId,
        companyId: user?.companyId,
        duration: `${Date.now() - CustomLogger.durations[log].duration}`,
      },
      level: LogLevel.DEBUG,
    });

    delete CustomLogger.durations[log];
  }

  static pushLog(log, payload) {
    const user = getUser();

    if (EnviromentName && EnviromentName === 'LOCAL') {
      if (typeof payload === 'string') {
        console.log(payload);
      } else {
        console.log(JSON.stringify(payload));
      }
    }

    faro?.api?.pushLog([log], {
      context: {
        userId: user?.userId,
        companyId: user?.companyId,
        ...payload,
      },
      level: payload?.error ? LogLevel.ERROR : LogLevel.DEBUG,
    });
  }
}
