/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import AiLabsIcon from './TaxResearchAi/assets/AI_Lab_Blue_Text.svg';
import ToggleIcon from './TaxResearchAi/assets/Toggle_Off.svg';
import './TaxResearchAi/styles.scss';
import UserPreferencesContext from '../UserPreferencesContext';
import TaxResearchModal from './TaxResearchAi/TaxResearchModal';
import { getAiSummary } from './TaxResearchAi/api';
import DisclaimerModal from './TaxResearchAi/DisclaimerModal';
import AiSubscriptionModal from './TaxResearchAi/AiSubscriptionModal';
import { getAiSubscription } from '../auth';

const TaxResearchAi = () => {
  const { userPreferences, setUserPreferences } = useContext(UserPreferencesContext);
  const { hasAiEnabled: aiEnabled } = userPreferences; // This is the user preference that persists across sessions
  const [showModal, setShowModal] = useState(false);
  const [isSubscribed, setIsSubScribed] = useState(getAiSubscription());

  useEffect(() => {
    const storageListener = () => {
      const newData = getAiSubscription();
      setIsSubScribed(newData);
    };
    window.addEventListener('storage', storageListener);

    return () => {
      window.removeEventListener('storage', storageListener);
    };
  }, []);

  // Display the read only disclaimer when user clicks the info icon
  const [readOnlyDisclaimer, setReadOnlyDisclaimer] = useState(false);

  // search input state
  const [error, setError] = useState();
  const [inputValue, setInputValue] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [aiSummary, setAiSummary] = useState();

  // Decides if the disclaimer modal should be a read-only or with action buttons
  const shouldRenderDisclaimerModal =
    (readOnlyDisclaimer && !showModal && aiEnabled) || (!readOnlyDisclaimer && showModal && !aiEnabled);

  // Click events
  const handleSubmit = async e => {
    e.preventDefault();

    setError(false);
    if (!inputValue) return clearAll();

    try {
      setIsSaving(true);
      const data = await getAiSummary(inputValue);

      setAiSummary(data);
      if (!data.summary) {
        setError('info');
      }
    } catch (error) {
      setAiSummary();
      setError('warning');
    } finally {
      setIsSaving(false);
    }
  };

  const toggleAiOff = () => {
    clearAll();
    toggleModal();
    setUserPreferences({
      ...userPreferences,
      hasAiEnabled: false,
    });
  };

  const acceptDisclaimer = () => {
    setUserPreferences({
      ...userPreferences,
      hasAiEnabled: true,
    });
  };

  const clearAll = () => {
    setInputValue('');
    setAiSummary();
  };

  const toggleModal = () => {
    setShowModal(state => !state);
  };

  return (
    <>
      <li className={`${aiEnabled && showModal && 'modal-active'} navbar-tax-research`} onClick={toggleModal}>
        <a id="research-ai-btn">
          {isSubscribed !== null &&
            (!isSubscribed ? (
              <button className="upgrade-button">UPGRADE</button>
            ) : (
              !aiEnabled && <img alt="Toggle off icon" className="navbar-button-icon toggle-button" src={ToggleIcon} />
            ))}
          <img alt="Tax Research Icon" className="navbar-button-icon" src={AiLabsIcon} />
          <span className="navbar-button-text">Ask Research</span>
        </a>
        {showModal && aiEnabled && isSubscribed && (
          <TaxResearchModal
            aiSummary={aiSummary}
            error={error}
            handleSubmit={handleSubmit}
            inputValue={inputValue}
            isSaving={isSaving}
            setInputValue={setInputValue}
            setReadOnlyDisclaimer={setReadOnlyDisclaimer}
            toggleAiOff={toggleAiOff}
            toggleModal={toggleModal}
          />
        )}
      </li>

      {/* No subscription Modal */}
      {shouldRenderDisclaimerModal && !isSubscribed && <AiSubscriptionModal toggleModal={toggleModal} />}

      {/* T&C Disclaimer */}
      {shouldRenderDisclaimerModal && isSubscribed && (
        <DisclaimerModal
          acceptDisclaimer={acceptDisclaimer}
          readOnly={readOnlyDisclaimer}
          setReadOnlyDisclaimer={setReadOnlyDisclaimer}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
};

export default TaxResearchAi;
