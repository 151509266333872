import ReorderElementInspector from '../../ReorderElementInspector';
import { REORDER_COLOR } from '../../shared/colors';
import { TransformationElementType } from '../TransformationElementType';
import iconImage from '../icons/Reorder_icon.svg';
import hintImage from '../icons/Reorder_hint_img.svg';
import { REORDER } from './shared/typesConstants';

export class ReorderElementType extends TransformationElementType {
  static TYPE = REORDER;

  static HELP_TEXT = `The reorder block allows you to order fields based on your preference.<img src=${hintImage} alt="Reorder hint"/>`;
  constructor() {
    super(ReorderElementType.TYPE, 'Reorder', REORDER_COLOR, iconImage, ReorderElementType.HELP_TEXT);
  }
  get initialData() {
    return {
      name: this.label,
      type: this.type,
      fields: [],
      newFieldOrder: [],
    };
  }
  get maxCount() {
    return -1;
  }
  get inspectorComponent() {
    return ReorderElementInspector;
  }

  verifyFieldNameMatch(reorderFields, sourceElementFields) {
    let updateReorder = [];

    const unknownFields = sourceElementFields.filter(function (field) {
      const fieldName = field?.original_name ? field?.original_name : field?.name;
      const fieldExist = reorderFields.find(reorderField =>
        reorderField?.original_name ? reorderField?.original_name === fieldName : reorderField?.name === fieldName
      );

      return !fieldExist ? true : false;
    });

    if (unknownFields.length > 0 || sourceElementFields.length !== reorderFields.length) {
      const check = reorderFields.filter(function (field) {
        const fieldName = field?.original_name ? field?.original_name : field?.name;
        const fieldExist = sourceElementFields.find(sourceField =>
          sourceField?.original_name ? sourceField?.original_name === fieldName : sourceField?.name === fieldName
        );
        return fieldExist ? true : false;
      });
      updateReorder = check.concat(unknownFields);
    }
    return updateReorder.length > 0 ? updateReorder : reorderFields;
  }

  applySourceElements(elementData, sourceElements) {
    const sourceDataFields = sourceElements['in']?.elementData?.fields ?? [];
    const newFieldOrder =
      elementData?.newFieldOrder.length > 0
        ? this.verifyFieldNameMatch(elementData?.newFieldOrder, sourceDataFields)
        : [];
    const fields = newFieldOrder?.length > 0 ? newFieldOrder : sourceDataFields;

    return {
      ...elementData,
      fields: fields,
      newFieldOrder: newFieldOrder,
      sourceDataFields: sourceDataFields,
    };
  }
  extractTypeData(elementData) {
    return {
      ...super.extractTypeData(elementData),
      newFieldOrder: elementData?.newFieldOrder,
      sourceDataFields: elementData?.sourceDataFields,
    };
  }
}
