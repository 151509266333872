import { setValueOnServer, setValueAndTagOnServer } from '../useWorkpaper/processCommand';
import {
  SOURCE_DATA_CONNECTION,
  INT_TAX_RATE,
  STATE_TAX_RATE,
  STATE_APPORTION,
  CELL_REVIEW,
} from '../../../_shared/DataReference/ReferenceType';
import GC from '../../../../SpreadSheets';
import { momentDateFormats } from '../Spreadsheet/_spreadsheets/utils';
const moment = require('moment');

export function getCellTag(spreadsheet, row, column) {
  const tag = spreadsheet.getTag(row, column, 3);
  if (tag) {
    const escapedString = tag.replaceAll('\\', '');
    return JSON.parse(escapedString);
  }

  return null;
}

export function setCellTag(spreadsheet, row, column, data) {
  spreadsheet.setTag(row, column, JSON.stringify(data));
}

export function isEmptyObject(obj) {
  try {
    if (typeof obj === 'string') {
      obj = JSON.parse(obj);
    }
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  } catch {
    return false;
  }
}

export async function generateOutputRequest(dataReferenceQueue, sheetName) {
  const sourceConnections = dataReferenceQueue.current.filter(reference => reference.type === SOURCE_DATA_CONNECTION);
  const intTaxRateConnections = dataReferenceQueue.current.filter(reference => reference.type === INT_TAX_RATE);
  const stateTaxRateConnections = dataReferenceQueue.current.filter(reference => reference.type === STATE_TAX_RATE);
  const stateApportionConnections = dataReferenceQueue.current.filter(reference => reference.type === STATE_APPORTION);

  let outPutRequest = [];

  if (sourceConnections.length) {
    const sourceConnectionRequest = sourceConnections.map(
      ({ row, column, output, outputField, filters, type, id }) => ({
        key: JSON.stringify({ row, column, sheetName, type, id }),
        outputId: output,
        fieldToSum: outputField,
        criteria: filters,
      })
    );
    outPutRequest = [...outPutRequest, ...sourceConnectionRequest];
  }

  if (intTaxRateConnections.length) {
    const intTaxRateRequest = intTaxRateConnections.map(
      ({ row, column, country, periodStartDate, taxableIncome, id, type }) => ({
        key: JSON.stringify({ row, column, sheetName, type, id }),
        country,
        periodStartDate,
        taxableIncome,
      })
    );
    outPutRequest = [...outPutRequest, ...intTaxRateRequest];
  }

  if (stateTaxRateConnections.length) {
    const stateTaxRateRequest = stateTaxRateConnections.map(
      ({ row, column, jurisdiction, periodStartDate, taxableIncome, id, type }) => ({
        key: JSON.stringify({ row, column, sheetName, type, id }),
        jurisdiction,
        periodStartDate,
        taxableIncome,
      })
    );
    outPutRequest = [...outPutRequest, ...stateTaxRateRequest];
  }

  if (stateApportionConnections.length) {
    const stateApportionRequest = stateApportionConnections.map(({ row, column, state, date, factor, id, type }) => ({
      key: JSON.stringify({ row, column, sheetName, type, id }),
      state,
      date,
      type: factor,
    }));
    outPutRequest = [...outPutRequest, ...stateApportionRequest];
  }

  return outPutRequest;
}

export function formulaMatch(dataReferences, targetParameter) {
  const reference = dataReferences.current.find(x => x.id === targetParameter.id);
  if (!reference) {
    return false;
  }
  if (reference && targetParameter.type === SOURCE_DATA_CONNECTION) {
    const {
      parameters: { OutputId, OutputField, Filters },
    } = targetParameter;

    const referenceParameter = JSON.parse(reference.parameters);
    if (
      OutputId !== referenceParameter.OutputId ||
      OutputField !== referenceParameter.OutputField ||
      Filters !== referenceParameter.Filters
    ) {
      return false;
    }
  }

  if (targetParameter.type === INT_TAX_RATE) {
    const {
      parameters: { Country, PeriodStartDate, TaxableIncome },
    } = targetParameter;

    const referenceParameter = JSON.parse(reference.parameters);
    if (
      Country !== referenceParameter.Country ||
      PeriodStartDate !== referenceParameter.PeriodStartDate ||
      TaxableIncome !== referenceParameter.TaxableIncome
    ) {
      return false;
    }
  }

  if (targetParameter.type === STATE_TAX_RATE) {
    const {
      parameters: { Jurisdiction, PeriodStartDate, TaxableIncome },
    } = targetParameter;

    const referenceParameter = JSON.parse(reference.parameters);
    if (
      Jurisdiction !== referenceParameter.Jurisdiction ||
      PeriodStartDate !== referenceParameter.PeriodStartDate ||
      TaxableIncome !== referenceParameter.TaxableIncome
    ) {
      return false;
    }
  }

  if (targetParameter.type === STATE_APPORTION) {
    const {
      parameters: { State, Date, Type },
    } = targetParameter;
    const referenceParameter = JSON.parse(reference.parameters);
    const targetDate = moment(Date.toString()).format('MM/DD/YYYY');
    const parameterDate = moment(referenceParameter.Date.toString()).format('MM/DD/YYYY');
    if (State !== referenceParameter.State || targetDate !== parameterDate || Type !== referenceParameter.Type) {
      return false;
    }
  }

  return true;
}

export async function generateCellMetadata(dataReferenceQueue, dataReferenceValues) {
  return dataReferenceQueue.current.map(({ id, row, column, parameters, type, sheetName }) => {
    const resolvedValue = dataReferenceValues.current.find(x => {
      if (x.key) {
        const key = JSON.parse(x.key);
        return row === key.row && column === key.column && sheetName === key.sheetName;
      }
      return null;
    });
    return {
      referenceId: id,
      row,
      column,
      parameters: JSON.stringify(parameters),
      referenceType: type,
      sheetName,
      oldValue: resolvedValue?.value,
      newValue: resolvedValue?.value,
    };
  });
}

export function returnReferenceValue(reference) {
  if (reference) {
    if (reference.value === undefined) {
      return undefined;
    } else if (reference.value === '') {
      return GC.Spread.CalcEngine.Errors.NotAvailable;
    } else {
      return reference.value;
    }
  }
  return reference;
}

export function returnRecalcReferenceValue(value) {
  return value === GC.Spread.CalcEngine.Errors.NotAvailable ? '' : value;
}

export function resolveNewReferenceValue(sourceReferenceQueue, row, column, sheet) {
  if (sourceReferenceQueue.current && sourceReferenceQueue.current.length > 0) {
    const reference = sourceReferenceQueue.current.find(
      x => JSON.parse(x.key).row === row && JSON.parse(x.key).column === column && JSON.parse(x.key).sheetName === sheet
    );
    return returnReferenceValue(reference);
  }
}

export function resolveExistingReferenceValue(sourceReferenceQueue, cellTag, referenceType, sheet) {
  if (sourceReferenceQueue.current && sourceReferenceQueue.current.length > 0) {
    if (cellTag.references.length > 0) {
      const cellFormulaReference = cellTag.references.find(x => x.type === referenceType);
      const reference = sourceReferenceQueue.current.find(
        x => x.id === cellFormulaReference.id && x.sheetName === sheet
      );
      return returnReferenceValue(reference);
    }
  }
  return '';
}

export function createTempInMemoryDataReferences(
  spreadsheet,
  dataReferences,
  dataReferenceQueue,
  isUndoDeleteCommandAction = false
) {
  if (!isUndoDeleteCommandAction) {
    const newReferences = [];
    const existingReferences = [];
    dataReferenceQueue.current.forEach(key => {
      const reference = dataReferences.current.find(x => x.id === key.id);
      if (reference) {
        reference.value = spreadsheet?.getValue(reference.row, reference.column);
        existingReferences.push(reference);
      } else {
        newReferences.push({
          id: key.id,
          row: key.row,
          column: key.column,
          parameters: JSON.stringify(key.parameters),
          type: key.type,
          sheetName: key.sheetName,
          value:
            key.type === CELL_REVIEW && spreadsheet && reference
              ? cleanDirtyCellValue(spreadsheet?.getCell(reference.row, reference.column)?.text())
              : undefined,
        });
      }
    });
    dataReferences.current = [...dataReferences.current, ...newReferences];
  }
}

export async function updateInMemoryDataReferences(dataReferences, dataReferenceQueue, dataReferenceValues) {
  const newReferences = [];
  const existingReferences = [];
  dataReferenceValues.current.forEach(x => {
    const key = JSON.parse(x.key);
    const referenceExist = dataReferences.current.find(
      x => x.row === key.row && x.column === key.column && x.sheetName === key.sheetName
    );
    const reference = {
      id: key.id,
      row: key.row,
      column: key.column,
      parameters: JSON.stringify(
        dataReferenceQueue.current.find(
          x => x.row === key.row && x.column === key.column && x.sheetName === key.sheetName
        )?.parameters
      ),
      type: key.type,
      sheetName: key.sheetName,
      value: x.value,
    };
    if (!referenceExist) {
      newReferences.push(reference);
    } else {
      existingReferences.push(reference);
    }
  });
  const updatedReferences = dataReferences.current.map(reference => {
    const valueExist = dataReferenceValues.current.find(
      x =>
        JSON.parse(x.key).row === reference.row &&
        JSON.parse(x.key).column === reference.column &&
        JSON.parse(x.key).sheetName === reference.sheetName
    );
    if (valueExist) {
      return { ...reference, value: valueExist.value };
    }
    return reference;
  });
  dataReferences.current = [...updatedReferences, ...newReferences];
}

export async function updateInMemoryDataReferencesQueue(dataReferences, sourceReferenceQueue, dataReferenceValues) {
  sourceReferenceQueue.current = sourceReferenceQueue.current.map(referenceRecalc => {
    const resolvedValue = dataReferenceValues.current.find(x => {
      const { row, column, sheetName } = JSON.parse(x.key);
      return (
        row === referenceRecalc.row && column === referenceRecalc.column && sheetName === referenceRecalc.sheetName
      );
    });

    dataReferences.current = dataReferences.current.map(reference => {
      const { row, column, type, sheetName } = reference;

      if (
        row === referenceRecalc.row &&
        column === referenceRecalc.column &&
        type === referenceRecalc.type &&
        sheetName === referenceRecalc.sheetName
      ) {
        return {
          ...reference,
          value: resolvedValue ? returnRecalcReferenceValue(resolvedValue.value) : undefined,
          parameters: JSON.stringify(referenceRecalc.parameters),
        };
      } else {
        return reference;
      }
    });
    return { ...referenceRecalc, value: resolvedValue ? returnRecalcReferenceValue(resolvedValue.value) : undefined };
  });
}

export async function updateDataReferenceQueue(spreadsheet, dataReferenceQueue, dataReferenceValues) {
  let references = dataReferenceQueue.current;
  let values = dataReferenceValues.current;
  if (references.length && values.length) {
    values.forEach(resolvedValue => {
      if (resolvedValue?.value !== undefined && resolvedValue?.value !== null) {
        const resolvedValueKey = JSON.parse(resolvedValue.key);
        let cellValue = spreadsheet.getValue(resolvedValueKey.row, resolvedValueKey.column)?.toString();
        if (cellValue === GC.Spread.CalcEngine.Errors.NotAvailable) {
          cellValue = '';
        }
        references = references.filter(({ row, column, sheetName }) => {
          return !(
            resolvedValueKey.row === row &&
            resolvedValueKey.column === column &&
            resolvedValueKey.sheetName === sheetName
          );
        });
        if (cellValue === resolvedValue.value) {
          values = values.filter(value => {
            const valuesKey = JSON.parse(value.key);
            return !(
              resolvedValueKey.row === valuesKey.row &&
              resolvedValueKey.column === valuesKey.column &&
              resolvedValueKey.sheetName === valuesKey.sheetName
            );
          });
        }
      }
    });
    dataReferenceQueue.current = references;
    dataReferenceValues.current = values;
  }
}

export async function setDataReferenceCellTags(spreadsheet, dataReferences) {
  const tagsToUpdate = [];
  if (spreadsheet && dataReferences && dataReferences.length > 0) {
    const sheetReferences = dataReferences.filter(reference => reference.sheetName === spreadsheet.name());

    if (sheetReferences && sheetReferences.length > 0) {
      sheetReferences.forEach(reference => {
        const { id, type, row, column } = reference;
        let { value } = reference;
        const cellTag = getCellTag(spreadsheet, row, column);
        if (!value) value = spreadsheet.getValue(row, column);
        if (!cellTag?.references) {
          const cellTagObject = { references: [{ id, type }], value };
          setCellTag(spreadsheet, row, column, cellTagObject);
          tagsToUpdate.push(reference);
        } else {
          if (type !== CELL_REVIEW) {
            cellTag.references.forEach(cellReference => {
              if (cellReference.type === type && (cellReference.id !== id || reference.value !== cellTag.value)) {
                cellReference.id = id;
                cellTag.value = reference.value;
                tagsToUpdate.push(reference);
              }
              return cellReference;
            });

            if (!cellTag.references.some(ref => ref.type === type && ref.id === id)) {
              cellTag.references.push({ id, type });
            }
          } else {
            const reviewReferences = sheetReferences?.filter(
              x => x.row === row && x.column === column && x.type === CELL_REVIEW
            );
            reviewReferences.forEach(review => {
              const reviewTagExist = cellTag.references.some(x => x.id === review.id);
              if (!reviewTagExist) {
                cellTag.references.push({ id: review.id, type: review.type });
              }
            });
          }

          setCellTag(spreadsheet, row, column, cellTag);
        }
      });
    }
    return tagsToUpdate;
  }
}

export async function renderResolvedValues(spreadsheet, dataReferenceValues) {
  spreadsheet.suspendPaint();
  spreadsheet.suspendCalcService();
  dataReferenceValues.current.forEach(resolvedValue => {
    if (resolvedValue.key) {
      const key = JSON.parse(resolvedValue.key);
      const { row, column } = key;
      resolvedValue['previousValue'] = spreadsheet.getValue(row, column);
      spreadsheet.recalcRange(row, column, 1, 1);
    }
  });
  spreadsheet.resumeCalcService(false);
  spreadsheet.resumePaint();
}

function isValidDate(dateString) {
  for (let index = 0; index < momentDateFormats.length; index++) {
    const dateFormat = momentDateFormats[index];
    const parsedDate = moment(dateString, dateFormat.toString(), true);
    if (parsedDate.isValid()) {
      return true;
    }
  }
  return false;
}

function timeZoneInstance(dateString) {
  const charactersAfterTime = dateString.toString().match(/(\d{2}:\d{2}:\d{2})\s(.*)/);
  if (charactersAfterTime) {
    return charactersAfterTime[2];
  }
  return undefined;
}
export function cleanDirtyCellValue(value, isApiTrigger = false) {
  const isPercentage = /^\d+(\.\d+)?%$/.test(value);
  if ((typeof value === 'string' || value instanceof String || value instanceof Date) && !isPercentage) {
    if (!isNaN(Date.parse(value))) {
      const timeZone = timeZoneInstance(value);
      const dateWithoutTimeZone = (timeZone ? value.toString().replace(timeZone, '') : value).trim();
      const isValidDateValue = isValidDate(dateWithoutTimeZone);
      if (isValidDateValue) {
        if (isApiTrigger) {
          return moment(new Date(dateWithoutTimeZone).toLocaleDateString()).format('MM/DD/YYYY');
        }
        return new Date(dateWithoutTimeZone).toLocaleDateString();
      }
    }
  }
  return value;
}

export function generateEditCellSheetChanges(spreadsheet, metadata, skipUndo = false) {
  const dirtyCellsToUpdate = [];
  var dirtyCells = spreadsheet?.getDirtyCells();
  if (dirtyCells && dirtyCells.length > 0) {
    dirtyCells.forEach(dirtyCell => {
      const currentCell = metadata.find(x => x.row === dirtyCell.row && x.column === dirtyCell.col);
      const dirtyCellExist = dirtyCellsToUpdate.find(
        x => x.row === dirtyCell.row && x.col === dirtyCell.col && (x.isEmptyRow === true || x.skipUndo === true)
      );
      if (!dirtyCellExist) {
        let dirtyCommand = {
          sheetArea: 3,
          type: 'updateDirty',
          row: dirtyCell.row,
          col: dirtyCell.col,
          oldValue: cleanDirtyCellValue(dirtyCell.oldValue),
          newValue: cleanDirtyCellValue(dirtyCell.newValue),
        };
        if (currentCell) {
          dirtyCommand.isEmptyRow = true;
        } else {
          dirtyCommand.skipUndo = skipUndo;
          dirtyCommand.oldCalcValue = cleanDirtyCellValue(dirtyCell.oldValue);
        }
        dirtyCellsToUpdate.push(dirtyCommand);
      }
    });
  }
  return dirtyCellsToUpdate;
}

function generateDataReferenceRequestDetails(commands) {
  return commands.map(({ commandText }) => ({
    commandText: JSON.stringify(commandText),
  }));
}

function generateCellFormatCommands(spreadsheet, metadata, sheetNamesList = null) {
  const { row, column, sheetName } = metadata;
  const selections = [{ row, column, colCount: 1, rowCount: 1 }];
  const sheetChanges = `changes${sheetName}`;
  const cellFormatterCommand = {
    value: spreadsheet.getFormatter(row, column),
    sheetName,
    selections: selections,
    activeRowIndex: row,
    activeColIndex: column,
    cmd: 'Designer.setFormatter',
    Z3: { W3: selections, K3: { row, column } },
    _styles: {},
    sheetId: spreadsheet._id,
    sheetNames: sheetNamesList ? sheetNamesList : [sheetName],
    actionType: 0,
  };
  cellFormatterCommand[sheetChanges] = [];
  const format = spreadsheet.getFormatter(row, column);
  const style = spreadsheet.getStyle(row, column);
  const cellFormatStyle = { ...format, ...style };
  cellFormatterCommand[sheetChanges].push([
    ['sheetModels', 3, 'dataTable', row, column, 'style'],
    cellFormatStyle,
    cellFormatStyle,
  ]);
  return cellFormatterCommand;
}

export async function prepareBatchCommands(metadata, spreadsheet, resolvedValues) {
  const commands = [];
  if (resolvedValues && resolvedValues.length > 0 && metadata && metadata.length > 0) {
    const dirtyCellsToUpdate = generateEditCellSheetChanges(spreadsheet, metadata);
    metadata.forEach(data => {
      var resolvedValue = resolvedValues.find(
        x => JSON.parse(x.key).row === data.row && JSON.parse(x.key).column === data.column
      );
      const formula = spreadsheet.getFormula(data.row, data.column);
      if (formula) {
        const dirtyCells = dirtyCellsToUpdate.filter(x => x.row === data.row && x.col === data.column);
        const previousValue =
          resolvedValue && resolvedValue.previousValue
            ? resolvedValue.previousValue
            : GC.Spread.CalcEngine.Errors.NotAvailable;

        const cellCommand = setValueOnServer(
          spreadsheet,
          data.row,
          data.column,
          previousValue,
          spreadsheet.getValue(data.row, data.column),
          dirtyCells
        );
        commands.push({
          commandText: cellCommand,
        });
        const { sheetNames } = cellCommand;
        commands.push({
          commandText: generateCellFormatCommands(spreadsheet, data, sheetNames),
        });
      }
    });

    const dependentCells = dirtyCellsToUpdate.filter(function (dirtyCell) {
      const data = metadata.filter(x => x.row === dirtyCell.row && x.column === dirtyCell.col);
      return data.length <= 0 ? dirtyCell : undefined;
    });

    if (dependentCells && dependentCells.length > 0) {
      const activeRowIndex = spreadsheet.getActiveRowIndex();
      const activeColIndex = spreadsheet.getActiveColumnIndex();
      const value = spreadsheet.getValue(activeRowIndex, activeColIndex);
      const dirtyCellsCommand = setValueOnServer(
        spreadsheet,
        activeRowIndex,
        activeColIndex,
        value,
        value,
        dependentCells
      );
      commands.push({
        commandText: dirtyCellsCommand,
      });
    }
    return generateDataReferenceRequestDetails(commands);
  }
}

export async function prepareBatchTagCommands(spreadsheet, references) {
  const commands = [];
  if (references && references.length > 0) {
    references.forEach(reference => {
      const cellTag = getCellTag(spreadsheet, reference.row, reference.column);
      const cellTagCommand = setValueAndTagOnServer(
        spreadsheet,
        reference.row,
        reference.column,
        JSON.stringify(cellTag)
      );
      commands.push({
        commandText: cellTagCommand,
      });
    });
  }
  return generateDataReferenceRequestDetails(commands);
}

export async function generateDirtyCellsCommands(spreadRef, metadata) {
  const commands = [];
  const spreadsheet = spreadRef.current.getActiveSheet();
  const dirtyCellsToUpdate = generateEditCellSheetChanges(spreadsheet, metadata);
  if (dirtyCellsToUpdate && dirtyCellsToUpdate.length > 0) {
    metadata.forEach(data => {
      commands.push({
        commandText: generateCellFormatCommands(spreadsheet, data),
      });
    });
    const activeRowIndex = spreadsheet.getActiveRowIndex();
    const activeColIndex = spreadsheet.getActiveColumnIndex();
    const value = spreadsheet.getValue(activeRowIndex, activeColIndex);
    const dirtyCellsCommand = setValueOnServer(
      spreadsheet,
      activeRowIndex,
      activeColIndex,
      value,
      value,
      dirtyCellsToUpdate
    );
    commands.push({
      commandText: dirtyCellsCommand,
    });
  }
  return generateDataReferenceRequestDetails(commands);
}
