import React, { useEffect, useState } from 'react';
import ElementInspector from './shared/ElementInspector';
import { BTForm, BTFormControlStatic, BTInput } from '@btas/jasper';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';

const ReorderElementInspector = ({ elementData, elementId, elementType, updateData, isDirty }) => {
  const [previewFields, setPreviewFields] = useState([]);

  useEffect(() => {
    if (elementData?.sourceDataFields.length === 0) {
      setPreviewFields([]);
    } else {
      setPreviewFields(elementData?.fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementData?.fields, elementData?.sourceDataFields]);

  const handleFieldListRender = () => {
    if (elementData?.newFieldOrder.length !== 0) {
      setPreviewFields(elementData?.newFieldOrder);
    }
  };

  const handleFieldOrder = (value, field, currentIndex) => {
    const currentFieldOrder = elementData?.newFieldOrder?.length > 0 ? elementData?.newFieldOrder : elementData?.fields;
    if (value <= 0 || value >= currentFieldOrder.length) {
      value = value <= 0 ? 1 : currentFieldOrder.length;
    }
    const newPosition = value === '' ? currentIndex : value - 1;

    const newFieldOrder = currentFieldOrder.filter(item => item?.name !== field?.name);
    newFieldOrder.splice(newPosition, 0, field);
    updateData({ newFieldOrder: newFieldOrder });
  };
  const canEditWorkflow = useCanEditWorkflow();
  return (
    <ElementInspector {...{ elementData, elementId, elementType, updateData, isDirty }}>
      <BTForm>
        <BTForm.FormGroup>
          <p>Enter the desired position for each field.</p>
          {previewFields?.map((field, index) => {
            const fieldName = field?.original_name ? field?.original_name : field?.name;
            return (
              <div key={`${index}_${fieldName}`}>
                <BTFormControlStatic>
                  <BTInput
                    disabled={!canEditWorkflow}
                    inputType="number"
                    max={elementData?.fields?.length}
                    min={1}
                    placeholder={index + 1}
                    size="compact"
                    onBlur={e => {
                      handleFieldListRender();
                      e.target.value = '';
                    }}
                    onChange={e => handleFieldOrder(e.target.value, field, index)}
                  />
                  {` ${fieldName}`}
                </BTFormControlStatic>
              </div>
            );
          })}
        </BTForm.FormGroup>
      </BTForm>
    </ElementInspector>
  );
};

export default ReorderElementInspector;
