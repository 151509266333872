import { BTCheckbox, BTInput } from '@btas/jasper';

const inputNames = {
  A: 'A',
  B: 'B',
  E: '',
};

export const ColumnMapping = ({
  name,
  original_name,
  joinSide,
  isPicked,
  isValid,
  renameTo,
  keyFieldsInLeft,
  setPicked,
  setRenamed,
}) => {
  const handleCheckboxChange = ({ target }) => {
    const { checked } = target;
    setPicked(joinSide, name, checked);
  };

  const handleNameChange = ({ target }) => {
    const { value } = target;
    setRenamed(joinSide, name, value);
  };

  let inputSide;

  if (joinSide === inputNames.A) {
    inputSide = keyFieldsInLeft.includes(name) ? inputNames.E : inputNames[joinSide];
  } else {
    inputSide = inputNames[joinSide];
  }

  return (
    <tr>
      <td>
        <BTCheckbox checked={isPicked} onChange={handleCheckboxChange} />
      </td>
      <td>{inputSide}</td>
      <td>{original_name}</td>
      <td className="wkp-no-paddings">
        <BTInput
          className={isValid ? 'bt-input' : 'has-error'}
          hasError={!isValid}
          value={renameTo ?? ''}
          onChange={handleNameChange}
        />
      </td>
      <td></td>
    </tr>
  );
};
