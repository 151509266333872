import { useContext, useEffect } from 'react';
import { LINK_COLOR } from '../shared/colors';
import { shapes, dia, connectionStrategies, linkTools } from '@clientio/rappid';
import useQuery from '../../../_shared/useQuery';

import { getRouter } from './router';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { ElementTypeMap } from '../elementType/types';

export const useRestoreCanvas = configuration => {
  const { dataFlowState, dataFlowActions } = useContext(DataFlowEditorContext);
  const { graph, navigator, paper } = dataFlowState;
  const query = useQuery();
  const activeElement = query.get('activeElement');
  useEffect(() => {
    if (!graph || !navigator) {
      return;
    }

    try {
      const { elements, annotations, connections, workflowConfiguration } = configuration;
      restoreElements(elements, graph, dataFlowActions);

      restoreElements(annotations, graph, dataFlowActions);
      restoreConnections(connections, graph, paper, dataFlowActions);
      restoreWorkflowConfiguration(workflowConfiguration, dataFlowActions);
      dataFlowActions.syncElementState();

      if (activeElement) {
        setActiveElement(graph, dataFlowActions, activeElement);
      }
    } catch (e) {
      dataFlowActions.setFailedToRestoreState(true);
    }
  }, [graph, navigator, paper, dataFlowActions, configuration, activeElement]);
};

function restoreWorkflowConfiguration(workflowConfiguration, dataFlowActions) {
  dataFlowActions.setWorkflowStatus({ workflowConfiguration });
}

function restoreElements(elements = {}, graph, dataFlowActions) {
  return Object.keys(elements).reduce((res, elementId) => {
    const element = elements[elementId];
    const elementType = ElementTypeMap[element.type];
    const cell = elementType.restoreInstance(element);
    cell.addTo(graph);
    const typeData = elementType.extractTypeData(element);
    dataFlowActions.addElement(cell, { name: element.name, ...typeData }, true);
    return { ...res, [elementId]: { cell, element } };
  }, {});
}

function restoreConnections(connections, graph, paper, dataFlowActions) {
  connections.forEach(connection => {
    const link = new shapes.standard.Link({
      line: {
        stroke: LINK_COLOR,
        strokeWidth: 1,
        targetMarker: {
          type: 'path',
          stroke: LINK_COLOR,
          fill: LINK_COLOR,
        },
      },
      source: {
        id: connection.sourceId,
        port: connection.sourcePort,
      },
      target: {
        id: connection.targetId,
        port: connection.targetPort,
      },
      vertices: connection?.vertices || [],
    });

    function getAbsAnchor(coords, view, magnet) {
      const { model, paper } = this;
      const end = connectionStrategies.pinAbsolute.call(paper, {}, view, magnet, coords, model);
      return end.anchor;
    }

    paper.updateViews();
    const toolsView = new dia.ToolsView({
      tools: [
        new linkTools.Segments({
          redundancyRemoval: true,
          segmentLengthThreshold: 10,
          anchor: getAbsAnchor,
        }),
      ],
    });

    if (!connection.vertices) {
      //sets temporary custom router
      link.router((vertices, opt, linkView) => getRouter(vertices, { ...opt, step: 10, padding: 15 }, linkView));
    }
    link.addTo(graph, { async: false });

    const linkView = paper.requireView(link);
    const { model, route } = linkView;
    model.vertices(route);
    model.unset('router');

    linkView.addTools(toolsView);
    dataFlowActions.addLink(link, true);

    link.on('change:vertices', function (link) {
      //updates link state when vertices are changed
      link.id && dataFlowActions.updateLinkVertices(link.id, link.attributes.vertices);
      dataFlowActions.setSaveStateDirty(true);
      dataFlowActions.resetSaveMenuDirty();
    });
  });
  graph.getLinks().forEach(link => {
    link.toBack();
  });
}

function setActiveElement(graph, dataFlowActions, activeElement) {
  const targetModel = graph.getElements().find(({ id }) => id === activeElement);

  if (targetModel) {
    dataFlowActions.setActiveElement(targetModel);
  }
}
