import { v4 as uuidv4 } from 'uuid';
import { setDataToLocalStorage, getDataFromLocalStorage, removeDataFromLocalStorage } from '../storage';
import { WORKPAPER_JOB_STATUS_FAILED } from './jobStatus';

const ACTIVE_BATCH_ID_LOCAL_STORAGE_ITEM_KEY = 'workpaper_job_active_batch_id';

export function getActiveBatchId() {
  const activeBatchId = getDataFromLocalStorage(ACTIVE_BATCH_ID_LOCAL_STORAGE_ITEM_KEY);
  return activeBatchId;
}

export function generateBatchId() {
  const newBatchId = uuidv4();
  return newBatchId;
}

export function setActiveBatchId() {
  const newBatchId = generateBatchId();
  setDataToLocalStorage(ACTIVE_BATCH_ID_LOCAL_STORAGE_ITEM_KEY, newBatchId);

  return newBatchId;
}

export function removeActiveBatchId() {
  removeDataFromLocalStorage(ACTIVE_BATCH_ID_LOCAL_STORAGE_ITEM_KEY);
}

export function mapJobToProcess({ jobId, workpaperId, jobType, status, payload, jobOutput }) {
  const isFailed = status === WORKPAPER_JOB_STATUS_FAILED;
  return {
    jobId,
    workpaperId,
    name: payload ? JSON.parse(payload).fileName : '',
    status,
    jobType,
    error: isFailed,
    jobOutput: jobOutput || { link: '', errorTitle: '', errorDescription: '' },
  };
}
