import * as fetch from '../../../../_shared/fetch';
import { spreadsheetServiceHostName } from '../../../../../configs/params';

export async function getSourceDataCells(linkedOutputIds) {
  const response = await fetch.post(
    `${spreadsheetServiceHostName}/spreadsheet/datareferences/data-flows/linked-workpapers`,
    {
      body: JSON.stringify(linkedOutputIds),
    }
  );
  const data = await response.json();

  return data;
}
