import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadSourceFile } from '../../shared/apis';
import { getWorkbookInfo } from '../FileDialog/apis';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { WKP_INPUT_FILE_IMPORT } from '../../../../constants/featureFlags';

export default function useUploadSourceFile(dataFlowState, dataFlowActions) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState({});
  const [uploadToS3Error, setUploadToS3Error] = useState();
  const { id: dataFlowId, workingElement, taxPeriod } = dataFlowState;
  const { setFilePropertiesDialog, resetSaveMenuDirty, setWKPFileImportProperties } = dataFlowActions;
  const [uploadedFilesByInput, setUploadedFilesByInput] = useState({});

  const uploadFileS3 = useCallback(
    async sampleFile => {
      const uploadResult = await uploadSourceFile(sampleFile);
      const fileExtension = sampleFile.name.split('.').pop();
      resetSaveMenuDirty();

      const isCsvFile = fileExtension === 'csv';
      let uploadedWorkbookInfo;

      // Display Source File Dialog
      setFilePropertiesDialog({ popup: true, loading: true });

      if (!isCsvFile) {
        uploadedWorkbookInfo = await getWorkbookInfo(uploadResult);
      }

      setFilePropertiesDialog({
        popup: true,
        loading: false,
        isCsvFile,
        fileDialogTaxPeriod: taxPeriod,
        fileName: sampleFile.name,
        sheets: !isCsvFile
          ? uploadedWorkbookInfo.sheetNames.map(sheet => {
              return { value: sheet.name, label: sheet.name };
            })
          : [],
        sourceInfo: {
          sourceData: !isCsvFile ? uploadedWorkbookInfo.sourceData : uploadResult,
          dataFlowId,
          inputId: workingElement.id,
          elementData: { ...workingElement.elementData, containsNewSourceFiles: true },
        },
      });

      setUploadError(prevState => ({ ...prevState, [workingElement.id]: undefined }));
    },
    [dataFlowId, workingElement, setFilePropertiesDialog, resetSaveMenuDirty, taxPeriod]
  );

  const uploadFileStaging = useCallback(
    sampleFile => {
      resetSaveMenuDirty();
      setWKPFileImportProperties({
        popup: true,
        loading: false,
        fileDialogTaxPeriod: taxPeriod,
        fileName: sampleFile.name,
        uploadFile: sampleFile,
      });
    },
    [resetSaveMenuDirty, setWKPFileImportProperties, taxPeriod]
  );

  const onSaveFile = useCallback(
    async (file, fileName) => {
      try {
        await uploadSourceFile(
          file,
          fileName,
          data => setUploadedFilesByInput(prevState => ({ ...prevState, [workingElement.id]: data })),
          setUploadToS3Error
        );
      } catch (err) {
        setUploadToS3Error(err);
      }
    },
    [workingElement, setUploadToS3Error]
  );

  const onDrop = useCallback(
    async files => {
      const [sampleFile] = files;

      try {
        if (isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT)) {
          uploadFileStaging(sampleFile);
        } else {
          await uploadFileS3(sampleFile);
        }
      } catch (err) {
        let error;

        if (err.code) {
          const { code, message } = err;
          error = ['WKP2001', 'WKP2002', 'WKP2003', 'WKP2006', 'WKP2007'].includes(code)
            ? 'Failed to parse sample file. Please make sure it is a valid csv file.'
            : 'Failed to upload sample file. Please check your internet connection and try again.';

          if (code === 'WKP2015') {
            error = message;
          }
        } else {
          error = 'Invalid file format. Source file must be of type *.xlsx or *.csv.';
        }

        setUploadError(prevState => ({ ...prevState, [workingElement.id]: error }));
      } finally {
        setIsUploading(false);
      }
    },
    [workingElement, uploadFileS3, uploadFileStaging]
  );

  const dropzone = useDropzone({
    accept: ['.csv', '.xlsx'],
    onDrop: onDrop,
    multiple: false,
  });

  return {
    uploadError,
    setUploadError,
    uploadToS3Error,
    setUploadToS3Error,
    dropzone,
    isUploading,
    onSaveFile,
    uploadedFilesByInput,
    setUploadedFilesByInput,
  };
}
