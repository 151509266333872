import React, { useRef, useEffect, useState } from 'react';
import { useGraph } from './DataFlowCanvas/useGraph';
import { useDataFlowEventHandlers } from './DataFlowCanvas/useDataFlowEventHandlers';
import { usePaper } from './DataFlowCanvas/usePaper';
import { useLinkHalo } from './DataFlowCanvas/useLinkHalo';
import { useElementTools } from './DataFlowCanvas/useElementTools';
import { useRestoreCanvas } from './DataFlowCanvas/useRestoreCanvas';
import { useTooltips } from './DataFlowCanvas/useTooltips';
import './DataFlowCanvas/styles.scss';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_DATAFLOW_SIDEBAR } from '../../../constants/featureFlags';

export const DataFlowCanvas = ({ initialDataFlow, inspectorIsPresent, previewIsPresent, configInspectorIsPresent }) => {
  const canvasContainer = useRef(null);
  const [canvasClass, setCanvasClass] = useState('wkp-data-flow-canvas');

  // Initialize graph
  useGraph();

  // Initialize paper and paper scroller
  usePaper(canvasContainer);

  // Enable event handlers for Data Flow Editor
  useDataFlowEventHandlers();

  // Enable Halo component for active links
  useLinkHalo();

  // Enable element Tools component for active elements
  useElementTools();

  useRestoreCanvas(initialDataFlow.configuration);

  useTooltips();

  useEffect(() => {
    const inspectorClassToUse = isFeatureFlagEnabled(WKP_DATAFLOW_SIDEBAR)
      ? 'wkp-data-flow-canvas-no-inspector-sidebar'
      : 'wkp-data-flow-canvas-no-inspector';
    const inspectorClass =
      inspectorIsPresent || configInspectorIsPresent ? 'wkp-data-flow-canvas-inspector' : inspectorClassToUse;
    const previewClass = previewIsPresent ? 'wkp-data-flow-canvas-preview' : 'wkp-data-flow-canvas-no-preview';

    const newCanvasClass = `wkp-data-flow-canvas ${inspectorClass} ${previewClass}`;

    setCanvasClass(newCanvasClass);
  }, [inspectorIsPresent, previewIsPresent, configInspectorIsPresent]);

  return <div ref={canvasContainer} className={canvasClass} />;
};
