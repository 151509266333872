import GC from '../../../../SpreadSheets';
import { useState, useRef } from 'react';
import {
  getWorkpaperDataReferences,
  resolveFormulaValues,
  updateReferences,
  deleteReferences,
  createWorkpaperDataReferences,
} from './apis';
import {
  formulaMatch,
  generateCellMetadata,
  generateOutputRequest,
  prepareBatchCommands,
  prepareBatchTagCommands,
  renderResolvedValues,
  setDataReferenceCellTags,
  updateDataReferenceQueue,
  updateInMemoryDataReferences,
  updateInMemoryDataReferencesQueue,
  createTempInMemoryDataReferences,
  getCellTag,
  resolveNewReferenceValue,
  resolveExistingReferenceValue,
  generateDirtyCellsCommands,
  cleanDirtyCellValue,
} from './dataReferenceHelper';
import { cellReviewDefinition } from '../Spreadsheet/_spreadsheets/commands/newCellReview';
import { cellReviewDefinition as oldCellReviewDefinition } from '../Spreadsheet/_spreadsheets/commands/cellReview';
import useCellTracker from './useCellTracker';
import useReferenceScheduler from './useReferenceScheduler';
import { CELL_REVIEW } from '../../../_shared/DataReference/ReferenceType';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { CELL_REVIEW_ENABLED, SJS_API } from '../../../../constants/featureFlags';

import { getDataFromLocalStorage } from '../../../_shared/storage';
import {
  CELL_REVIEW_ADDED,
  CELL_REVIEW_REMOVED,
  CELL_REVIEW_COMMAND_DELETE,
} from '../HistoryTracker/useHistoryTracker';
export default function useDataReferenceManager({
  dataReferences,
  dataReferenceValues,
  isDragFillAction,
  isCopyPasteAction,
  enqueueCommands,
  tooltipManagerRef,
  spreadRef,
  dataReferenceHistoryTracker = null,
  dataReferenceWorkpaperId,
  dataReferenceWorkpaperVersionId,
  cellReviewManagerInitialized,
}) {
  const dataReferenceQueue = useRef([]);
  const dataReferenceCellReviewQueue = useRef([]);
  const dataReferencePositionUpdateQueue = useRef([]);
  const dataReferenceDeleteQueue = useRef([]);
  const dataReferenceCellTagUpdateQueue = useRef([]);
  const dataReferenceRecalcQueue = useRef([]);
  const isRecalcPostitions = useRef(false);
  const isUndoDeleteCommand = useRef(false);
  const deleteCellReviewCommand = useRef([]);
  const lastCellReviewActionTimestamp = useRef(0);
  const { trackPosition, trackPastedReference, clearReferenceCell } = useCellTracker(
    spreadRef?.current,
    dataReferences,
    dataReferencePositionUpdateQueue,
    dataReferenceDeleteQueue,
    dataReferenceCellTagUpdateQueue,
    lastCellReviewActionTimestamp,
    deleteCellReviewCommand,
    dataReferenceHistoryTracker,
    processDataReferencePositionQueue,
    processDataReferencesDeleteQueue,
    enqueueManyDataReferences,
    processCellReviewQueue
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const processingInterval = 400;
  dataReferenceHistoryTracker.current.dataReferenceWorkpaperId = dataReferenceWorkpaperId.current;
  useReferenceScheduler(dataReferenceQueue, processDataReferenceQueue, processingInterval);
  useReferenceScheduler(dataReferenceRecalcQueue, processDataReferencesRecalculation, processingInterval);
  useReferenceScheduler(dataReferenceCellTagUpdateQueue, processCellTagUpdate, processingInterval);

  const SJS_ENABLED = isFeatureFlagEnabled(SJS_API);

  // ====================
  // Enqueue Process
  // ====================

  function enqueueManyDataReferences(references, isUndoDeleteCommandAction = false) {
    isUndoDeleteCommand.current = isUndoDeleteCommandAction;
    const spreadsheet = spreadRef.current.getActiveSheet();
    if (references.length > 0) {
      dataReferenceCellReviewQueue.current = [...dataReferenceCellReviewQueue.current, ...references];
      createTempInMemoryDataReferences(
        spreadsheet,
        dataReferences,
        dataReferenceCellReviewQueue,
        isUndoDeleteCommandAction
      );
      lastCellReviewActionTimestamp.current = Date.now();
    }

    if (references.length > 0) {
      createTempInMemoryDataReferences(spreadsheet, dataReferences, dataReferenceQueue, isUndoDeleteCommandAction);
    }
  }

  function enqueueDataReference(reference) {
    const spreadsheet = spreadRef.current.getActiveSheet();
    if (reference.type === CELL_REVIEW) {
      const calcReferenceExist = dataReferenceCellReviewQueue.current.filter(
        x => x.row === reference.row && x.column === reference.column && x.sheetName === reference.sheetName
      );
      if (calcReferenceExist.length <= 0) {
        dataReferenceCellReviewQueue.current = [...dataReferenceCellReviewQueue.current, reference];
        createTempInMemoryDataReferences(spreadsheet, dataReferences, dataReferenceCellReviewQueue);
        lastCellReviewActionTimestamp.current = Date.now();
      }
    } else {
      const calcReferenceExist = dataReferenceQueue.current.filter(
        x => x.row === reference.row && x.column === reference.column && x.sheetName === reference.sheetName
      );
      if (calcReferenceExist.length <= 0) {
        dataReferenceQueue.current = [...dataReferenceQueue.current, reference];
        createTempInMemoryDataReferences(spreadsheet, dataReferences, dataReferenceQueue);
      }
    }
  }

  function enqueueDataReferenceReCalc(reference) {
    const recalcReferenceIndex = dataReferenceRecalcQueue.current.findIndex(
      x => x.row === reference.row && x.column === reference.column && x.sheetName === reference.sheetName
    );

    if (recalcReferenceIndex !== -1) {
      dataReferenceRecalcQueue.current[recalcReferenceIndex] = reference;
    } else {
      dataReferenceRecalcQueue.current = [...dataReferenceRecalcQueue.current, reference];
    }
  }

  // ====================
  // References Validity
  // ====================

  function isFormulaMatch(reference) {
    return formulaMatch(dataReferences, reference);
  }
  function referenceExistInWorksheet(id, targetSheetName) {
    var reference = dataReferences.current.find(x => x.id === id && x.sheetName === targetSheetName);
    return reference ? true : false;
  }

  function referenceExistInTargetCell(row, column, targetSheetName, type) {
    return dataReferences.current.find(
      x => x.row === row && x.column === column && x.sheetName === targetSheetName && x.type === type
    );
  }

  function referenceIsEnqueuedForRecalc(row, column, targetSheetName) {
    return dataReferenceRecalcQueue.current.some(
      x => x.row === row && x.column === column && x.sheetName === targetSheetName
    );
  }

  // ====================
  // References values resoltuion & renering
  // ====================

  async function repaintActiveSheet(references) {
    const activeSheet = spreadRef.current.getActiveSheet();
    const currentReferences = references?.length ? references : dataReferences.current;
    if (activeSheet) {
      const cellReviews = currentReferences.filter(x => x.type === CELL_REVIEW && x.sheetName === activeSheet.name());

      if (cellReviews.length) {
        activeSheet.suspendPaint();
        activeSheet.resumePaint();
      }
    }
  }

  function renderCustomFormulaValues(cellTag, row, column, referenceType, sheet) {
    if (!cellTag?.references) {
      return resolveNewReferenceValue(dataReferenceValues, row, column, sheet);
    } else if (isDragFillAction.current.isActive) {
      const newValue = dataReferenceValues.current.find(
        x =>
          JSON.parse(x.key).row === row && JSON.parse(x.key).column === column && JSON.parse(x.key).sheetName === sheet
      )?.value;
      const existingValue = dataReferences.current.find(
        x => x.row === row && x.column === column && x.sheetName === sheet
      )?.value;
      if (newValue === '') {
        return GC.Spread.CalcEngine.Errors.NotAvailable;
      } else if (newValue && existingValue) {
        return resolveNewReferenceValue(dataReferenceValues, row, column, sheet);
      } else if (!newValue && existingValue) {
        return resolveExistingReferenceValue(dataReferences, cellTag, referenceType, sheet);
      } else if (newValue && !existingValue) {
        return resolveNewReferenceValue(dataReferenceValues, row, column, sheet);
      } else {
        return null;
      }
    } else {
      const isCalc = dataReferenceQueue.current.find(
        x => x.row === row && x.column === column && x.sheetName === sheet
      );
      const isRecalc = dataReferenceRecalcQueue.current.find(
        x => x.row === row && x.column === column && x.sheetName === sheet
      );
      if (isCalc || isRecalc) {
        return resolveNewReferenceValue(dataReferenceValues, row, column, sheet);
      }

      return resolveExistingReferenceValue(dataReferences, cellTag, referenceType, sheet);
    }
  }
  async function resolveDataReferenceValues() {
    let resolvedValuesResponseData = [];
    const outputRequest = await generateOutputRequest(dataReferenceQueue, spreadRef.current.getActiveSheet().name());
    if (outputRequest.length > 0) {
      const resolvedValuesResponse = await resolveFormulaValues(dataReferenceWorkpaperId.current, outputRequest);
      if (resolvedValuesResponse.ok || resolvedValuesResponse.status === 504) {
        resolvedValuesResponseData = await resolvedValuesResponse.json();
        if (resolvedValuesResponseData && resolvedValuesResponseData.length > 0) {
          dataReferenceValues.current = [...dataReferenceValues.current, ...resolvedValuesResponseData];
        }
      }
    }
    if (dataReferenceValues.current.length <= 0) {
      dataReferenceValues.current = outputRequest.map(request => ({
        ...request,
        value: GC.Spread.CalcEngine.Errors.NotAvailable,
      }));
    }
  }
  async function loadWorkbookDataReferences(workpaperId) {
    const { creatingWorkpaper } = JSON.parse(getDataFromLocalStorage(workpaperId) || '{}');
    if (!creatingWorkpaper) {
      const workbookDataReferences = await getWorkpaperDataReferences(workpaperId);
      if (workbookDataReferences?.length) {
        let references = [...dataReferences.current, ...workbookDataReferences];
        if (dataReferenceWorkpaperVersionId?.current) {
          references = references.filter(x => x.type !== CELL_REVIEW);
        }
        dataReferences.current = [...new Map(references.map(reference => [reference.id, reference])).values()];
      }
      if (!cellReviewManagerInitialized.current) {
        if (isFeatureFlagEnabled(CELL_REVIEW_ENABLED)) {
          cellReviewDefinition(tooltipManagerRef.current, dataReferences, isRecalcPostitions);
        } else {
          oldCellReviewDefinition(tooltipManagerRef.current);
        }
        cellReviewManagerInitialized.current = true;
      }
      if (dataReferenceWorkpaperVersionId.current) {
        spreadRef.current.suspendPaint();
        spreadRef.current.resumePaint();
      } else {
        await repaintActiveSheet();
      }
    }
  }

  // ====================
  // References cell tags
  // ====================

  async function setReferenceCellTags() {
    const cellTagsToUpdate = await setDataReferenceCellTags(spreadRef.current.getActiveSheet(), dataReferences.current);
    await processBatchTagCommands(cellTagsToUpdate);
  }
  function getCellReferenceTag(sheet, row, column) {
    if (sheet) {
      const activeRow = sheet.getActiveRowIndex();
      const activeColumn = sheet.getActiveColumnIndex();
      const activeCellTag = getCellTag(sheet, activeRow, activeColumn);
      const contextCellTag = getCellTag(sheet, row, column);
      // The active cell tag is retrieved when cells are inserted from a cell containing a custom formula.
      // The active cell tag serves as the source tag and the context tag serves as the target tag.
      const cellTag = contextCellTag ? contextCellTag : activeCellTag;
      return cellTag;
    }
  }

  async function processCellTagUpdate() {
    if (dataReferenceCellTagUpdateQueue.current && dataReferenceCellTagUpdateQueue.current.length > 0) {
      await processBatchTagCommands(dataReferenceCellTagUpdateQueue.current);
    }
  }

  async function processBatchTagCommands(spreadsheet, sourceReferences) {
    if (sourceReferences && sourceReferences.length > 0) {
      const commands = await prepareBatchTagCommands(spreadsheet, sourceReferences);
      enqueueCommands(commands);
    }
  }

  // ====================
  // References cell position tracker
  // ====================
  async function trackDataReferenceCellPositions({ command, sheet }) {
    isRecalcPostitions.current = true;
    trackPosition(command, sheet);
    isRecalcPostitions.current = false;
  }

  const handleCellReviewDeleteAction = (addToCellReviewHistory, spread, references) => {
    const deletedCellReviews = references
      ? references.current
      : dataReferences.current.filter(({ id }) => dataReferenceDeleteQueue.current.includes(id));
    addToCellReviewHistory(
      {
        cmd: references ? CELL_REVIEW_COMMAND_DELETE : CELL_REVIEW_REMOVED,
        data: deletedCellReviews,
      },
      spread
    );
    if (!references) {
      dataReferences.current = dataReferences.current.filter(
        ({ id }) => !dataReferenceDeleteQueue.current.includes(id)
      );
    }
  };

  // ====================
  // Custom formula definition
  // ====================

  function gcBaseCustomFunction(isContextSensitive) {
    const gcBaseCustomFunctionSubClass = isContextSensitive
      ? GC.Spread.CalcEngine.Functions.Function
      : GC.Spread.CalcEngine.Functions.AsyncFunction;

    const customFunctionClass = class extends gcBaseCustomFunctionSubClass {
      constructor({ name, minArgs, maxArgs, descriptionData, evaluationFunction, data }) {
        super();

        this.name = name;
        this.minArgs = minArgs;
        this.maxArgs = maxArgs;
        this.descriptionData = descriptionData;
        this.evaluationFunction = evaluationFunction;
        this.data = data;
      }

      //set context flag to get GC context object for affected cell
      isContextSensitive() {
        return isContextSensitive;
      }

      defaultValue() {
        return this.data.defaultValue;
      }
    };

    if (isContextSensitive) {
      // add evaluate method to class
      customFunctionClass.prototype.evaluate = function (...args) {
        return this.evaluationFunction(args, this.data);
      };
    } else {
      customFunctionClass.prototype.evaluateAsync = function (...args) {
        return this.evaluationFunction(args, this.data);
      };
    }

    customFunctionClass.prototype.description = function () {
      return this.descriptionData;
    };

    return customFunctionClass;
  }

  // ====================
  // Base queue processors
  // ====================

  async function processDataReferenceQueue() {
    if (dataReferenceQueue.current.length > 0) {
      setIsProcessing(true);
      await resolveDataReferenceValues();
      await renderResolvedValues(spreadRef.current.getActiveSheet(), dataReferenceValues);
      const metadata = await generateCellMetadata(dataReferenceQueue, dataReferenceValues);
      isCopyPasteAction.current = { isActive: false, sameSheet: undefined, entireSheet: undefined };
      isDragFillAction.current = { isActive: false, direction: undefined };
      await updateInMemoryDataReferences(dataReferences, dataReferenceQueue, dataReferenceValues);
      const createDataReferences = await createWorkpaperDataReferences(dataReferenceWorkpaperId.current, metadata);
      if (createDataReferences) {
        dataReferences.current = createDataReferences;
      }
      await setReferenceCellTags();
      await processBatchCommands(dataReferenceQueue.current);
      await syncDataReferencesQueues(false);
      setIsProcessing(false);
    }
  }
  async function processCellReviewQueue(spread, sheet, addToHistory, repaintOnLoad = true) {
    let alreadySyncData = false;
    if (dataReferenceCellReviewQueue.current.length > 0) {
      const metadata = [];
      setIsProcessing(true);
      dataReferenceCellReviewQueue.current.forEach(data => {
        metadata.push({
          parameters: typeof data.parameters === 'string' ? data.parameters : JSON.stringify(data.parameters),
          oldValue: data.value,
          newValue: data.value,
          row: data.row,
          column: data.column,
          sheetName: data.sheetName,
          referenceType: data.type,
          referenceId: data.id,
        });
      });
      const createDataReferences = await createWorkpaperDataReferences(dataReferenceWorkpaperId.current, metadata);
      if (createDataReferences) {
        dataReferences.current = createDataReferences;
        if (repaintOnLoad) await repaintActiveSheet();
        alreadySyncData = true;
      }

      setIsProcessing(false);
      await setReferenceCellTags();
      const cellTagCommands = await prepareBatchTagCommands(sheet, dataReferenceCellReviewQueue.current);
      const dirtyCellsCommand = await generateDirtyCellsCommands(
        spreadRef,
        dataReferenceCellReviewQueue.current,
        isUndoDeleteCommand
      );
      const commands = [...cellTagCommands, ...dirtyCellsCommand];
      enqueueCommands(commands);
      addToHistory &&
        addToHistory(
          {
            cmd: CELL_REVIEW_ADDED,
            data: dataReferenceCellReviewQueue.current,
          },
          spread,
          sheet
        );
      dataReferenceCellReviewQueue.current = [];
      isUndoDeleteCommand.current = false;
    }
    return { alreadySyncData };
  }

  async function processDataReferencesRecalculation() {
    if (dataReferenceRecalcQueue.current.length > 0) {
      setIsProcessing(true);
      const activeSheet = spreadRef.current.getActiveSheet();
      const sheetName = activeSheet.name();
      const outputRequest = await generateOutputRequest(dataReferenceRecalcQueue, sheetName);
      const resolvedValuesResponse = await resolveFormulaValues(dataReferenceWorkpaperId.current, outputRequest);
      if (resolvedValuesResponse.ok || resolvedValuesResponse.status === 504) {
        const resolvedValues = await resolvedValuesResponse.json();
        if (resolvedValues && resolvedValues.length > 0) {
          dataReferenceValues.current = resolvedValues;
        }
      }
      if (dataReferenceValues.current.length <= 0) {
        dataReferenceValues.current = outputRequest.map(request => ({
          ...request,
          value: GC.Spread.CalcEngine.Errors.NotAvailable,
        }));
      }
      await renderResolvedValues(activeSheet, dataReferenceValues);
      await updateInMemoryDataReferencesQueue(dataReferences, dataReferenceRecalcQueue, dataReferenceValues);
      const cellReviews = dataReferences.current.filter(
        x =>
          x.type === CELL_REVIEW &&
          dataReferenceRecalcQueue.current.filter(
            y => y.row === x.row && y.column === x.column && y.sheetName === x.sheetName
          )?.length
      );
      const reviewsToUpdate = cellReviews?.map(x => {
        x.value = cleanDirtyCellValue(
          dataReferenceRecalcQueue.current.find(
            y => y.row === x.row && y.column === x.column && y.sheetName === x.sheetName && y.type !== CELL_REVIEW
          )?.value
        );
        return x;
      });
      const referencesToUpdate = [...dataReferenceRecalcQueue.current, ...reviewsToUpdate];
      await updateReferences(dataReferenceWorkpaperId.current, referencesToUpdate);
      await processBatchCommands(dataReferenceRecalcQueue.current);
      await syncDataReferencesQueues();
      await processDataReferencePositionQueue();
      setIsProcessing(false);
    }
  }
  async function processDataReferencePositionQueue() {
    if (dataReferencePositionUpdateQueue.current.length) {
      const sheet = spreadRef.current.getActiveSheet();
      const references = dataReferencePositionUpdateQueue.current;
      const response = await updateReferences(dataReferenceWorkpaperId.current, references);
      if (response.ok) {
        const { alreadySyncData } = await processCellReviewQueue(spreadRef.current, sheet, null);
        await syncDataReferencesQueues(alreadySyncData);
        const updatedPositionsReferences = dataReferencePositionUpdateQueue.current.filter(
          item => !references.includes(item)
        );

        dataReferencePositionUpdateQueue.current = updatedPositionsReferences;
      }
    }
  }

  async function processDataReferencesDeleteQueue(
    workpaperIdParam = dataReferenceWorkpaperId.current,
    callback = null,
    isCellReview = false,
    spread = null,
    addToCellReviewHistory = null
  ) {
    if (dataReferenceDeleteQueue.current.length && !isUndoDeleteCommand.current) {
      if (isCellReview && spread && addToCellReviewHistory) {
        handleCellReviewDeleteAction(addToCellReviewHistory, spread, null);
      }
      if (
        dataReferenceHistoryTracker &&
        deleteCellReviewCommand.current.length &&
        dataReferenceHistoryTracker?.current?.addToHistory
      ) {
        handleCellReviewDeleteAction(
          dataReferenceHistoryTracker.current.addToHistory,
          spreadRef.current,
          deleteCellReviewCommand
        );
      }
      const response = await deleteReferences(workpaperIdParam, dataReferenceDeleteQueue.current);
      if (response.ok) {
        dataReferences.current = dataReferences.current.filter(
          ref => !dataReferenceDeleteQueue.current.includes(ref.id)
        );
        await repaintActiveSheet(deleteCellReviewCommand.current);
        await syncDataReferencesDeleteAction(workpaperIdParam, callback);
      }
    } else {
      await syncDataReferencesDeleteAction(workpaperIdParam, callback);
    }
  }
  async function processBatchCommands(metadata) {
    const spreadsheet = spreadRef.current.getActiveSheet();
    if (dataReferenceValues.current && dataReferenceValues.current.length > 0) {
      const commands = [];

      if (!SJS_ENABLED) {
        const referenceCommands = await prepareBatchCommands(metadata, spreadsheet, dataReferenceValues.current);
        commands.push(...referenceCommands);
      }

      const tagsToUpdate = await setDataReferenceCellTags(spreadsheet, metadata);
      const tagCommands = await prepareBatchTagCommands(spreadsheet, tagsToUpdate);
      commands.push(...tagCommands);

      enqueueCommands(commands);
      return commands;
    }
  }

  // ====================
  // Base queue synchronizers
  // ====================
  async function syncDataReferencesDeleteAction(workpaperIdParam, callback = null) {
    await loadWorkbookDataReferences(workpaperIdParam);
    dataReferenceDeleteQueue.current = [];
    deleteCellReviewCommand.current = [];
    isUndoDeleteCommand.current = false;
    callback && callback();
  }

  async function syncDataReferencesQueues(alreadySyncData = true) {
    const spreadsheet = spreadRef.current.getActiveSheet();
    if (!alreadySyncData) await loadWorkbookDataReferences(dataReferenceWorkpaperId.current);
    await setReferenceCellTags();
    await updateDataReferenceQueue(spreadsheet, dataReferenceQueue, dataReferenceValues);
    await updateDataReferenceQueue(spreadsheet, dataReferenceRecalcQueue, dataReferenceValues);
  }

  return {
    clearReferenceCell,
    isProcessing,
    dataReferences,
    dataReferenceRecalcQueue,
    dataReferenceValues,
    dataReferenceDeleteQueue,
    dataReferenceQueue,
    enqueueDataReference,
    enqueueManyDataReferences,
    enqueueDataReferenceReCalc,
    getCellReferenceTag,
    gcBaseCustomFunction,
    loadWorkbookDataReferences,
    processBatchCommands,
    renderResolvedValues,
    renderCustomFormulaValues,
    setReferenceCellTags,
    trackDataReferenceCellPositions,
    trackPastedReference,
    updateDataReferenceQueue,
    isDragFillAction,
    referenceExistInWorksheet,
    referenceExistInTargetCell,
    referenceIsEnqueuedForRecalc,
    isFormulaMatch,
    processCellReviewQueue,
    processDataReferencesDeleteQueue,
    isRecalcPostitions,
    resolveDataReferenceValues,
  };
}
