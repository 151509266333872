import { TransformationElementType } from '../TransformationElementType';
import iconImage from '../icons/advanced_transpose_block_icon.svg';
import hintImage from '../icons/advanced_transpose_hint_img.svg';
import { FILTER_COLOR } from '../../shared/colors';
import { AdvancedTransposeElementInspector } from '../../AdvancedTransposeElementInspector';
import { ADVANCED_TRANSPOSE } from '../types/shared/typesConstants';

export class AdvancedTransposeElementType extends TransformationElementType {
  static TYPE = ADVANCED_TRANSPOSE;

  static HELP_TEXT = `The advanced transpose block takes multiple columns and converts them into name/value pairs.<img src=${hintImage} alt="Advanced transpose hint" />`;

  constructor() {
    super(
      AdvancedTransposeElementType.TYPE,
      `Advanced\nTranspose`,
      FILTER_COLOR,
      iconImage,
      AdvancedTransposeElementType.HELP_TEXT
    );
  }

  applySourceElements(elementData, sourceElements) {
    const { fields: sourceFields } = super.applySourceElements(elementData, sourceElements);
    const checkedFields = this.produceCheckedFields(sourceFields, elementData.checkedFields);

    const { name, value, transpositionFields } = this.produceTranspositionFields(
      elementData.advancedTransposeFieldName,
      elementData.advancedTransposeFieldValue,
      checkedFields,
      sourceFields,
      elementData.fields
    );

    const fields =
      sourceFields.length !== 0
        ? this.produceFinalFields(sourceFields, checkedFields, transpositionFields)
        : elementData.fields ?? [];

    return {
      ...elementData,
      fields,
      sourceFields,
      checkedFields,
      transpositionFields,
      advancedTransposeFieldName: name,
      advancedTransposeFieldValue: value,
    };
  }

  produceCheckedFields(sourceFields, checkedFields = {}) {
    if (sourceFields.length === 0) {
      return checkedFields;
    }

    return sourceFields.reduce((acum, field) => {
      const name = field.name;

      if (checkedFields.hasOwnProperty(name)) {
        acum[name] = checkedFields[name];
      } else if (field.original_name && checkedFields.hasOwnProperty(field.original_name)) {
        acum[name] = checkedFields[field.original_name];
      } else {
        acum[name] = false;
      }

      return acum;
    }, {});
  }

  produceTranspositionFields(nameField, valueField, checkedFields, sourceFields, oldFields = []) {
    const name = this.getColumnName(nameField, 'Name', checkedFields, sourceFields);

    const value = this.getColumnName(valueField, 'Value', checkedFields, sourceFields);

    const areAllNumeric = sourceFields.every(field => {
      if (!checkedFields[field.original_name || field.name]) {
        return true;
      }

      return field.type === 'numeric';
    });

    const oldNameField = oldFields.find(f => f.original_name && f.original_name === name);
    const oldValueField = oldFields.find(f => f.original_name && f.original_name === value);

    return {
      name,
      value,
      transpositionFields: [
        oldNameField ? { name: oldNameField.original_name, type: 'text' } : { name, type: 'text' },
        oldValueField
          ? {
              name: oldValueField.original_name,
              type: areAllNumeric ? 'numeric' : 'text',
            }
          : { name: value, type: areAllNumeric ? 'numeric' : 'text' },
      ],
    };
  }

  produceFinalFields(sourceFields, checkedFields, transpositionFields) {
    return [...sourceFields.filter(field => checkedFields[field.name] === false), ...transpositionFields];
  }

  get inspectorComponent() {
    return AdvancedTransposeElementInspector;
  }

  extractTypeData(elementData) {
    return {
      ...super.extractTypeData(elementData),
      checkedFields: elementData.checkedFields,
      transpositionFields: elementData.transpositionFields,
      advancedTransposeFieldName: elementData.advancedTransposeFieldName,
      advancedTransposeFieldValue: elementData.advancedTransposeFieldValue,
    };
  }

  getPreviewColumns(elementData) {
    return elementData.fields;
  }

  getColumnName(field, defaultName, checkedFields, inputFields) {
    const fieldUndefined = !field || !field.trim().length;
    if (
      fieldUndefined ||
      (field.trim().toLowerCase() === defaultName.toLowerCase() && checkedFields[field] === false)
    ) {
      return this.validateColumnName(defaultName, checkedFields, inputFields);
    } else {
      return field;
    }
  }

  validateColumnName(columnName, checkedFields, fields, suffix = 1) {
    let newColumnName = columnName;
    const newColumnNameInLowerCase = newColumnName.toLowerCase();
    const hasName = Object.values(fields).find(
      key => key['name'].trim().toLowerCase() === newColumnNameInLowerCase && checkedFields[key['name']] === false
    );

    if (hasName) {
      newColumnName = newColumnName.replace(`_${suffix - 1}`, '');
      newColumnName += `_${suffix}`;

      return this.validateColumnName(newColumnName, checkedFields, fields, suffix + 1);
    }

    return newColumnName;
  }
}
