import { BTButton, BTComboBox, BTForm, BTInput, BTModal } from '@btas/jasper';
import React, { useCallback, useEffect, useState } from 'react';
import { FIND_AND_REPLACE_PROMPT } from '../../../../constants/featureFlags';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { validateSourceFileFields } from '../InputElementInspector/apis';
import {
  processSourceFileToCsv,
  validateSourceFile,
  getUploadedFilesByTaxPeriod,
  getWorkbookInfo,
  addDeleteMarketOnSFTPObject,
} from './apis';
import isValidCellFormat from './utils';
import './styles.scss';
import TaxPeriodInput from '../../../_shared/TaxPeriodInput';
import SheetList from '../../../_shared/SheetList';
import UpdateFieldNameReferenceDialog from '../UpdateFieldNameReferenceDialog/UpdateFieldNameReferenceDialog';
import { getErrorMessage } from '../../shared/Status';
import { copyObjectBetweenBuckets } from '../../shared/apis';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const FileDialog = ({ dataFlowActions, dataFlowState, setGeneralError, setOpenSourceFile }) => {
  const {
    setElementData,
    setFilePropertiesDialog,
    resetSaveMenuDirty,
    setFindAndReplacePropDialog,
    setSaveStateDirty,
    setSFTPInputProperties,
  } = dataFlowActions;

  const { filePropertiesDialog, findAndReplaceDialog, taxPeriod: dataFlowTaxPeriod, id: dataflowId } = dataFlowState;
  const [sheetData, setSheetData] = useState({ sheetName: '', dataStartAt: '', numHeaders: '' });
  const [validationError, setValidationError] = useState({});
  const [fileNameInUse, setFileNameInUse] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [headerError, setHeaderError] = useState('');
  const [filesByPeriod, setFilesByPeriod] = useState(null);
  const [fileNames, setFileNames] = useState([]);
  const [fileName, setFileName] = useState(
    filePropertiesDialog?.fileName?.substring(0, filePropertiesDialog?.fileName.lastIndexOf('.') || '')
  );
  const [fileDialogTaxPeriod, setFileDialogTaxPeriod] = useState(
    filePropertiesDialog?.fileDialogTaxPeriod ?? dataFlowTaxPeriod
  );
  const [fileExtension, setFileExtension] = useState('.'.concat(filePropertiesDialog?.fileName?.split('.').pop()));

  const history = useHistory();

  useEffect(() => {
    return () => {
      setFilePropertiesDialog({
        loading: false,
        popup: false,
      });
    };
  }, [setFilePropertiesDialog]);

  useEffect(() => {
    if (filePropertiesDialog?.updateSourceFile) {
      const { sheetName, headerRows: numHeaders, headersStartAt: dataStartAt } = filePropertiesDialog.savedValues;
      setSheetData({ sheetName, dataStartAt, numHeaders });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filePropertiesDialog?.updateSourceFile]);

  useEffect(() => {
    setFileDialogTaxPeriod(filePropertiesDialog?.fileDialogTaxPeriod);
  }, [filePropertiesDialog?.fileDialogTaxPeriod]);

  useEffect(() => {
    async function createInitialValues() {
      if (!!filePropertiesDialog?.fileName) {
        const name =
          filePropertiesDialog?.fileName?.substring(0, filePropertiesDialog?.fileName.lastIndexOf('.')) || '';
        setFileName(name);
        setFileExtension('.'.concat(filePropertiesDialog?.fileName?.split('.').pop()));

        if (filePropertiesDialog?.useSFTP) {
          setFilePropertiesDialog({ ...filePropertiesDialog, loading: true });
          let sheetOptions = filePropertiesDialog?.selectedSFTPFileSheetData;
          const defaultSheet = sheetOptions && sheetOptions.length > 0 ? sheetOptions[0]?.value : '';
          setSheetData({ sheetName: defaultSheet, dataStartAt: '', numHeaders: '' });
          setFilePropertiesDialog({ ...filePropertiesDialog, loading: false, sheets: sheetOptions });
        }

        if (!filePropertiesDialog?.updateSourceFile) {
          await validateSourceFileExistence(true, {
            name,
            taxPeriodValue: fileDialogTaxPeriod,
            extension: '.'.concat(filePropertiesDialog?.fileName?.split('.').pop()),
          });
        }
      }
    }
    createInitialValues();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filePropertiesDialog?.fileName,
    fileDialogTaxPeriod,
    filePropertiesDialog?.updateSourceFile,
    filePropertiesDialog?.selectedSFTPFileSheetData,
    filePropertiesDialog?.useSFTP,
  ]);

  // could use call back to avoid many calls
  const fetchFilesByTaxperiod = useCallback(
    async fileDialogTaxPeriod => {
      if (fileDialogTaxPeriod) {
        const files = await getUploadedFilesByTaxPeriod(fileDialogTaxPeriod);
        setFilesByPeriod(files);

        if (files && files.uploadFiles) {
          const fileNames = files?.uploadFiles.map(f => ({ label: f.name, value: f.name }));
          setFileNames(fileNames);
        }
        setSelectedFileName('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileDialogTaxPeriod]
  );

  useEffect(() => {
    fetchFilesByTaxperiod(fileDialogTaxPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileDialogTaxPeriod, filePropertiesDialog?.reloadFiles]);

  useEffect(() => {
    async function fetchSheets() {
      const files = filesByPeriod?.uploadFiles;
      let sheetOptions = [];
      if (files && files.length > 0) {
        const f = files.find(f => f.name === selectedFileName?.value);
        if (f !== undefined) {
          const sourceData = { path: f.fileLocation.fileKey, bucket: f.fileLocation.bucket, name: f.name };
          if (!filePropertiesDialog?.isCsvFile) {
            setFilePropertiesDialog({ ...filePropertiesDialog, loading: true });
            const sheetsResult = await getWorkbookInfo(sourceData);
            if (sheetsResult?.error) {
              setGeneralError(sheetsResult.error);
              setFilePropertiesDialog({
                popup: false,
                loading: false,
                saving: false,
                sheets: [],
                reloadFiles: false,
              });
              return;
            }

            sheetOptions = sheetsResult.sheetNames.map(sheet => {
              return { value: sheet.name, label: sheet.name };
            });
            const defaultSheet = sheetOptions.length > 0 ? sheetOptions[0].value : '';
            setSheetData({ sheetName: defaultSheet, dataStartAt: '', numHeaders: '' });
            setFilePropertiesDialog({ ...filePropertiesDialog, loading: false });
          }
          const sourceInfo = { ...filePropertiesDialog.sourceInfo, sourceData: sourceData };
          setFilePropertiesDialog({
            ...filePropertiesDialog,
            sourceInfo: sourceInfo,
            existingFileLocation: f.fileLocation,
            sheets: sheetOptions,
          });
        }
      }
    }
    fetchSheets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFileName]);

  const onClose = () => {
    if (filePropertiesDialog?.useSFTP) {
      setFilePropertiesDialog({
        useSFTP: false,
        uploadedSFTPFile: null,
        selectedSFTPFile: null,
        selectedSFTPFileSheetData: [],
      });
      setSFTPInputProperties({
        popup: false,
        loading: false,
      });
      history.replace(`/data-flows/${dataflowId}/editor`);
    }
    setValidationError({});
    setFilePropertiesDialog({
      popup: false,
      loading: false,
      saving: false,
      sheets: [],
      reloadFiles: false,
    });
    setSheetData({ sheetName: '', dataStartAt: '', numHeaders: '' });
    setHeaderError('');
    setSelectedFileName('');
    setFileNames([]);
  };

  const onTaxPeriodComboChange = value => {
    setFileDialogTaxPeriod(value);
  };

  const onSheetComboChange = e => {
    const { value } = e;
    setSheetData(prev => ({ ...prev, sheetName: value }));
  };

  const onSheetValueChange = e => {
    const { name, value } = e.target;
    setSheetData(prev => ({ ...prev, [name]: value.toString().toUpperCase() }));
  };

  const onFileNameChange = f => {
    if (f && f.value) {
      setSelectedFileName(f);
      const fileExtension = f.value.split('.').pop();
      const isCsvFile = fileExtension === 'csv';
      setFilePropertiesDialog({ ...filePropertiesDialog, isCsvFile: isCsvFile });
    }
  };
  const isValidHeaderInput = str => {
    return onlyNumberVals(str);
  };

  const onlyNumberVals = str => {
    const pattern = /^[0-9]+$/;
    return str.match(pattern);
  };

  const filePropertiesHaveNotChanged = () => {
    const editHeaderRows = filePropertiesDialog?.savedValues?.headerRows || '1';
    const editHeadersStartAt = filePropertiesDialog?.savedValues?.headersStartAt || 'A1';

    const dialogHeaderRows = sheetData.numHeaders || '1';
    const dialogHeadersStartAt = sheetData.dataStartAt || 'A1';

    return (
      filePropertiesDialog?.savedValues?.sheetName === sheetData.sheetName &&
      editHeaderRows === dialogHeaderRows &&
      editHeadersStartAt === dialogHeadersStartAt
    );
  };

  const getNameAndTaxPeriodError = (name, taxPeriod) => {
    return `There is already a file named ‘${name}’ for tax period ‘${taxPeriod}’. You can either rename this version, select a different tax period, or press cancel to browse to the existing version.`;
  };

  const onFileSubmit = async () => {
    if (filePropertiesDialog?.useSFTP) {
      setValidationError({});
      setFilePropertiesDialog({
        popup: false,
        loading: false,
        saving: false,
        sheets: [],
        reloadFiles: false,
      });
      setHeaderError('');
      setSelectedFileName('');
      setFileNames([]);
    }
    setValidationError({});
    setGeneralError({ message: undefined });

    const fileNameError = 'File name is required.';
    //when the file is already in use and user is uploading new files
    if (!!fileNameInUse && !filePropertiesDialog.uploadExistingFile && !filePropertiesDialog.updateSourceFile) {
      const nameAndTaxPeriodError = getNameAndTaxPeriodError(fileNameInUse, fileDialogTaxPeriod);
      setValidationError({ nameAndTaxPeriod: nameAndTaxPeriodError });
      return;
    }

    //when fileName field is empty and user is uploading new files
    if (!fileName && !filePropertiesDialog.uploadExistingFile && !filePropertiesDialog.updateSourceFile) {
      setValidationError({ name: fileNameError });
      return;
    }

    if (filePropertiesDialog.uploadExistingFile && !selectedFileName) {
      setValidationError({ fileName: fileNameError });
      return;
    }

    if (
      !sheetData?.sheetName &&
      filePropertiesDialog?.sheets?.length > 1 &&
      !filePropertiesDialog.isCsvFile &&
      !filePropertiesDialog.updateSourceFile
    ) {
      setValidationError({
        sheetName: 'Sheet is required',
      });
      return;
    }

    if (sheetData.dataStartAt && !isValidCellFormat(sheetData.dataStartAt)) {
      setValidationError({
        dataStartAt:
          'Invalid cell reference. Valid cell references start with one or more letters and are followed by one or more numbers.',
      });
      return;
    }

    if (sheetData.numHeaders && !isValidHeaderInput(sheetData.numHeaders)) {
      setValidationError({
        numHeaders: 'Number of column header rows is invalid.',
      });
      return;
    }

    setFilePropertiesDialog({ ...filePropertiesDialog, saving: true });

    let payload = {
      ...filePropertiesDialog.sourceInfo,
      sheetData: {
        ...sheetData,
        sheetName: sheetData?.sheetName || '',
      },
      systemCode: filePropertiesDialog?.systemCode ?? 'wkp',
    };
    // create new payload only for exsiting file
    if (filePropertiesDialog.uploadExistingFile) {
      payload = {
        ...payload,
        sourceData: { ...filePropertiesDialog.sourceInfo.sourceData, name: selectedFileName.value },
        updateSourceFile: false,
        taxPeriod: fileDialogTaxPeriod,
        fileLocationId: filePropertiesDialog?.existingFileLocation?.id,
      };
    } else {
      payload = {
        ...payload,
        sourceData: { ...filePropertiesDialog.sourceInfo.sourceData, name: fileName.concat(fileExtension) },
        updateSourceFile: filePropertiesDialog?.updateSourceFile,
        taxPeriod: fileDialogTaxPeriod,
        sourceFileId: filePropertiesDialog?.sourceFileId,
      };
    }
    if (filePropertiesDialog?.uploadedSFTPFile) {
      const { bucket, fileKey } = filePropertiesDialog?.uploadedSFTPFile?.uploadFiles[0].fileLocation;
      const name = fileName.concat(fileExtension);

      const { bucket: updatedBucket, key: updatedFileKey } = await copyObjectBetweenBuckets({
        sourceKey: `${bucket}/${fileKey}`,
        fileName: name,
      });

      payload = {
        ...payload,
        sourceData: {
          bucket: updatedBucket,
          path: updatedFileKey,
          name: name,
        },
      };

      setOpenSourceFile(null);
      //Deleting SFTP object in sftp bucket
      await addDeleteMarketOnSFTPObject(fileKey);
    }

    if (!sheetData.sheetName && filePropertiesDialog?.sheets?.length > 0) {
      payload = {
        ...payload,
        sheetData: {
          ...sheetData,
          sheetName: filePropertiesDialog.sheets[0].value || '',
        },
      };
    }

    let sourceToCsv = null;

    try {
      sourceToCsv = await processSourceFileToCsv(payload);
      const { metadata, sourceFileVersionId } = sourceToCsv;
      const { inputId, elementData } = filePropertiesDialog.sourceInfo;
      const { sourceFields } = elementData;
      const { header } = metadata;
      //we need to re render input source files when we edit source files
      setFilePropertiesDialog({
        ...filePropertiesDialog,
        saving: true,
        reloadFiles: true,
      });
      const sourceHeader = sourceFields?.reduce((arr, val) => [...arr, val.name], []);
      // Check if new uploaded file headers are same as old source headers regardless of order
      const sameFieldNames = (header, sourceHeader) => {
        if (header?.length !== sourceHeader?.length) return false;
        const fieldNames = new Set([...header, ...sourceHeader]);
        for (const field of fieldNames) {
          const headerCount = header.filter(name => name === field).length;
          const sourceCount = sourceHeader.filter(name => name === field).length;
          if (headerCount !== sourceCount) return false;
        }
        return true;
      };
      // If there is a configuration we will validate that the fields have a match
      if (
        !filePropertiesDialog?.updateSourceFile &&
        dataFlowState.links.some(link => link.sourceId === inputId) &&
        !sameFieldNames(header, sourceHeader)
      ) {
        if (isFeatureFlagEnabled(FIND_AND_REPLACE_PROMPT) && filePropertiesDialog.sourceInfo.elementData.sourceFields) {
          // if sourceFields has fields but elementData.fields is undefined the file was deleted
          const oldFields =
            dataFlowState.workingElement.elementData.sourceFields && !dataFlowState.workingElement.elementData.fields
              ? dataFlowState.workingElement.elementData.sourceFields
              : dataFlowState.workingElement.elementData.fields;

          setFindAndReplacePropDialog({
            show: true,
            newFields: sourceToCsv.metadata.fields,
            oldFields: oldFields,
            inputId: filePropertiesDialog.sourceInfo.inputId,
            elementData: filePropertiesDialog.sourceInfo.elementData,
            fileVersionId: sourceToCsv.sourceFileVersionId,
          });
        } else {
          const { id: dataFlowId, workingElement } = dataFlowState;

          await validateSourceFileFields(dataFlowId, metadata.header, workingElement.id);
          //  display Fields
          setElementData(inputId, {
            ...elementData,
            fields: metadata.fields,
            pendingSourceFileVersionId: sourceFileVersionId,
          });
        }
      } else {
        // if source file is being updated:
        if (filePropertiesDialog?.updateSourceFile) {
          // enable save and save and publish so we can save new dataflow configuration
          setSaveStateDirty(true);
          resetSaveMenuDirty();
        }
        //  display Fields
        setElementData(inputId, {
          ...elementData,
          fields: metadata.fields,
          pendingSourceFileVersionId: sourceFileVersionId,
          integrationType: filePropertiesDialog?.systemCode?.toUpperCase() ?? 'WKP',
          isSourceFileDeleted: undefined,
        });
      }
      onClose();
    } catch (error) {
      if (error.code) {
        const { code, message } = error;

        switch (code) {
          case 'GN0006':
            setHeaderError('');
            setValidationError({ [error.target]: message });
            setFilePropertiesDialog({ ...filePropertiesDialog, saving: false });
            break;
          case 'WKP2015':
            setFilePropertiesDialog({ ...filePropertiesDialog, saving: false });
            setHeaderError(message);
            break;
          default:
            setGeneralError({ message: getErrorMessage(code) });
            onClose();
        }
      } else {
        setGeneralError({ message: error.message });
        onClose();
      }
    }
  };

  const validateSourceFileExistence = async (isInitialValidation, { name, extension, taxPeriodValue }) => {
    setFileNameInUse(null);
    const nameParam = isInitialValidation ? name : fileName;
    const taxPeriodParam = isInitialValidation ? taxPeriodValue : fileDialogTaxPeriod;
    const fileExtensionParam = isInitialValidation ? extension : fileExtension;
    if (!nameParam && !isInitialValidation && !!filePropertiesDialog?.sourceInfo) {
      setValidationError({ name: 'File name is required.' });
    }
    if (!!nameParam && !!taxPeriodParam) {
      const completeFileName = nameParam.concat(fileExtensionParam);
      const validationResult = await validateSourceFile(completeFileName, taxPeriodParam);
      if (!validationResult.isValid) {
        const nameAndTaxPeriodError = getNameAndTaxPeriodError(completeFileName, taxPeriodParam);
        setValidationError({ nameAndTaxPeriod: nameAndTaxPeriodError });
        setFileNameInUse(completeFileName);
      }
    }
  };

  useEffect(() => {
    setValidationError({});
    if (!filePropertiesDialog?.updateSourceFile && !filePropertiesDialog?.uploadExistingFile) {
      validateSourceFileExistence(false, {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileDialogTaxPeriod, fileName, filePropertiesDialog?.updateSourceFile]);

  const displayFileDialogWithFindAndReplace = () => {
    if (dataFlowState.findAndReplaceDialog?.show) {
      return (
        <UpdateFieldNameReferenceDialog
          filePropertiesDialog={filePropertiesDialog}
          setElementData={setElementData}
          setFilePropertiesDialog={setFilePropertiesDialog}
          setState={setFindAndReplacePropDialog}
          state={findAndReplaceDialog}
          onClose={onClose}
        />
      );
    } else {
      return displayFileDialog();
    }
  };
  const displayFileDialog = () => {
    return (
      <BTModal show={filePropertiesDialog?.popup} size="modal-lg" title="File Properties" onCloseClick={onClose}>
        <BTModal.Body>
          <BTForm>
            {!filePropertiesDialog?.uploadExistingFile && (
              <BTForm.FormGroup
                required
                errorText={validationError.name ?? validationError.nameAndTaxPeriod}
                hasError={!!validationError.name || !!validationError.nameAndTaxPeriod}
                label="File name"
              >
                <div className="wkp-filename-input-group">
                  <BTInput
                    disabled={filePropertiesDialog?.loading || filePropertiesDialog?.updateSourceFile}
                    hasError={!!validationError.name || !!validationError.nameAndTaxPeriod}
                    name="fileName"
                    value={fileName}
                    onChange={e => {
                      setFileName(e.target.value);
                    }}
                  />
                  <BTInput disabled={true} name="fileExtension" size="small" value={fileExtension} />
                </div>
              </BTForm.FormGroup>
            )}
            {!filePropertiesDialog?.uploadExistingFile && (
              <TaxPeriodInput
                disabled={filePropertiesDialog?.updateSourceFile}
                errorText={!!validationError.nameAndTaxPeriod ? '' : []}
                hasError={!!validationError.nameAndTaxPeriod}
                isClearable={false}
                showSmallText={false}
                value={fileDialogTaxPeriod}
                onChange={onTaxPeriodComboChange}
              />
            )}

            {filePropertiesDialog?.uploadExistingFile && (
              <TaxPeriodInput
                isClearable={false}
                showSmallText={false}
                value={fileDialogTaxPeriod}
                onChange={onTaxPeriodComboChange}
              />
            )}

            {filePropertiesDialog?.uploadExistingFile && (
              <BTForm.FormGroup
                required
                errorText={validationError.fileName}
                hasError={!!validationError.fileName}
                label="File name"
              >
                <BTComboBox
                  id="FileName"
                  name="FileName"
                  noResultsMessage="No source files have been uploaded to this tax period"
                  options={fileNames}
                  value={selectedFileName}
                  onChange={onFileNameChange}
                ></BTComboBox>
              </BTForm.FormGroup>
            )}

            {!filePropertiesDialog?.uploadExistingFile
              ? !filePropertiesDialog?.isCsvFile && (
                  <SheetList
                    hasError={!!validationError.sheetName}
                    isRequired={true}
                    loading={filePropertiesDialog?.loading}
                    sheetData={sheetData}
                    sheets={filePropertiesDialog?.sheets}
                    onChange={onSheetComboChange}
                  />
                )
              : !filePropertiesDialog?.isCsvFile && (
                  <SheetList
                    disabled={!selectedFileName}
                    hasError={!!validationError.sheetName}
                    isRequired={true}
                    loading={filePropertiesDialog?.loading}
                    sheetData={sheetData}
                    sheets={filePropertiesDialog?.sheets}
                    onChange={onSheetComboChange}
                  />
                )}

            <BTForm.FormGroup
              errorText={validationError.dataStartAt}
              hasError={!!validationError.dataStartAt}
              htmlFor="dataStartAt"
              label="Column headers start at"
            >
              <BTInput
                disabled={filePropertiesDialog?.loading}
                id="dataStartAt"
                name="dataStartAt"
                placeholder="e.g A6"
                value={sheetData.dataStartAt}
                onChange={onSheetValueChange}
              />
            </BTForm.FormGroup>
            <BTForm.FormGroup
              errorText={validationError.numHeaders}
              hasError={!!validationError.numHeaders}
              htmlFor="numHeaders"
              label="Number of column header rows"
            >
              <BTInput
                disabled={filePropertiesDialog?.loading}
                id="numHeaders"
                name="numHeaders"
                placeholder="1"
                value={sheetData.numHeaders}
                onChange={onSheetValueChange}
              />
            </BTForm.FormGroup>
          </BTForm>
          {headerError.length > 0 && <p style={{ color: 'red' }}>{headerError}</p>}
        </BTModal.Body>
        <BTModal.Footer>
          <BTButton onClick={onClose}>Cancel</BTButton>
          <BTButton
            btStyle="primary"
            disabled={
              filePropertiesDialog?.loading ||
              filePropertiesDialog?.saving ||
              (filePropertiesDialog?.uploadExistingFile && !fileDialogTaxPeriod) ||
              (filePropertiesHaveNotChanged() &&
                !filePropertiesDialog?.uploadExistingFile &&
                fileName.concat(fileExtension) === filePropertiesDialog?.fileName)
            }
            hasSpinner={filePropertiesDialog?.saving}
            id="df-save-file-settings"
            onClick={onFileSubmit}
          >
            Save
          </BTButton>
        </BTModal.Footer>
      </BTModal>
    );
  };

  if (isFeatureFlagEnabled(FIND_AND_REPLACE_PROMPT)) {
    return displayFileDialogWithFindAndReplace();
  } else {
    return displayFileDialog();
  }
};

export default FileDialog;
