import { format, isMatch, isValid } from 'date-fns';
import { supportedInputElementTypes } from './supportedInputElementTypes';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import {
  IMPROVE_DATAFLOW_PREVIEW,
  INPUT_OUTPUT_TOGGLE,
  ENABLE_FIELD_NAME_HASHING,
} from '../../../../constants/featureFlags';
import { INPUT } from '../../DataFlowEditorPage/elementType/types/shared/typesConstants';

const hasInputConnected = (links, configuration, activeElement) => {
  const hasLinks = links && Object.values(links).filter(link => link?.targetId === activeElement?.id).length === 1;
  return configuration?.connections.length > 0 || hasLinks;
};

export function checkToggleIOState({ activeElement, configuration, links }, previewInputRecords) {
  return isFeatureFlagEnabled(INPUT_OUTPUT_TOGGLE)
    ? activeElement &&
        supportedInputElementTypes.includes(activeElement.type) &&
        hasInputConnected(links, configuration, activeElement) &&
        activeElement?.elementData &&
        previewInputRecords?.length > 0
    : false;
}

export function formatOutputRecords(previewFields, previewRecords) {
  const formattedPreviewRecords = previewRecords ? JSON.parse(JSON.stringify(previewRecords)) : [];

  formattedPreviewRecords.forEach(record => {
    for (const [key, value] of Object.entries(record)) {
      if (value === null) {
        record[key] = '';
      }
    }
  });

  const previewDateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
  const recordTypes = {};
  for (const ind in previewFields) {
    const {
      name,
      type: fieldType,
      date_format: dateFormat,
      original_name: originalName,
      raw_format: rawFormat,
    } = previewFields[ind];
    const fieldName = originalName || name;
    if (fieldType === 'date') {
      formattedPreviewRecords?.forEach(record => {
        const dateRecord = record[fieldName];
        const recordAsDate = new Date(dateRecord);
        const dateOffsetUTC = new Date(recordAsDate.getTime() + recordAsDate.getTimezoneOffset() * 60000);
        if (dateRecord && isMatch(dateRecord, previewDateFormat) && isValid(recordAsDate)) {
          record[fieldName] = dateFormat ? format(dateOffsetUTC, dateFormat) : format(dateOffsetUTC, 'MM/dd/yyyy');
        }
      });
    }

    if (isFeatureFlagEnabled(IMPROVE_DATAFLOW_PREVIEW)) {
      recordTypes[fieldName] = { type: fieldType, format: 'General' };
      if (fieldType === 'numeric') {
        if (rawFormat) {
          recordTypes[fieldName].format = rawFormat;
        } else {
          const floatFound = formattedPreviewRecords?.find(record => Math.abs(record[fieldName]) % 1 > 0);
          recordTypes[fieldName].format = floatFound ? '#,##0.00;-#,##0.00' : '#,##0;-#,##0';
        }
      }
    }
  }

  if (isFeatureFlagEnabled(IMPROVE_DATAFLOW_PREVIEW)) {
    return { records: formattedPreviewRecords, recordTypes };
  }

  return formattedPreviewRecords;
}

export function getOutputRecords({ activeElement, workingElement }, previewRecords) {
  const containsNewSourceFiles = workingElement?.elementData?.containsNewSourceFiles;
  //input block is active and does not have new source files
  if (containsNewSourceFiles !== undefined && !containsNewSourceFiles && activeElement?.type === INPUT) {
    return [];
  }

  return previewRecords;
}

export function getColumns(activeElement, elementData) {
  const elementType = activeElement?.elementType;

  if (!elementType) {
    return [];
  } else {
    if (isFeatureFlagEnabled(ENABLE_FIELD_NAME_HASHING)) {
      return elementType.getPreviewColumns(elementData ? elementData : activeElement.elementData);
    } else {
      return elementType.getPreviewColumns(activeElement.elementData);
    }
  }
}

export function getDefaultToggleIO(activeElement, hasErrors) {
  // Default to input if the user has not configured the block or the output preview has errors
  return hasErrors || !isElementConfigured(activeElement) ? 'T' : 'F';
}

export function objToHeadersWithoutRecords(array) {
  return new Array(
    array.reduce((prev, ele) => {
      const header = ele?.original_name ? ele.original_name : ele.name;
      prev[header] = '';
      return prev;
    }, {})
  );
}

// Return if the user has configured the block
function isElementConfigured(activeElement) {
  switch (activeElement?.type) {
    case 'aggregate':
      return !(
        activeElement?.elementData?.groupBy.length === 0 &&
        (activeElement?.elementData?.fieldsToAggregate.length === 0 ||
          activeElement?.elementData?.fieldsToAggregate[0].field === '')
      );
    case 'filter':
      return !(activeElement?.elementData?.formula === '');
    case 'formula':
      return !(activeElement?.elementData?.formula === '' || activeElement?.elementData?.outputFieldName === '');
    default:
      return false;
  }
}
