import GC from '../../../../../../SpreadSheets';
import { validateJurisdiction, validatePeriodStartDate } from '../utils';
import { isWithinInterval } from 'date-fns';
import { transformIntoString } from '../stateTaxRateFormulaHelper';
import { v4 as uuidv4 } from 'uuid';
import { STATE_TAX_RATE } from '../../../../../_shared/DataReference/ReferenceType';
import { isNumeric } from '../formulas';

export const stateTaxRateFormulaName = 'STATETAXRATE';

const name = stateTaxRateFormulaName;

const parameters = [
  {
    name: '"jurisdiction"',
    repeatable: false,
    optional: false,
  },
  {
    name: '"period_start_date"',
    repeatable: false,
    optional: false,
  },
  {
    name: 'taxable_income',
    repeatable: false,
    optional: true,
  },
];

const description =
  'Retrieves state tax rates from the tax law database. Taxable income parameter is optional. For states with graduated income tax, if no taxable income is entered then the tax rate for the highest income bracket is returned; if taxable income is entered, then the effective rate is computed.';
const evaluationFunction = (args, data) => {
  const [context, jurisdiction, periodStartDate, taxableIncome] = args;
  const hasToolTipContent = setTaxRateTooltipMessage(
    context,
    data.stateTaxJurisdictions,
    jurisdiction,
    transformIntoString(periodStartDate),
    taxableIncome
  );
  if (!hasToolTipContent) {
    const { row, column } = context;
    const sheet = context.source.getSheet();
    const sheetName = sheet.name();
    const cellTag = data.getCellReferenceTag(sheet, row, column);
    const formula = `=${sheet.getFormula(row, column)}`;

    const requestParameters = {
      row,
      column,
      jurisdiction,
      periodStartDate,
      taxableIncome,
      type: STATE_TAX_RATE,
      parameters: {
        Jurisdiction: jurisdiction,
        PeriodStartDate: periodStartDate,
        TaxableIncome: taxableIncome,
      },
      value: undefined,
      sheetName,
    };

    const hasCellTag =
      cellTag?.references?.length && cellTag?.references?.filter(x => x.type === STATE_TAX_RATE)?.length;
    const referenceExistInTargetCell = data.referenceExistInTargetCell(row, column, sheetName, STATE_TAX_RATE);
    if (!hasCellTag || data.isDragFillAction.current.isActive) {
      if (referenceExistInTargetCell) {
        requestParameters['id'] = referenceExistInTargetCell.id;
        data.enqueueDataReferenceReCalc(requestParameters);
      } else {
        requestParameters['id'] = uuidv4();
        data.enqueueDataReference(requestParameters);
      }
    } else if (hasCellTag) {
      const reference = cellTag.references.find(reference => reference.type === STATE_TAX_RATE);
      const referenceId = referenceExistInTargetCell ? referenceExistInTargetCell.id : reference.id;
      if (!reference) {
        requestParameters['id'] = uuidv4();
        data.enqueueDataReference(requestParameters);
      } else {
        const referenceExistInWorkSheet = data.referenceExistInWorksheet(referenceId, sheetName);
        const referenceIsEnqueuedForRecalc = data.referenceIsEnqueuedForRecalc(row, column, sheetName);
        requestParameters['id'] = referenceId;
        const formulaMatch = data.isFormulaMatch(requestParameters);
        const cellChangedData =
          data.cellChangedData?.current &&
          (data.cellChangedData?.current?.row !== row ||
            data.cellChangedData?.current.col !== column ||
            data.cellChangedData?.current?.editor?.Li !== formula);
        if (!referenceExistInWorkSheet || data.isCopyPasteAction.current.isActive) {
          if (
            referenceExistInTargetCell &&
            data.isCopyPasteAction.current.isActive &&
            data.isCopyPasteAction.current.sameSheet &&
            data.isCopyPasteAction.current.entireSheet
          ) {
            return formatFormulaReturnValue(sheet.getValue(row, column), sheet, row, column, data);
          }
          requestParameters['id'] = uuidv4();
          data.enqueueDataReference(requestParameters);
        } else if ((!formulaMatch && referenceExistInTargetCell) || cellChangedData || referenceIsEnqueuedForRecalc) {
          data.enqueueDataReferenceReCalc(requestParameters);
          data.cellChangedData.current = null;
        }
      }
    }
    return formatFormulaReturnValue(
      data.renderCustomFormulaValues(cellTag, row, column, STATE_TAX_RATE, sheetName),
      sheet,
      row,
      column,
      data
    );
  }
  return GC.Spread.CalcEngine.Errors.NotAvailable;
};

function formatFormulaReturnValue(value, sheet, row, column, data) {
  if (value === '') {
    return GC.Spread.CalcEngine.Errors.NotAvailable;
  } else if (isNumeric(value)) {
    const currentFormat = sheet.getFormatter(row, column);
    const format = Number.isInteger(Number(value) * 100) ? '0%' : '0.00%';
    if (!currentFormat) {
      sheet.setFormatter(row, column, format);
    } else {
      sheet.setFormatter(row, column, currentFormat);
    }
  }
  return value ? value : data.defaultValue;
}

export const stateTaxRateFormula = (gcSyncFunction, data) =>
  new gcSyncFunction({
    name,
    minArgs: 2,
    maxArgs: 3,
    descriptionData: {
      name,
      description,
      parameters,
    },
    evaluationFunction,
    data,
  });

function setTaxRateTooltipMessage(context, jurisdictions, jurisdiction, periodStartDate, taxableIncome) {
  const sheet = context.source.getSheet();
  // TODO: Refactor to generate tooltip content based on error message returned from API

  const tooltipContentHeader = `Unable to retrieve state tax rate because of the following error: \n <ul style="text-align: left;">`;
  let tooltipContent = '';

  tooltipContent = tooltipContent.concat(checkPeriodDate(periodStartDate), checkTaxableIncome(taxableIncome));

  try {
    const jurisdictionTooltipContent = checkJurisdiction(jurisdictions, jurisdiction);
    tooltipContent = tooltipContent.concat(jurisdictionTooltipContent);
    if (tooltipContent !== '') {
      tooltipContent = tooltipContentHeader + tooltipContent + `</ul>`;
      const { row, col } = context;
      sheet.setWkpCellTag(row, col, { stateTaxRateTooltip: tooltipContent });
    }
  } catch (error) {
    console.error(error);
  }
  return tooltipContent !== '';
}

function checkJurisdiction(jurisdictions, jurisdiction) {
  const isJurisdictionValid = jurisdictions.current && validateJurisdiction(jurisdictions.current, jurisdiction);
  return !isJurisdictionValid ? '\n <li>Jurisdiction is invalid.</li>' : '';
}

function checkPeriodDate(periodStartDate) {
  let validationMessage = '';
  const startDateRange = '01/01/2010';
  const endDateRange = '12/31/2099';

  if (periodStartDate === '' || periodStartDate === null) {
    validationMessage = `\n <li>Period start date is required.</li>`;
  } else {
    const isPeriodStartDateValid = validatePeriodStartDate(periodStartDate);
    const isDateOutOfRange = !isWithinInterval(new Date(periodStartDate), {
      start: new Date(startDateRange),
      end: new Date(endDateRange),
    });

    if (!isPeriodStartDateValid) {
      validationMessage = `\n <li>Period start date is invalid.</li>`;
    } else if (isDateOutOfRange) {
      validationMessage = `\n <li>Period start date is out of range. Rates are available for ${startDateRange} to ${endDateRange}.</li>`;
    }
  }

  return validationMessage;
}

function checkTaxableIncome(taxableIncome) {
  let validationMessage = '';

  if (typeof taxableIncome === 'string' || taxableIncome < 0 || taxableIncome === null) {
    validationMessage = `\n <li>Taxable income is invalid.</li>`;
  }

  return validationMessage;
}
