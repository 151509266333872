import GC from '../../../../../../SpreadSheets';
import { getFiltersFromDynamicArguments } from '../sourceDataFormulaHelper';
import { SOURCE_DATA_CONNECTION } from '../../../../../_shared/DataReference/ReferenceType';
import { isNumeric } from '../formulas';
import { v4 as uuidv4 } from 'uuid';

export const sourceDataFormulaName = 'SOURCE_DATA';

const name = sourceDataFormulaName;

const parameters = [
  {
    name: 'output',
    repeatable: false,
    optional: false,
  },
  {
    name: 'output_field',
    repeatable: false,
    optional: false,
  },
  {
    name: 'filter_field1, filter_value1',
    repeatable: true,
    optional: true,
  },
];

const description = 'Retrieves source data from a data transformation workflow.';

const evaluationFunction = (args, data) => {
  const [context, output, outputField, ...rawFilters] = args;
  const { row, column } = context;
  const sheet = context.source.getSheet();
  const sheetName = sheet.name();
  const cellTag = data.getCellReferenceTag(sheet, row, column);
  const formula = `=${sheet.getFormula(row, column)}`;
  const filters = getFiltersFromDynamicArguments(rawFilters);
  const requestParameters = {
    output,
    row,
    column,
    filters,
    type: SOURCE_DATA_CONNECTION,
    outputField,
    parameters: {
      OutputId: output,
      Filters: filters ? JSON.stringify(filters) : undefined,
      OutputField: outputField,
    },
    value: undefined,
    sheetName,
  };
  const hasCellTag =
    cellTag?.references?.length && cellTag?.references?.filter(x => x.type === SOURCE_DATA_CONNECTION)?.length;
  const referenceExistInTargetCell = data.referenceExistInTargetCell(row, column, sheetName, SOURCE_DATA_CONNECTION);
  if (!hasCellTag || data.isDragFillAction.current.isActive) {
    if (referenceExistInTargetCell) {
      requestParameters['id'] = referenceExistInTargetCell.id;
      data.enqueueDataReferenceReCalc(requestParameters);
    } else {
      requestParameters['id'] = uuidv4();
      data.enqueueDataReference(requestParameters);
    }
  } else if (hasCellTag) {
    const reference = cellTag.references.find(reference => reference.type === SOURCE_DATA_CONNECTION);
    const referenceId = referenceExistInTargetCell ? referenceExistInTargetCell.id : reference.id;
    if (!reference) {
      requestParameters['id'] = uuidv4();
      data.enqueueDataReference(requestParameters);
    } else {
      const referenceExistInWorkSheet = data.referenceExistInWorksheet(referenceId, sheetName);
      const referenceIsEnqueuedForRecalc = data.referenceIsEnqueuedForRecalc(row, column, sheetName);
      requestParameters['id'] = referenceId;
      const formulaMatch = data.isFormulaMatch(requestParameters);
      const cellChangedData =
        data.cellChangedData?.current &&
        (data.cellChangedData?.current?.row !== row ||
          data.cellChangedData?.current.col !== column ||
          data.cellChangedData?.current?.editor?.Li !== formula);
      if (!referenceExistInWorkSheet || data.isCopyPasteAction.current.isActive) {
        if (
          referenceExistInTargetCell &&
          data.isCopyPasteAction.current.isActive &&
          data.isCopyPasteAction.current.sameSheet &&
          data.isCopyPasteAction.current.entireSheet
        ) {
          return formatFormulaReturnValue(sheet.getValue(row, column), data);
        }
        requestParameters['id'] = uuidv4();
        data.enqueueDataReference(requestParameters);
      } else if ((!formulaMatch && referenceExistInTargetCell) || cellChangedData || referenceIsEnqueuedForRecalc) {
        data.enqueueDataReferenceReCalc(requestParameters);
        data.cellChangedData.current = null;
      }
    }
  }

  return formatFormulaReturnValue(
    data.renderCustomFormulaValues(cellTag, row, column, SOURCE_DATA_CONNECTION, sheetName),
    data
  );
};

function formatFormulaReturnValue(value, data) {
  if (value === '') {
    return GC.Spread.CalcEngine.Errors.NotAvailable;
  }
  return value ? (isNumeric(value) ? Number(value) : value) : data.defaultValue;
}

export const sourceDataFormula = (gcSyncFunction, data) =>
  new gcSyncFunction({
    name,
    minArgs: 2,
    maxArgs: 256,
    descriptionData: {
      name,
      description,
      parameters,
    },
    evaluationFunction,
    data,
  });
