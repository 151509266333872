import React, { useContext, useEffect } from 'react';
import { BTButton } from '@btas/jasper';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { DF_INPUT_FA_OPTION, DF_INPUT_SFTP_OPTION, WKP_INPUT_FILE_IMPORT } from '../../../../constants/featureFlags';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { isValidFAUser } from './apis';
import { faLearnMoreLink } from '../../../../configs/params';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';
import WKPInputFileImport from './WKPInputFileImport';
import {
  FA_INTEGRATION_TYPE,
  SFTP_INTEGRATION_TYPE,
  WKP_INTEGRATION_TYPE,
  WKP_IMPORT_INTEGRATION_TYPE,
} from './constants';
import GeneralErrorMessage from '../../../_shared/GeneralErrorMessage';

const InputOptionsSubInspector = ({ updateData }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { dataFlowState, dataFlowActions, showConfirmationModal, sourceFileUpload } = useContext(DataFlowEditorContext);
  const { setFAInputProperties, setSFTPInputProperties } = dataFlowActions;
  const { faInputProperties, sftpInputProperties } = dataFlowState;
  const { uploadToS3Error, setUploadToS3Error } = sourceFileUpload;

  useEffect(() => {
    updateData({ integrationType: '' });
  }, [updateData]);

  useEffect(() => {
    if (uploadToS3Error) {
      //Automatically dismiss generic error message after 5 seconds
      setTimeout(() => {
        setUploadToS3Error(undefined);
      }, 5000);
    }
  }, [uploadToS3Error, setUploadToS3Error]);

  const updateIntegrationType = async type => {
    if (type === FA_INTEGRATION_TYPE) {
      const hasPermission = await isValidFAUser();
      if (!hasPermission) {
        showConfirmationModal(
          'Unable to Connect to Bloomberg Tax Fixed Assets',
          'Either you have not purchased Bloomberg Tax Fixed Assets or this feature is not available at your service level.',
          'LEARN MORE',
          'Cancel',
          '',
          () => {
            window.open(faLearnMoreLink, '_blank', 'noreferrer');
          }
        );
        return;
      } else {
        updateData({ integrationType: type });
        setFAInputProperties({ ...faInputProperties, showDataType: false });
      }
    }
    if (type === SFTP_INTEGRATION_TYPE) {
      updateData({ integrationType: type });
      setSFTPInputProperties({ ...sftpInputProperties, showDataType: false });
    }
    history.push(`${url}/${type}`);
  };
  const canEditWorkflow = useCanEditWorkflow();
  return (
    <>
      <div className="wkp-input-element-inspector">
        <h5>Data Source</h5>
        <div>
          {canEditWorkflow && !isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) && (
            <BTButton
              btStyle="default"
              className={'wkp-df-inputBlock-buttons'}
              id="uploadWKPFileBtn"
              name="uploadWKPFileBtn"
              onClick={() => updateIntegrationType(WKP_INTEGRATION_TYPE)}
            >
              Upload a file (.xlsx, .csv)
            </BTButton>
          )}

          {canEditWorkflow && isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) && (
            <WKPInputFileImport updateIntegrationType={() => updateIntegrationType(WKP_IMPORT_INTEGRATION_TYPE)} />
          )}

          {canEditWorkflow && isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) && (
            <BTButton
              btStyle="default"
              className={'wkp-df-inputBlock-buttons'}
              id="attach-existing-source-file-btn"
              name="uploadFAFileBtn"
            >
              Attach Existing Source File
            </BTButton>
          )}
          {canEditWorkflow && isFeatureFlagEnabled(DF_INPUT_FA_OPTION) && (
            <BTButton
              btStyle="default"
              className={'wkp-df-inputBlock-buttons'}
              id="uploadFAFileBtn"
              name="uploadFAFileBtn"
              onClick={() => updateIntegrationType(FA_INTEGRATION_TYPE)}
            >
              Bloomberg Tax Fixed Assets
            </BTButton>
          )}

          {canEditWorkflow && isFeatureFlagEnabled(DF_INPUT_SFTP_OPTION) && (
            <BTButton
              btStyle="default"
              className={'wkp-df-inputBlock-buttons'}
              id="uploadSftpFileBtn"
              name="uploadSftpFileBtn"
              onClick={() => updateIntegrationType(SFTP_INTEGRATION_TYPE)}
            >
              Secure File Transfer Protocol (SFTP)
            </BTButton>
          )}
        </div>
      </div>

      {isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) && (
        <GeneralErrorMessage dismissible={true} visible={!!uploadToS3Error} />
      )}
    </>
  );
};

export default InputOptionsSubInspector;
