import { useContext } from 'react';
import UserPermissionsContext from '../../_shared/UserPermissionsContext';
import { getUserId } from '../../_shared/auth';

export function CanDeleteWorkflow({ onYes, onNo = () => null }) {
  const { userPermissions } = useContext(UserPermissionsContext);

  const check = function () {
    return userPermissions.includes(['delete_workflow_self', 'delete_workflow_others']);
  };

  return check() ? onYes() : onNo();
}

export function getWorkflowsUserCanDelete(workflowIdsToDelete, workflows, userPermissions) {
  const userId = getUserId();

  const deleteSet = new Set(workflowIdsToDelete);

  const workflowsToDelete = workflows.filter(workflow => {
    if (!userPermissions.includes('delete_workflow_self')) {
      return false;
    }
    if (!userPermissions.includes('delete_workflow_others')) {
      return workflow.userId === userId && deleteSet.has(workflow.id);
    }
    return true;
  });

  return new Map(workflowsToDelete.map(i => [i.id, i]));
}

export default CanDeleteWorkflow;
