import React, { useContext, useRef, useMemo, useEffect } from 'react';
import { DataFlowEditorContext } from './DataFlowEditorContext';
import { useInputElements } from './DataFlowInspector/useInputElements';
import './DataFlowInspector/styles.scss';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_DATAFLOW_SIDEBAR } from '../../../constants/featureFlags';

const DataFlowInspector = ({ callback }) => {
  const { dataFlowState, dataFlowActions } = useContext(DataFlowEditorContext);
  const { activeElement, workingElementIsDirty, workingElement } = dataFlowState;
  const { updateWorkingElement } = dataFlowActions;
  const elementType = activeElement?.elementType;
  const ElementInspector = elementType ? elementType.inspectorComponent : null;

  const inspectorContainer = useRef(null);

  // Find elements connected to the workingElement
  const { inputElementsForId, inputElements } = useInputElements(workingElement);

  const showInspector = useMemo(
    () => ElementInspector && workingElement && inputElementsForId === workingElement.id,
    [ElementInspector, inputElementsForId, workingElement]
  );

  useEffect(() => {
    callback(showInspector);
  }, [callback, showInspector]);

  const inspectorContainerClass = isFeatureFlagEnabled(WKP_DATAFLOW_SIDEBAR)
    ? 'wkp-inspector-container-sidebar'
    : 'wkp-inspector-container';

  return showInspector ? (
    <div ref={inspectorContainer} className={inspectorContainerClass}>
      <ElementInspector
        elementData={workingElement.elementData}
        elementId={workingElement.id}
        elementType={workingElement.elementType}
        inputElements={inputElements}
        isDirty={workingElementIsDirty}
        updateData={updateWorkingElement}
      />
    </div>
  ) : null;
};

export default DataFlowInspector;
