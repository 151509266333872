import dataFlowConnectionSvg from './data_flow_connections_icon.svg';

const sharedFontAwesomeClasses = 'fas fa-lg fa-fw';

export const sideBarPanelItems = [
  {
    id: 'wkp-source-btn',
    title: 'Data connections',
    label: 'Data connections',
    name: 'sourceData',
    children: <img alt="Data connections" src={dataFlowConnectionSvg} />,
  },
  {
    id: 'wkp-tax-law-research-btn',
    title: 'Tax law research',
    label: 'Tax law research',
    name: 'taxLawResearch',
    children: <i className={`${sharedFontAwesomeClasses} fa-gavel`} />,
  },
  {
    id: 'wkp-workflow-btn',
    title: 'Workflow',
    label: 'Workflow',
    name: 'workflow',
    children: <i className={`${sharedFontAwesomeClasses} fa-tasks`} />,
  },
  {
    id: 'wkp-settings-btn',
    title: 'Workpaper settings',
    label: 'Settings',
    name: 'settings',
    children: <i className={`${sharedFontAwesomeClasses} fa-cog`} />,
  },
  {
    id: 'wkp-history-btn',
    title: 'Workpaper history',
    label: 'History',
    name: 'history',
    children: <i className={`${sharedFontAwesomeClasses} fa-history`} />,
  },
];
