import { BTForm, BTRadio } from '@btas/jasper';
import React, { useMemo } from 'react';
import ElementInspector from './shared/ElementInspector';
import { LeftRightFieldsSelector } from './shared/LeftRightFieldsSelector';

import { objectArrayRemoveDuplicates } from './elementType/types/shared/utils';

export const LookupTableInput = Object.freeze({
  A: 'A',
  B: 'B',
});

export const LookupTableOutput = Object.freeze({
  T: 'T',
  F: 'F',
});

export const LookupElementInspector = ({ elementData, elementType, updateData, elementId, inputElements, isDirty }) => {
  const [leftElementData, rightElementData] = [inputElements?.A?.elementData, inputElements?.B?.elementData];
  const [leftInputName, rightInputName] = [
    `Input A${leftElementData?.name ? ` (${leftElementData?.name})` : ''}`,
    `Input B${rightElementData?.name ? ` (${rightElementData?.name})` : ''}`,
  ];

  const [leftInputNameNotConnected, rightInputNameNotConnected] = [
    `Input A (Not connected)`,
    `Input B (Not connected)`,
  ];

  const lookupTableInput = elementData.lookupTableInput;
  const keyFields = elementData.keyFields ?? [];

  const keyFieldsLeft = keyFields.map(field => ({
    name: field.left?.name,
    type: field.left?.type,
  }));

  const keyFieldsRight = keyFields.map(field => ({
    name: field.right?.name,
    type: field.right?.type,
  }));

  const leftFieldOptions = determineFieldOptions(leftElementData?.fields, keyFieldsLeft);
  const rightFieldOptions = determineFieldOptions(rightElementData?.fields, keyFieldsRight);

  const areInputPortsConnected = Object.keys(inputElements).length === 2;
  const isInputAConnectedFirst = Object.keys(inputElements)[0] === 'A';

  const isInputBConnectedFirst = Object.keys(inputElements)[0] === 'B';

  const setKeyFields = newKeyFields => {
    updateData({ keyFields: newKeyFields });
  };

  const setLookupTableInput = inputName => {
    updateData({ lookupTableInput: inputName, fieldsByPort: { T: [], F: [] } });
  };

  const alerts = useMemo(() => {
    if (!areInputPortsConnected) {
      return [{ style: 'danger', content: 'Lookup block is not connected.' }];
    }
    return [];
  }, [areInputPortsConnected]);

  function determineFieldOptions(inputFields, inputKeyFields) {
    let fieldOptions = [];
    if (!inputFields && inputKeyFields) {
      //input fields for the side do not exist but keyfields for the side have been defined, meaning input is disconnected from previous block
      fieldOptions = objectArrayRemoveDuplicates(inputKeyFields);
    } else if (inputFields && inputKeyFields && !inputKeyFields.every(r => inputFields.some(e => e.name === r.name))) {
      //input fields and keyfields for the side have been defined but do not match, meaning a new input source has been connected
      fieldOptions = objectArrayRemoveDuplicates(inputFields.concat(inputKeyFields));
    } else {
      fieldOptions = inputFields;
    }
    return fieldOptions;
  }

  return (
    <ElementInspector {...{ elementData, elementId, elementType, updateData, isDirty, alerts }}>
      <div className="wkp-lookup-element-inspector">
        <BTForm>
          <BTForm.FormGroup disabled={!areInputPortsConnected} label="Which input contains the lookup table?">
            <BTRadio
              checked={lookupTableInput === LookupTableInput.A}
              label={isInputAConnectedFirst || areInputPortsConnected ? leftInputName : leftInputNameNotConnected}
              name="lookupTableInput"
              value={LookupTableInput.A}
              onChange={() => setLookupTableInput(LookupTableInput.A)}
            />
            <BTRadio
              checked={lookupTableInput === LookupTableInput.B}
              label={isInputBConnectedFirst || areInputPortsConnected ? rightInputName : rightInputNameNotConnected}
              name="lookupTableInput"
              value={LookupTableInput.B}
              onChange={() => setLookupTableInput(LookupTableInput.B)}
            />
          </BTForm.FormGroup>
        </BTForm>
        <div>
          <h5>Select Fields to Perform the Lookup</h5>
          <p>Select the field(s) that are common between the datasets.</p>
          <LeftRightFieldsSelector
            leftFields={leftFieldOptions}
            leftLabel={leftInputName}
            mappings={keyFields}
            rightFields={rightFieldOptions}
            rightLabel={rightInputName}
            setMappings={setKeyFields}
          />
        </div>
      </div>
    </ElementInspector>
  );
};
